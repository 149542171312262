const REQUEST_CERTIFICATE = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_CERTIFICATE = (state, { body }) => {
  state.Certificates = body;
  state.status = "success";
};

const FAILURE_REQUEST_CERTIFICATE = state => {
  state.status = "failure";
};

const REQUEST_SEND_CERTIFICATE = state => {
  state.statusSend = "fetching";
};

const SUCCESS_REQUEST_SEND_CERTIFICATE = state => {
  state.statusSend = "success";
};

const FAILURE_REQUEST_SEND_CERTIFICATE = state => {
  state.statusSend = "failure";
};


const REQUEST_DELETE_CERTIFICATES = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_CERTIFICATES = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_CERTIFICATES = state => {
  state.statusDelete = "failure";
};

export default {
  REQUEST_CERTIFICATE,
  SUCCESS_REQUEST_CERTIFICATE,
  FAILURE_REQUEST_CERTIFICATE,
  REQUEST_SEND_CERTIFICATE,
  SUCCESS_REQUEST_SEND_CERTIFICATE,
  FAILURE_REQUEST_SEND_CERTIFICATE,
  REQUEST_DELETE_CERTIFICATES,
  SUCCESS_REQUEST_DELETE_CERTIFICATES,
  FAILURE_REQUEST_DELETE_CERTIFICATES
};
