<template>
  <div class="user" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-else
            :headers="headers_user"
            :items="Users"
            sort-by="createdAt"
            sort-desc
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
            :search="search_number"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Users") }}</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
                <v-text-field
                  flat
                  single-line
                  append-icon="search"
                  hide-details
                  v-model="search_number"
                  label="Suche"
                ></v-text-field>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemUser"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_user_site" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleUser }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-textarea
                          v-if="mySelf.roles.includes('admin')"
                          v-model="editedItemUser.sites"
                          label="Sites"
                        ></v-textarea>
                        <div v-if="editedItemUser.bc">
                          <v-text-field
                            required
                            v-model="editedItemUser.bc._RfidNr"
                            label="RfidNr"
                          ></v-text-field>
                          <v-text-field
                            type="number"
                            required
                            v-model="editedItemUser.bc._Discount"
                            label="Discount in Promille"
                          ></v-text-field>
                          <v-checkbox
                            required
                            v-model="editedItemUser.bc._FairUse"
                            label="FairUse"
                          ></v-checkbox>
                          <v-checkbox
                            required
                            v-model="editedItemUser.bc._Active"
                            label="Active BlockChain User"
                          ></v-checkbox>
                        </div>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeUserSite"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveUser"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog_user_voucher" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">Gutschein</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <div v-if="!creating_voucher">
                          <v-text-field
                            type="number"
                            required
                            :rules="positiveValue"
                            v-model="voucherAmount"
                            label="Token"
                          ></v-text-field>
                        </div>
                        <div v-else>Creating Voucher</div>
                      </v-form>
                    </v-card-text>

                    <v-card-actions v-if="!creating_voucher">
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeVoucher"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="createVoucher"
                        >Erstelle Gutschein</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog_user" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleUser }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <!-- <v-text-field
                          disabled
                          v-model="editedItemUser.crypto_address"
                          label="BC Address"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.nickname"
                          label="Angezeigter Kurzname"
                        ></v-text-field> -->
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.user_id"
                          label="SozNr"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.firstname"
                          label="Vorname"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.lastname"
                          label="Nachname"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          type="date"
                          required
                          v-model="editedItemUser.birthday"
                          label="Geburtstag"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.street"
                          label="Strasse/Hausnummer"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          type="number"
                          required
                          v-model="editedItemUser.postcode"
                          label="Postleitzahl"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.town"
                          label="Ort"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemUser.phone"
                          label="Telefon Nr."
                        ></v-text-field>
                        <v-text-field
                          :rules="emailRule"
                          required
                          v-model="editedItemUser.email"
                          label="E-Mail"
                        ></v-text-field>
                        <v-text-field
                          v-if="
                            mySelf.roles.includes('admin') ||
                            mySelf.roles.includes('provider')
                          "
                          v-model="editedItemUser.roles"
                          :rules="requiredRule"
                          label="Roles"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeUser"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveUser"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.sites="{ item }">{{
              comp_site(item.sites)
            }}</template>
            <template v-slot:item.activated="{ item }">
              <div v-if="item.activated">
                <v-icon x-small class="mr-2">mdi-check</v-icon>
              </div>
              <div v-else>
                <v-icon x-small class="mr-2">mdi-cancel</v-icon>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemUser(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <template v-slot:item.actions2="{ item }">
              <v-icon small @click="editItemUserSite(item)"
                >mdi-home-lock</v-icon
              >
            </template>
            <template v-slot:item.actions3="{ item }">
              <v-icon small @click="editVoucher(item)">mdi-cash</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule, positiveValue } from "../utils/rules";
import {
  array2Object,
  array2ArrayObject,
  extractRevertReason,
} from "../functions/utils";
import { ethers, errors } from "ethers";
import { rentErrors } from "../utils/errors";

export default {
  name: "user",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,
      positiveValue,

      dialog_delete: false,
      //dialog_person: false,
      /*headers_person: [
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        { text: "Name 1", value: "name1" },
        { text: "Name 2", value: "name2" },
        { text: "Street", value: "street" },
        { text: "Streetnumber", value: "street_number" },
        { text: "Code", value: "code" },
        { text: "Town", value: "town" },
        { text: "Country", value: "country" },
        { text: "VAT Nr", value: "vatnr" },
        { text: "Email", value: "comm[0]" },
        { text: "Phone Number", value: "comm[1]" },
        { text: "Updated at", value: "updatedAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],*/
      /*
      headers_person: [
        {
          text: "Number",
          align: "start",
          sortable: true,
          value: "number",
        },
        { text: "Short Name", value: "name1" },
        { text: "Full Name", value: "name2" },

        { text: "Email", value: "comm[0]" },
        { text: "Phone Number", value: "comm[1]" },

        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndexPerson: -1,
      editedItemPerson: {
        number: "",
        name1: "",
        name2: "",
        street: "",
        street_number: "",
        code: "",
        town: "",
        country: "",
        vatnr: "",
        comm: ["", ""],
      },
      defaultItemPerson: {
        number: "",
        name1: "",
        name2: "",
        street: "",
        street_number: "",
        code: "",
        town: "",
        country: "",
        vatnr: "",
        comm: ["", ""],
      },
  */
      search_number: "",
      dialog_user: false,
      dialog_user_site: false,
      dialog_user_voucher: false,
      voucherAmount: 1,

      headers_user: [
        { text: "Privacy", value: "actions", sortable: false },
        { text: "Space", value: "actions2", sortable: false },
        { text: "Voucher", value: "actions3", sortable: false },
        {
          text: "Spitzname",
          align: "start",
          sortable: false,
          value: "nickname",
        },
        { text: "Vorname", value: "firstname" },
        { text: "Nachname", value: "lastname" },
        { text: "Geburtstag", value: "birthday" },
        /* { text: "Strasse", value: "street" },
        { text: "PLZ", value: "postcode" },
        { text: "Stadt", value: "town" },*/
        { text: "Telefon", value: "phone" },
        /* { text: "DSGVO", value: "dsgvo" },*/
        { text: "Email", value: "email" },
        { text: "Berechtigung", value: "roles" },
        { text: "Email bestätigt", value: "activated" },
        { text: "Spaces", value: "sites", width: "300px" },
        { text: "BC Address", value: "crypto_address" },
        { text: "erstellt", value: "createdAt" },
      ],

      editedIndexUser: -1,

      defaultItemUser: {
        crypto_address: "",
        nickname: "",
        firstname: "",
        lastname: "",
        birthday: null,
        street: "",
        postcode: null,
        town: "",
        phone: "",
        password: "",
        email: "",
        password: "",
        membership_end: null,
        member: false,
        roles: [],
        activated: true,
        sites: [],

        user_id: "",
      },
      //  bc: {
      //     _Discount: 0,
      //     _RfidNr: "",
      //     _Active: false,
      //     _FairUse: false,
      //   },
      editedItemUser: this.defaultItemUser,
      snackbar: false,
      snackbar_text: "",

      allSites: [],
      loading_data: true,
      amount: 0,
      creating_voucher: false,

      machines: [],
    };
  },
  async mounted() {
    const sites = await this.Web3.SitesRegistryContract.getAllSites();
    this.allSites = array2ArrayObject(sites);
    //console.log(this.allSites);
    await this.getUsers({});

    // for (let user of this.Users) {
    //   try {
    //     user.bc = array2Object(
    //       await this.Web3.RentContract.getRenter(user.crypto_address)
    //     );
    //   } catch (err) {
    //     this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
    //     this.snackbar = true;
    //   }
    // }
    // console.log("User loaded");

    for (let user of this.Users) {
      user.birthday = this.$moment(user.birthday).format("YYYY-MM-DD");
    }
    this.editedItemUser = Object.assign({}, this.defaultItemUser);

    // this.amount = ethers.utils.parseUnits("5", "ether");
    // console.log(this.amount);

    // console.log(await this.Web3.RentContract.getAllMachines());
    this.machines = array2ArrayObject(
      await this.Web3.RentContract.getAllMachines()
    );
    // console.log(this.machines);
    this.loading_data = false;
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Users: ({ user }) => user.Users,
      UserState: ({ user }) => user,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
    }),

    /*formTitlePerson() {
      return this.editedIndexPerson === -1 ? "New Company" : "Edit Company";
    },*/
    formTitleUser() {
      return this.editedIndexUser === -1 ? "Neuer User" : "Editiere User";
    },
  },
  methods: {
    ...mapActions("user", [
      "getUsers",
      "createUser",
      "createUserAuth",
      "readUser",
      "updateUser",
      "deleteUser",
    ]),
    ...mapActions("invoice", ["createVoucherInvoice"]),
    comp_site(site) {
      try {
        let site_array = "";
        site.forEach((obj) => {
          let _site = _.find(this.allSites, (s) => {
            return s.rentBC.toLowerCase() == obj.address.toLowerCase();
          });
          if (_site && obj.active) {
            site_array += _site.name + ", ";
          } else if (_site && !obj.active) {
            site_array +=
              "(" +
              _site.name
                .split("")
                .map((char) => char + "\u0336")
                .join("") +
              "), ";
          } else if (this.mySelf.roles.includes("admin")) {
            site_array += obj.address + ", ";
          }
        });
        return site_array;
      } catch {
        return "";
      }
    },
    /*
    ...mapActions("person", [
      "getPersons",
      "createPerson",
      "readPerson",
      "updatePerson",
      "deletePerson",
    ]),
    */

    /*
    async editItemPerson(item) {
      //console.log("editItem");
      this.editedIndexPerson = this.Persons.indexOf(item);
      this.editedItemPerson = Object.assign({}, item);

      this.dialog_person = true;
    },

    async deleteItemPerson(item) {
      const index = this.Persons.indexOf(item);
      await this.deletePerson(item._id);
      if (this.PersonState.statusDelete == "success") {
        this.Persons.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closePerson() {
      this.dialog_person = false;
      this.$nextTick(() => {
        this.editedItemPerson = Object.assign({}, this.defaultItem);
        this.editedIndexPerson = -1;
      });
    },

    async savePerson() {
      //console.log("savePerson");

      //console.log(this.editedItemPerson)

      if (this.editedIndexPerson > -1) {
        await this.updatePerson(this.editedItemPerson);
        if (this.PersonState.statusUpdate == "success") {
          this.editedItemPerson._id = this.PersonState.Person._id;
          this.editedItemPerson.updatedAt = this.$moment(
            this.PersonState.Person.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          Object.assign(
            this.Persons[this.editedIndexPerson],
            this.editedItemPerson
          );
        } else {
          this.snackbar_text = this.PersonState.Person.message;
          this.snackbar = true;
        }
      } else {
        await this.createPerson(this.editedItemPerson);
        if (this.PersonState.statusCreate == "success") {
          this.editedItemPerson._id = this.PersonState.Person._id;
          this.editedItemPerson.updatedAt = this.$moment(
            this.PersonState.Person.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          this.Persons.push(this.editedItemPerson);
        } else {
          this.snackbar_text =  this.PersonState.Person.message;
          this.snackbar = true;
        }
      }
      this.closePerson();
    },
*/
    createItemUser() {
      this.editedItemUser = Object.assign({}, this.defaultItemUser);
      this.editedIndexUser = -1;
      this.dialog_user = true;
    },

    async editItemUser(item) {
      //console.log("editItem");
      this.editedIndexUser = this.Users.indexOf(item);
      this.editedItemUser = Object.assign({}, item);
      this.editedItemUser.roles = JSON.stringify(this.editedItemUser.roles);
      //this.editedItemUser.birthday = this.$moment(this.editedItemUser.birthday).format("YYYY-MM-DD");
      try {
        this.editedItemUser.bc = array2Object(
          await this.Web3.RentContract.getRenter(item.crypto_address)
        );
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      this.dialog_user = true;
    },

    async editItemUserSite(item) {
      //console.log("editItem");
      this.editedIndexUser = this.Users.indexOf(item);
      this.editedItemUser = Object.assign({}, item);
      this.editedItemUser.sites = JSON.stringify(this.editedItemUser.sites);
      //this.editedItemUser.birthday = this.$moment(this.editedItemUser.birthday).format("YYYY-MM-DD");
      //let objBC = {};
      try {
        this.editedItemUser.bc = array2Object(
          await this.Web3.RentContract.getRenter(item.crypto_address)
        );
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      this.dialog_user_site = true;
    },

    async deleteItemUser(item) {
      const index = this.Users.indexOf(item);
      await this.deleteUser(item._id);
      if (this.UserState.statusDelete == "success") {
        this.Users.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeUser() {
      this.dialog_user = false;
      this.$nextTick(() => {
        this.editedItemUser = Object.assign({}, this.defaultItem);
        this.editedIndexUser = -1;
      });
    },

    closeUserSite() {
      this.dialog_user_site = false;
      this.$nextTick(() => {
        this.editedItemUser = Object.assign({}, this.defaultItem);
        this.editedIndexUser = -1;
      });
    },
    closeVoucher() {
      this.dialog_user_voucher = false;
      this.creating_voucher = false;
    },

    editVoucher(item) {
      this.editedIndexUser = this.Users.indexOf(item);
      // console.log(item);
      this.editedItemUser = Object.assign({}, item);
      this.dialog_user_voucher = true;
    },

    async createVoucher() {
      this.creating_voucher = true;
      await this.createVoucherInvoice({
        from: this.mySelf.crypto_address,
        to: this.editedItemUser.crypto_address,
        amount: this.voucherAmount,
        site: this.Sites[this.selectedSite],
      });
      this.dialog_user_voucher = false;
      this.creating_voucher = false;
    },

    async saveUser() {
      //console.log("saveUser");

      //console.log(this.editedItemUser)
      if (this.editedIndexUser == -1) {
        try {
          this.editedItemUser.sites = [
            { address: this.Sites[this.selectedSite].rentBC, active: true },
          ];
          //console.log(this.editedItemUser);
          await this.createUserAuth(this.editedItemUser);
          if (this.UserState.statusCreate == "success") {
            this.editedItemUser._id = this.UserState.User._id;
            this.editedItemUser.createdAt = this.UserState.User.createdAt;
            this.Users.push(this.editedItemUser);
            this.dialog_user = false;
          } else {
            this.snackbar_text = this.UserState.User.message;
            this.snackbar = true;
          }
        } catch (err) {
          console.log("Error CreateUserAuth ", err);
        }
      } else {
        let check = false;
        try {
          if (typeof this.editedItemUser.sites != "object") {
            this.editedItemUser.sites = JSON.parse(this.editedItemUser.sites);
          }
          if (typeof this.editedItemUser.roles != "object") {
            this.editedItemUser.roles = JSON.parse(this.editedItemUser.roles);
          }
          check = true;
        } catch (err) {
          console.log("Parse Error Sites");
        }
        try {
          if (check) {
            //console.log("Update User 1 ", this.editedItemUser);
            let _isInArray = 0;
            for (let site of this.editedItemUser.sites) {
              if (
                site.address ==
                this.Sites[this.selectedSite].rentBC.toLowerCase()
              ) {
                site.active = this.editedItemUser.bc._Active;
                // console.log("SetActive: ", site);
                _isInArray = 1;
              }
            }
            if (_isInArray == 0) {
              // add site
              let obj = {};
              obj.active = this.editedItemUser.bc._Active;
              obj.address = this.Sites[this.selectedSite].rentBC.toLowerCase();
              this.editedItemUser.sites.push(obj);
            }

            this.dialog_user_site = false;

            if (this.editedIndexUser > -1) {
              //console.log("Update User 2 ", this.editedItemUser);

              if (!this.editedItemUser.roles.includes("readOnlyUser")) {
                try {
                  await this.Web3.RentContract.setRenter(
                    this.editedItemUser.crypto_address,
                    this.editedItemUser.bc._RfidNr,
                    this.editedItemUser.bc._Active,
                    this.editedItemUser.bc._Discount,
                    this.editedItemUser.bc._FairUse
                  );
                } catch (err) {
                  throw new Error("No enough BC rights");
                }
              }

              /* 
              const _site = _.find(this.editedItemUser.sites, {address: this.Sites[this.selectedSite].rentBC.toLowerCase()});

              for (let machineId = 0; machineId < this.machines.length; machineId++) {
                try {
                  await this.Web3.RentContract.setMachinePermissionForRenter(machineId + 1, this.editedItemUser.crypto_address, _site.active);
                } catch (err) {
                  this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
                  this.snackbar = true;
                }
              }
              */
              //console.log("Update User 3", this.editedItemUser);
              await this.updateUser(this.editedItemUser);

              if (this.UserState.statusUpdate == "success") {
                this.editedItemUser._id = this.UserState.User._id;
                this.editedItemUser.updatedAt = this.$moment(
                  this.UserState.User.updatedAt
                ).format("DD-MM-YYYY HH:mm");
                Object.assign(
                  this.Users[this.editedIndexUser],
                  this.editedItemUser
                );
              } else {
                this.snackbar_text = this.UserState.User.message;
                this.snackbar = true;
              }
            } else {
              if (!this.editedItemUser.roles.includes("readOnlyUser")) {
                try {
                  await this.Web3.RentContract.setRenter(
                    this.editedItemUser.crypto_address,
                    this.editedItemUser.bc._RfidNr,
                    this.editedItemUser.bc._Active,
                    this.editedItemUser.bc._Discount,
                    this.editedItemUser.bc._FairUse
                  );
                } catch (err) {
                  throw new Error("No enough BC rights");
                }
              }
              await this.createUser(this.editedItemUser);
              if (this.UserState.statusCreate == "success") {
                this.editedItemUser._id = this.UserState.User._id;
                this.editedItemUser.roles = ["user"];
                this.editedItemUser.activated = true;
                this.editedItemUser.updatedAt = this.$moment(
                  this.UserState.User.updatedAt
                ).format("DD-MM-YYYY HH:mm");
                this.Users.push(this.editedItemUser);
              } else {
                this.snackbar_text = this.UserState.User.message;
                this.snackbar = true;
              }
            }

            this.closeUser();
          }
        } catch (err) {
          this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
          if (!this.snackbar_text) {
            this.snackbar_text = err;
          }
          this.snackbar = true;
        }
      }
    },
  },
  watch: {
    /*editedIndexPerson(item) {
      console.log(item);
    },*/
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>