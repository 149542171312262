const REQUEST_CONTRACTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_CONTRACTS = (state, { body }) => {
  state.Contracts = body;
  state.status = "success";
};

const FAILURE_REQUEST_CONTRACTS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_CONTRACT = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_CONTRACT = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_CONTRACT = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_CONTRACT = (state, { body }) => {
  state.CreatedContract = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_CONTRACT = state => {
  
  state.statusCreate = "failure";
};


export default {
  REQUEST_CONTRACTS,
  SUCCESS_REQUEST_CONTRACTS,
  FAILURE_REQUEST_CONTRACTS,
  REQUEST_CREATE_CONTRACT,
  SUCCESS_REQUEST_CREATE_CONTRACT,
  FAILURE_REQUEST_CREATE_CONTRACT,
  REQUEST_DELETE_CONTRACT,
  SUCCESS_REQUEST_DELETE_CONTRACT,
  FAILURE_REQUEST_DELETE_CONTRACT,
  REQUEST_UPDATE_CONTRACT,
  SUCCESS_REQUEST_UPDATE_CONTRACT,
  FAILURE_REQUEST_UPDATE_CONTRACT
};
