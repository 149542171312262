<template>
  <div class="contract" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers_contract"
            :items="Contracts"
            sort-by="lastname"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Smart Contracts</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="green" left fab small dark class="mb-2" @click="createItemContract">
            <v-icon>
              add
            </v-icon>
          </v-btn>
                <v-dialog v-model="dialog_contract" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleContract }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemContract.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field                   
                          v-model="editedItemContract.address"
                          label="Address"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemContract.abi"
                          label="ABI"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemContract.deployed"
                          label="Deployed by"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemContract.root"
                          label="Root"
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItemContract.description"
                          label="Beschreibung"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeContract"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveContract"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemContract(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemContract(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule } from "../utils/rules";

export default {
  name: "contract",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,

      dialog_delete: false,
  
      dialog_contract: false,

      headers_contract: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Name", value: "name" },
        { text: "address", value: "address" },
        //{ text: "abi", value: "abi" },
        { text: "Description", value: "description" },
        { text: "deployed", value: "deployed" },
        { text: "root", value: "root" },
        
      ],

      editedIndexContract: -1,
      editedItemContract: {
        name: "",
        address: "",
        abi: "",
        description: "",
        deployed: "",
        root: ""
      },
      defaultItemContract: {
        name: "",
        address: "",
        abi: "",
        description: "",
        deployed: "",
        root: ""
      },

      snackbar: false,
      snackbar_text: "",

    };
  },
  async mounted() {
    await this.getContracts({});
    this.editedItemContract = Object.assign({}, this.defaultItemContract);
  },
  computed: {
    ...mapState({
      status: ({ contract }) => contract.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Contracts: ({ contract }) => contract.Contracts,
      ContractState: ({ contract }) => contract
    }),

    formTitleContract() {
      return this.editedIndexContract === -1 ? "New Contract" : "Edit Contract";
    },
  },
  methods: {
    ...mapActions("contract", [
      "getContracts",
      "createContract",
      "readContract",
      "updateContract",
      "deleteContract",
    ]),
    
    createItemContract () {
      this.editedItemContract = Object.assign({}, this.defaultItem);
      this.editedIndexContract = -1;
      this.dialog_contract = true;
    },

    async editItemContract(item) {
      //console.log("editItem ", item);
      this.editedIndexContract = this.Contracts.indexOf(item);
      this.editedItemContract = Object.assign({}, item);
      this.dialog_contract = true;
    },

    async deleteItemContract(item) {
      const index = this.Contracts.indexOf(item);
      await this.deleteContract(item._id);
      if (this.ContractState.statusDelete == "success") {
        this.Contracts.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeContract() {
      this.dialog_contract = false;
      this.$nextTick(() => {
        this.editedItemContract = Object.assign({}, this.defaultItem);
        this.editedIndexContract = -1;
      });
    },

    async saveContract() {
      //console.log("saveContract");
      //console.log(this.editedItemContract)

      if (this.editedIndexContract > -1) {
        await this.updateContract(this.editedItemContract);

        if (this.ContractState.statusUpdate == "success") {
          Object.assign(this.Contracts[this.editedIndexContract], this.editedItemContract);
        } else {
          this.snackbar_text = "Update failed"; //this.ContractState.Contract.message;
          this.snackbar = true;
        }
      } else {
        await this.createContract(this.editedItemContract);
        if (this.ContractState.statusCreate == "success") {
          this.Contracts.push(this.editedItemContract);
        } else {
          this.snackbar_text = "Create failed"; //this.ContractState.Contract.message;
          this.snackbar = true;
        }
      }

      this.closeContract();
    },
  },
  watch: {
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
   font-size: 10px !important;
}
</style>