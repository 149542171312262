import api from "../../../api";

export default {
  async getEvents({ commit }) {
    commit("REQUEST_EVENTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_EVENTS",
        await api.getEvents()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_EVENTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteEvent({ commit }, id) {
    commit("REQUEST_DELETE_EVENT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_EVENT",
        await api.deleteEvent(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_EVENT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateEvent({ commit }, item ) {
    commit("REQUEST_UPDATE_EVENT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_EVENT",
        await api.updateEvent(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_EVENT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createEvent({ commit }, item ) {
    commit("REQUEST_CREATE_EVENT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_EVENT",
        await api.createEvent( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_EVENT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
