<template>
  <div class="homeBuyables" v-if="mySelf && machine">
    <v-card flat>
      <!-- HomeBuyables -->
      <v-row>
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-if="!loading_data"
            :headers="headers_machineContract"
            :items="machineContracts"
            sort-by="startTime"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ machine.Name }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.contractNr="{ item }">
              <!-- <v-btn x-small elevation="1" @click="showContract(item)">{{
                item.contractNr
              }}</v-btn> -->
              {{ item.contractNr }}
            </template>
            <template v-slot:item.startTime="{ item }">
              <!-- {{ item.startTime | moment("utc", "DD.MMM.YY HH:mm") }} -->
              <!-- {{ item.startTime | moment("DD.MMM.YY HH:mm") }} -->
              <div v-if="calcTime(item).minutesPerTimeSlot > 10">
                {{ calcTime(item).start | moment("utc", "DD.MMM.YY") }}
              </div>
              <div v-else>
                {{ calcTime(item).start | moment("DD.MMM.YY HH:mm") }}
              </div>
            </template>
            <template v-slot:item.endTime="{ item }">
              <div v-if="calcTime(item).minutesPerTimeSlot > 10">
                {{ calcTime(item).end | moment("utc", "DD.MMM.YY") }}
              </div>
              <div v-else>
                {{ calcTime(item).end | moment("DD.MMM.YY HH:mm") }}
              </div>
            </template>
            <template v-slot:item.actions_qr="{ item }">
              <div @click="printQr(item)">
                <vue-qr
                  width="100px"
                  :text="getMachineQr(item)"
                  :logoImage="logoQr"
                  :correctLevel="1"
                ></vue-qr>
              </div>
            </template>

            <template v-slot:item.actions_pdf="{ item }">
              <v-icon
                v-if="checkInvoice(item) && calculateRentPrice.totalBudget > 0"
                small
                @click="showInvoicePdf(item)"
                >picture_as_pdf</v-icon
              >
            </template>
            <template v-slot:header.actions_pdf="{ item }">
              <div v-if="calculateRentPrice.totalBudget > 0">Rechnung</div>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" class="">
          <!--Lagerstand: {{selectedMachine.Stock}}-->

          <v-btn
            color="success"
            v-on:click="startTransaction"
            v-if="checkAmount"
            height="70px"
            width="100%"
            >{{ textBuy }}</v-btn
          >
          <div v-else>
            <v-btn height="70px" small disabled width="100%"
              >Nicht genug Token ({{ calculateRentPrice.totalBudget / 1000 }}
              {{ TokenName }})</v-btn
            >
            <br /><br />
            <v-btn
              small
              width="100%"
              color="orange"
              v-on:click="dialog_paypal = true"
              >PayPal - Token kaufen</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbar_color">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_transaction" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1"
            ><v-icon small left> mdi-cash-multiple </v-icon
            ><b> Gutschein Token Transfer</b></span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center h6"
                v-if="!loadingBuy && !checkAmount"
              >
                <p class="subtitle-2">Nicht genug Token!</p>
              </v-col>

              <v-col cols="12" class="text-center h6" v-else-if="!loadingBuy">
                <p class="subtitle-1">
                  <b
                    >Sende
                    {{ this.calculateRentPrice.totalBudget / 1000 }}
                    {{ this.TokenSymbol }}</b
                  >
                </p>
                <p class="subtitle-2">von Account</p>
                <p class="caption">{{ this.mySelf.crypto_address }}</p>
                <p class="subtitle-2">
                  <v-icon small> mdi-arrow-down-bold </v-icon>
                </p>
                <p class="subtitle-1">
                  <b>{{ this.Sites[this.selectedSite].name }}</b>
                </p>
                <div class="caption">
                  {{ this.Sites[this.selectedSite].rentBC }}
                </div>
              </v-col>
              <v-col cols="12" class="text-center h5" v-else>
                Transaktion wird durchgeführt<br /><br />
                <v-progress-circular
                  indeterminate
                  :size="50"
                  :width="7"
                  color="secondary"
                  v-if="loadingBuy"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!loadingBuy">
          <v-btn color="error" text @click="dialog_transaction = false"
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="buy()"
            :disabled="!checkAmount"
            >Durchführen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_contract" max-width="500px">
      <v-card
        v-if="
          selectedMachineContract.contract &&
          selectedMachineContract.contract.error
        "
      >
        <v-card-title>
          Details zu Vertrag Nr {{ selectedMachineContract.contractNr }}
        </v-card-title>
        <v-card-text>
          Maschinen Nr: {{ selectedMachineContract.contract.MachineID }}
          <br />Abgeschlossen am:
          {{
            (selectedMachineContract.contract.ContractTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Mieter:
          {{ getRenter(selectedMachineContract.contract.Renter) }}
          <br />StartSlot: {{ selectedMachineContract.contract.StartSlot }}
          <br />SlotCount: {{ selectedMachineContract.contract.SlotCount }}
          <br />Verbrauchte kW:
          {{ (selectedMachineContract.contract.Watth / 1000).toFixed(3) }}
          <br />Betriebszeit in Minuten:
          {{ selectedMachineContract.contract.UsedMin }}

          <br />Kaution: {{ selectedMachineContract.contract.Deposit / 1000 }}
          <br />Reservierungsgebühr:
          {{ selectedMachineContract.contract.SlotPrice / 1000 }}
          <br />Betriebskosten:
          {{ selectedMachineContract.contract.RunPrice / 1000 }} <br />Gebühren:
          {{ selectedMachineContract.contract.Fees / 1000 }} <br />Summe:
          {{ selectedMachineContract.contract.TotalBudget / 1000 }}

          <br />Verrechnet: {{ selectedMachineContract.contract.Invoiced }}
          <br />Token transferiert:
          {{ selectedMachineContract.contract.MoneyTransferred }}
          <br />Rücküberweisung:
          {{ selectedMachineContract.contract.RenterReturn }}
          <br />Rücküberweisung Zeit:
          {{
            (selectedMachineContract.contract.DepositPayOutTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Rechnungsnummer Provider:
          {{ selectedMachineContract.contract.InvoiceProviderID }}
          <br />Rechnungsnummer Maschinenbesitzer:
          {{ selectedMachineContract.contract.InvoiceMachineOwnerID }}

          <br />Fehlernummer: {{ selectedMachineContract.contract.ErrorID }}
          <br />Fehler aktiv:
          {{ selectedMachineContract.contract.error.active }}
          <br />Fehlermeldung:
          {{ selectedMachineContract.contract.error.Description }} <br />Unset
          Description:
          {{ selectedMachineContract.contract.error.UnsetDescription }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog_contract = false"
            >Schliessen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_qr" max-width="250px">
      <v-card align="center" class="pa-5">
        <div class="h5">
          {{ machine.Name }} Nr. {{ selectedMachineContract.contractNr }}
        </div>
        <vue-qr
          :text="machineQr"
          :logoImage="logoQr"
          :correctLevel="1"
        ></vue-qr>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_paypal" max-width="500px">
      <PayPalDialog :dialog="dialog_paypal"></PayPalDialog>
    </v-dialog>
  </div>
</template>

<script>
import { ethers, BigNumber, errors } from "ethers";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule } from "../utils/rules";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import { rentErrors } from "../utils/errors";
import PayPalDialog from "./PayPalDialog.vue";

export default {
  name: "homeBuyables",
  props: {
    machine: {},
  },
  components: {
    PayPalDialog,
  },
  data() {
    return {
      valid: false,
      requiredRule,

      // dialog_delete: false,

      //dialog_machineContract: false,
      dialog_error: false,
      dialog_cancel_contract: false,
      dialog_contract: false,
      dialog_paypal: false,

      headers_machineContract: [
        { text: "QR Code", value: "actions_qr", sortable: false },
        { text: "Nummer", value: "contractNr" },
        {
          text: "Rechnung",
          value: "actions_pdf",
          sortable: false,
          align: "center",
        },
        { text: "gültig von", value: "startTime", groupable: false },
        { text: "bis", value: "endTime", groupable: false },
        //{ text: "rentBC", value: "rentBC" },
        //{ text: "machineId", value: "machineId" },
        //{ text: "Maschinenname", value: "machine.Name"  },
        //{ text: "finalized", value: "finalized", groupable: false  },
        //{ text: "usedSec", value: "usedSec", groupable: false  },
        //{ text: "wattH", value: "wattH", groupable: false  },
        //{ text: "maxWattPerHour", value: "machine.MaxWattPerHour", groupable: false  },
        //{ text: "blockNumber", value: "blockNumber" },
        //{ text: "error", value: "error", groupable: false  },
        //{ text: "Actions", value: "actions", sortable: false, groupable: false  },
        //{ text: "Cancel", value: "cancel", sortable: false, groupable: false },
      ],

      selectedMachineContract: {},
      selectedMachineContractIndex: 0,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      machineContracts: [],
      machines: [],
      unsetDescription: "",
      loading_data: true,

      logoQr: undefined,
      calculateRentPriceTemplate: {
        RunPrice: 0,
        deposit: 0,
        extendContractNr: 0,
        slotPrice: 0,
        totalBudget: 0,
        priceFix: 0,
        priceVar: 0,
      },
      calculateRentPrice: {},
      dialog_transaction: false,
      loadingBuy: false,
      timeOffset: 0,
      localRentingAction: false,
      localInvoiceAction: false,
      dialog_qr: false,

      machineQr: "",
      logoQr: undefined,
      lastBlock: 0,
    };
  },
  created: function () {
    this.timeOffset = new Date().getTimezoneOffset();
    this.calculateRentPrice = this.calculateRentPriceTemplate;
  },
  async mounted() {
    if (this.machine) {
      this.loadMachineContracts();
    }
  },
  computed: {
    ...mapState({
      status: ({ machineContract }) => machineContract.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      MachineContracts: ({ machineContract }) =>
        machineContract.MachineContracts,
      MachineContractState: ({ machineContract }) => machineContract,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      TokenName: ({ web3 }) => web3.TokenName,
      TokenSymbol: ({ web3 }) => web3.TokenSymbol,
      WalletAddress: ({ web3 }) => web3.Address,
      actualBlock: (web3) => web3.web3.actualBlock,
      Invoices: ({ invoice }) => invoice.Invoices,
      InvoiceState: ({ invoice }) => invoice,
      showPayPal: (state) => state.showPayPal,
    }),

    formTitleMachineContract() {
      return this.editedIndexMachineContract === -1
        ? "New MachineContract"
        : "Edit MachineContract";
    },
    checkAmount() {
      try {
        //console.log("CHECK AMOUNT");
        const totalBig = ethers.utils.parseEther(
          (this.calculateRentPrice.totalBudget / 1000).toString()
        );
        //console.log(totalBig.lte(this.TokenBalance));
        return totalBig.lte(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    textBuy() {
      let text = "";
      if (this.calculateRentPrice.totalBudget > 0) {
        text =
          "Für " +
          String(this.calculateRentPrice.totalBudget / 1000) +
          " " +
          String(this.TokenName) +
          "s kaufen";
      } else {
        text = "Produkt beziehen";
      }
      return text;
    },
  },
  methods: {
    ...mapActions("machineContract", ["getMachineContracts"]),
    ...mapActions("invoice", ["getInvoices"]),

    calcTime(item) {
      let obj = {};
      try {
        //console.log(this.machine)
        //const duration = this.machine.MinutesPerTimeSlot;

        //if (duration )
        obj.start = item.startTime;
        obj.end = item.endTime - 60;
        obj.maxSlots = this.machine.MaxSlots;
        obj.minutesPerTimeSlot = this.machine.MinutesPerTimeSlot;
      } catch (err) {}
      //console.log(obj)
      return obj;
    },
    async matchMachine() {
      try {
        await this.loadMachines();
        let machineContracts = this.MachineContracts;
        for (let machineContract of machineContracts) {
          machineContract.contract = array2Object(
            await this.Web3.RentContract.getContract(machineContract.contractNr)
          );
          machineContract.machine = _.find(this.machines, {
            MachineId: machineContract.machineId,
          });
        }
        this.machineContracts = machineContracts;
      } catch (err) {
        //
      }
    },

    getMachineQr(item) {
      let obj = {};
      obj.rentBC = this.Sites[this.selectedSite].rentBC;
      obj.invoiceNr = item.contract.InvoiceProviderID;
      obj.contractNr = item.contractNr;
      return JSON.stringify(obj);
    },

    async loadMachines() {
      // console.log("Load Machines ");
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].Active && !_machines[idx].Buyable) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
            _machines[idx].MachineId = parseInt(idx) + 1;
            this.machines.push(_machines[idx]);
          }
        }
      }
    },

    async showContract(item) {
      this.selectedMachineContract = item;

      this.dialog_contract = true;
    },

    getRenter(_address) {
      if (_address) {
        const _user = _.find(this.Users, {
          crypto_address: _address.toLowerCase(),
        });
        if (_user) {
          return _user.firstname + " " + _user.lastname;
        } else {
          return _address;
        }
      } else {
        return 0;
      }
    },

    async calcPrice() {
      try {
        let slotStart = Math.floor(
          this.$moment().format("X") / 60 / this.machine.MinutesPerTimeSlot
        );
        //console.log(slotStart, this.machine.MachineId, this.timeOffset);
        const slotCount = this.machine.MaxSlots;
        this.calculateRentPrice = array2Object(
          await this.Web3.RentContract.calculateRentPrice(
            this.mySelf.crypto_address,
            this.machine.MachineId,
            slotStart,
            slotCount,
            this.timeOffset,
            false
          )
        );
        console.log(this.calculateRentPrice);
      } catch (err) {
        console.log(err);
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      //console.log(JSON.stringify(this.calculateRentPrice));
    },

    async buy() {
      this.loadingBuy = true;
      const sendToken = ethers.utils.parseEther(
        (this.calculateRentPrice.totalBudget / 1000).toString()
      );
      this.lastBlock = this.actualBlock;
      //console.log("Buy on Block: " + this.actualBlock);
      const slotCount = this.machine.MaxSlots;
      try {
        let slotStart = Math.floor(
          this.$moment().format("X") / 60 / this.machine.MinutesPerTimeSlot
        );
        //console.log(slotStart);
        //console.log(slotStart.toFixed(0));
        if (this.calculateRentPrice.totalBudget > 0) {
          await this.Web3.TokenContract.approve(
            this.Web3.RentContractAddress,
            sendToken
          );
        }

        let tx = await this.Web3.RentContract.rent(
          this.machine.MachineId,
          slotStart,
          slotCount,
          this.timeOffset,
          false,
          this.mySelf.crypto_address
        );
        //console.log("Rent TX: ",tx);

        this.localRentingAction = true;
      } catch (err) {
        console.log(err);
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
        this.loadingBuy = false;
      }
      //Wait until next Block
      // console.log("LastBlock ", lastBlock , this.actualBlock)
    },
    async loadBalance() {
      try {
        const _tokenBalance = await this.Web3.TokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        console.log(err);
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
    },

    async loadMachineContracts() {
      this.loading_data = true;
      this.calcPrice();
      await this.getMachineContracts({
        rentBC: this.Sites[this.selectedSite].rentBC.toLowerCase(),
        cancelled: false,
        machineId: this.machine.MachineId,
        //startTime: {$lte: this.$moment().utc().format('X')},
        //endTime: {$gte: this.$moment().utc().format('X')},
      }); //finalized: true, invoiced: true});
      this.editedItemMachineContract = Object.assign(
        {},
        this.defaultItemMachineContract
      );
      await this.matchMachine();
      await this.getInvoices(this.Sites[this.selectedSite]);
      this.loading_data = false;
    },

    showInvoicePdf(item) {
      const invoice = _.find(this.Invoices, {
        contract_number: item.contractNr,
      });

      if (invoice) {
        var download_url = "/api/invoice/" + invoice.pdf + ".pdf";
        var x = new XMLHttpRequest();
        x.open("GET", download_url, true);
        x.setRequestHeader(
          "Authorization",
          localStorage.getItem("Authorization")
        );
        x.responseType = "blob";
        x.onload = function (e) {
          let file = new File(
            [x.response],
            invoice.prefix + invoice.number + ".pdf",
            { type: "application/pdf" }
          );
          var objectURL = window.URL.createObjectURL(file);
          window.open(objectURL);
        };
        x.send();
      }
    },
    printQr(item) {
      this.selectedMachineContract = item;
      this.machineQr = this.getMachineQr(item);
      this.dialog_qr = true;
    },
    checkInvoice(item) {
      const invoice = _.find(this.Invoices, {
        contract_number: item.contractNr,
      });
      //console.log("Check Invoice " + invoice);
      if (invoice) {
        return true;
      } else {
        return false;
      }
    },
    startTransaction() {
      this.dialog_transaction = true;
      if (this.calculateRentPrice.totalBudget == 0) {
        this.buy();
      }
    },
  },
  watch: {
    async machine() {
      //console.log("Watch Machine: ",this.machine)
      this.loadMachineContracts();
    },
    actualBlock(blockNr) {
      if (this.lastBlock < blockNr - 3 && this.localRentingAction) {
        this.loadBalance();
        this.loadMachineContracts();
        this.localRentingAction = false;
        this.loadingBuy = false;
        this.localInvoiceAction = true;
        this.dialog_transaction = false;
      }
      // ToDo: ersetzen durch BC Event
      if (this.lastBlock < blockNr - 4 && this.localInvoiceAction) {
        //console.log("invoice ready ")
        this.getInvoices(this.Sites[this.selectedSite]);
        this.localInvoiceAction = false;
      }
    },
    showPayPal(now) {
      if (!now) {
        this.dialog_paypal = now;
      }
    },
    dialog_paypal(now) {
      this.$store.state.showPayPal = now;
    },
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>