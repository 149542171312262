import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  mySelf: void 0,
  statusMySelf: void 0,
  error: void 0,
  machine: void 0,
  door: void 0,
  site: void 0,
  site_name: void 0,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
