import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  status: void 0,
  statusUpdate: void 0,
  statusDelete: void 0,
  statusCreate: void 0,
  statusMachine: null
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
