const emailRule = [
  v => {
    return !!v || "Bitte E-Mail angeben";
  },
  v => (v &&

   ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,32})+$/.test(v.trim()) 
  /^.*@.{1,63}\./.test(v.trim()) 

  ) ||
    "E-Mail must be valid"
];

const positiveValue = [
  v => {
    return !!v || "Bitte einen Betrag angeben";
  },
  v => (v && v > 0) || 'Nur positive Beträge erlaubt'
];

const addressBCRule = [
  v => {
    return !!v || "Bitte BlockChain Adresse angeben";
  },
  v => (v && v.length == 42) || 'BlockChain Address must have 42 characters incl. prefix 0x',
  v => (v && /^0x/.test(v)) || 'The Prefix 0x missing'
];


const requiredRule = [v => !!v || "Bitte ausfüllen"];

let passwordRule = void 0;

if (process.env.NODE_ENV === "production") {
  passwordRule = [
    v => {
      return !!v || "Password is required";
    },
    v =>
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(v) ||
      "Min. 8 characters with at least one capital letter, a number and a special character => !@#\$%^&*"
  ];
} else {
  passwordRule = [v => !!v || "Bitte ausfüllen"];
}

const digitsRule4 = [
  v => {
    return !!v || "Bitte Nr angeben";
  },
  v => (v && v.length == 4) || '4-stellig erforderlich'
];
const digitsRule10 = [
  v => {
    return !!v || "Bitte Nr angeben";
  },
  v => (v && v.length == 10) || '10-stellig erforderlich'
];

export { passwordRule, emailRule, requiredRule , addressBCRule, positiveValue, digitsRule4, digitsRule10};
