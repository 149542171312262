var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mySelf)?_c('div',{staticClass:"homeReadOnly",attrs:{"height":"100%"}},[(_vm.firebase_id)?_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mt-2 ml-4",attrs:{"color":"green","small":"","dark":""},on:{"click":function($event){return _vm.$router.push({
            path: '/login',
            query: { firebase_id: _vm.firebase_id, back: true },
          })}}},[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-arrow-left-box")]),_vm._v("Zurück zum Menu ")],1)],1)],1):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"h5"},[_vm._v("Kunden QR")]),_c('v-row',[_c('v-col',{staticClass:"ml-3 pr-0",attrs:{"cols":"auto"}},[_c('vue-qr',{attrs:{"width":"150px","text":_vm.mySelf.user_id,"logoImage":_vm.logoQr,"correctLevel":3}})],1),_c('v-col',{staticClass:"pl-0 mt-3 mr-6",attrs:{"cols":"100%"}},[(_vm.mySelf.email)?_c('table',{staticClass:"caption",attrs:{"width":"100%"}},[_c('tr',[_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.mySelf.firstname)+" "+_vm._s(_vm.mySelf.lastname)),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm.mySelf.birthday,"DD.MM.YYYY"))),_c('br'),_vm._v(" "+_vm._s(_vm.mySelf.user_id)),_c('br'),_vm._v(" "+_vm._s(_vm.mySelf.email)),_c('br')])])]):_vm._e()])],1)],1),_c('br'),(_vm.loading_data)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"width":7,"color":"secondary"}}):_vm._e(),(!_vm.loading_data)?_c('v-data-table',{staticClass:"elevation-1 caption",attrs:{"headers":_vm.headers_machineContract,"items":_vm.MachineContracts,"dense":"","mobile-breakpoint":600,"footer-props":{ 'items-per-page-options': [25, 50, 100, -1] },"items-per-page":50,"hide-default-footer":"","calculate-widths":"","item-class":_vm.row_classes,"sort-by":"endTime","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"h5"},[_vm._v("Aktuelle Tests")]),_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","elevation":"1","color":"blue lighten-3"},on:{"click":_vm.loadMachineContracts}},[_vm._v("Erstelle neue Zertifikate")])],1)]},proxy:true},{key:"item.startTime",fn:function(ref){
          var item = ref.item;
return [(_vm.calcTime(item).minutesPerTimeSlot > 10)?_c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.calcTime(item).start,"utc", "DD.MMM.YY"))+" ")]):_c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.calcTime(item).start,"DD.MMM.YY HH:mm"))+" ")])]}},{key:"item.endTime",fn:function(ref){
          var item = ref.item;
return [(_vm.calcTime(item).minutesPerTimeSlot > 10)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.calcTime(item).end,"utc", "DD.MMM.YY"))+" ")]):_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.calcTime(item).end,"DD.MMM.YY HH:mm"))+" ")])]}},{key:"item.test",fn:function(ref){
          var item = ref.item;
return [(_vm.getFlag(item))?_c('div',{staticClass:"font-weight-bold red--text"},[_vm._v("nachgewiesen")]):_c('div',{staticClass:"font-weight-bold green--text"},[_vm._v("nicht nachgewiesen")])]}},{key:"item.machine.Name",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.machine.Name))])]}},{key:"item.organisation",fn:function(ref){
          var item = ref.item;
return [(item.organisation.name)?_c('div',[_vm._v(_vm._s(item.organisation.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.organisation.codeTown)+" "),_c('br'),_vm._v(" "+_vm._s(item.organisation.country))]):_vm._e()]}},{key:"item.actions_qr",fn:function(ref){
          var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.printQr(item)}}},[_c('vue-qr',{attrs:{"width":"150px","text":_vm.getMachineQr(item),"logoImage":_vm.logoQr,"correctLevel":1}})],1)]}},{key:"item.pdf",fn:function(ref){
          var item = ref.item;
return [(item.pdf)?_c('div',{staticClass:"justify-center layout px-0"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showInvoicePdf(item)}}},[_vm._v(" picture_as_pdf ")])],1):_vm._e()]}},{key:"header.signature",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.signature",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.qr).signature.substr(0, 20))+" "+_vm._s(JSON.parse(item.qr).signature.substr(20, 20))+"... ")]}},{key:"item.time",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTime(JSON.parse(item.qr).secret.time))+" ")]}}],null,false,1002338569)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }