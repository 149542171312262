<template>
  <div class="paypal" v-if="mySelf">
    <v-card flat lazy>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers_paypal"
            :items="paypalCredentials"
            sort-by="name"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Paypal") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemPaypal"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_paypal" max-width="500px" v-if="editedItemPaypal">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitlePaypal }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                       
                        <v-text-field
                          disabled
                          v-model="editedItemPaypal.rentBC"
                          label="rentBC"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemPaypal.sandbox.receiver_email"
                          label="sandbox receiver_email"
                        ></v-text-field>
                         <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemPaypal.sandbox.credentials"
                          label="sandbox credentials"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemPaypal.production.receiver_email"
                          label="production receiver_email"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemPaypal.production.credentials"
                          label="production credentials"
                        ></v-text-field>
                        <v-text-field
                          type="number"
                          v-model="editedItemPaypal.min_amount"
                          label="Mindestbetrag"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closePaypal"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="savePaypal"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
               
              </v-toolbar>
            </template>
           
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemPaypal(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemPaypal(item)">mdi-delete</v-icon>
            </template>
            <!-- <template v-slot:item.rentBC="{ item }">{{
                comp_site(item.rentBC)
              }}</template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { requiredRule, addressBCRule } from "../utils/rules";
import {
  array2ArrayObject,
} from "../functions/utils";

export default {
  name: "paypal",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      addressBCRule,

      dialog_delete: false,

      dialog_paypal: false,

      headers_paypal: [
        { text: "Actions", value: "actions", sortable: false },  
        //{ text: "rentBC", value: "rentBC" },
        { text: "Mindestbetrag", value: "min_amount" },
        { text: "sandbox", value: "sandbox" },
        { text: "production", value: "production" }
      ],

      editedIndexPaypal: -1,
      defaultItemPaypal: {
        rentBC: "",
        sandbox: {
          receiver_email: "",
          credentials: ""
        },
        production: {
          receiver_email: "",
          credentials: ""
        }
      },
      editedItemPaypal: undefined,

      snackbar: false,
      snackbar_text: "",

      allSites: [],

      paypalCredentials: []

    };
  },
  created() {
    this.editedItemPaypal = Object.assign({}, this.defaultItemPaypal);
  },
  async mounted() {
    const sites = await this.Web3.SitesRegistryContract.getAllSites();
    this.allSites = array2ArrayObject(sites);
    
    await this.getPaypal({rentBC: this.Sites[this.selectedSite].rentBC});
    
    if (this.Paypals) {
      this.paypalCredentials = [JSON.parse(JSON.stringify(this.Paypals))];
    }
    
  },
  computed: {
    ...mapState({
      status: ({ paypal }) => paypal.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Paypals: ({ paypal }) => paypal.Paypals,
      PaypalState: ({ paypal }) => paypal,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Web3: (web3) => web3.web3,
    }),

    formTitlePaypal() {
      return this.editedIndexPaypal === -1 ? "New Paypal" : "Edit Paypal";
    },
  },
  methods: {
    ...mapActions("paypal", [
      "getPaypal",
      "createPaypal",
      "updatePaypal",
      "deletePaypal",
    ]),

    createItemPaypal() {
      console.log("createtItem ");
      this.editedItemPaypal = Object.assign({}, this.defaultItemPaypal);
      this.editedItemPaypal.rentBC = this.Sites[this.selectedSite].rentBC;
      this.editedIndexPaypal = -1;
      this.dialog_paypal = true;
    },

    async editItemPaypal(item) {
      //console.log("editItem ", item);
      this.editedIndexPaypal = this.paypalCredentials.indexOf(item);
      this.editedItemPaypal = Object.assign({}, item);
      this.dialog_paypal = true;
    },

    async deleteItemPaypal(item) {
      const index = this.paypalCredentials.indexOf(item);
      await this.deletePaypal(item._id);
      if (this.PaypalState.statusDelete == "success") {
        console.log("Delete")
        this.paypalCredentials.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closePaypal() {
      this.dialog_paypal = false;
      this.$nextTick(() => {
        this.editedItemPaypal = Object.assign({}, this.defaultItemPaypal);
        this.editedIndexPaypal = -1;
      });
    },

    async savePaypal() {
      //console.log("savePaypal");
      //console.log(this.editedItemPaypal);
      //this.editedItemPaypal.machines = this.editedItemPaypal.machines.split(",");
      if (this.editedIndexPaypal > -1) {
        await this.updatePaypal(this.editedItemPaypal);
        
        if (this.PaypalState.statusUpdate == "success") {
          //console.log("UPDATE ",this.editedItemPaypal)
          Object.assign(this.paypalCredentials[this.editedIndexPaypal], this.editedItemPaypal);
        } else {
          this.snackbar_text = "Update failed"; //this.PaypalState.Paypal.message;
          this.snackbar = true;
        }
      } else {
        await this.createPaypal(this.editedItemPaypal);
        if (this.PaypalState.statusCreate == "success") {
           if (!this.paypalCredentials) {
             this.paypalCredentials = [this.editedItemPaypal];
           } else {
            this.paypalCredentials.push(this.editedItemPaypal);
           }
        } else {
          this.snackbar_text = "Create failed"; //this.PaypalState.Paypal.message;
          this.snackbar = true;
        }
      }

      this.closePaypal();
    },

    comp_site(rentBC) {
      try {
        let _site = _.find(this.allSites, (s) => {
            return s.rentBC.toLowerCase() == rentBC.toLowerCase();
          });
        if (_site) {
          return _site.name;
        }
        else {
          return rentBC;
        }
        
      } catch {
        return "";
      }
    },
  },
  watch: {},
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>