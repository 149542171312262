<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="7">
        <v-card v-if="status === 'success'" flat>
          <v-card-text>
            <v-card>
              <v-alert type="info" :value="status === 'success'"
                >Registration abgeschlossen</v-alert
              >
              <v-card-text class="text-xs-center">{{
                $t(
                  "Thank you for signing up. You will receive a verification e-mail."
                )
              }}</v-card-text>
            </v-card>
          </v-card-text>
        </v-card>

        <v-card flat v-else-if="!crypto_address">
          <v-card-text>
            <v-card>
              <v-card-title class="title">{{ $t("Sign Up") }}</v-card-title>
              <v-alert type="info" :value="!crypto_address">
                {{
                  $t("Bitte verbinde Dich sich zuerst mit Deiner CryptoWallet.")
                }}</v-alert
              >
              <v-card-actions>
                <v-btn
                  rounded
                  outlined
                  color="primary"
                  @click="$router.push('/cryptologin')"
                  >zum Login</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card v-else-if="auth.error.status === 406" flat>
          <v-card-text>
            <v-card>
              <v-alert type="info" :value="auth.error.status === 406">{{
                $t(
                  "Du bist nun erfolgreich registriert und der Administrator wurde verständigt um die Freischaltung durchzuführen. Du wirst automatisch per email in den nächsten Tagen verständigt, sobald dies erfolgt ist."
                )
              }}</v-alert>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card v-else-if="authError.code == 'NotFound'" flat>
          <v-card-text>
            <v-card>
              <v-card-title class="title">{{ $t("Sign Up") }}</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'"
                  >Something went wrong</v-alert
                >
                <v-alert type="info" :value="status === 'success'"
                  >Registration abgeschlossen</v-alert
                >
              </v-card-text>
              <v-card-text align="center">
                <v-form v-model="valid" style="width: 100%; height: 100%">
                  <v-text-field
                    v-model="site_name"
                    label="Site"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    v-model="crypto_address"
                    label="Deine Crypto Addresse"
                    disabled
                  ></v-text-field>
                  <v-select
                    :items="genderTypes"
                    v-model="user.gender"
                    label="Geschlecht"
                  ></v-select>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.nickname"
                    label="Kurzname / Nickname"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.firstname"
                    label="Vorname"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.lastname"
                    label="Nachname"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule && dateRule"
                    type="date"
                    min="1900-01-01"
                    max="2100-12-24"
                    required
                    v-model="user.birthday"
                    label="Geburtstag"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.street"
                    label="Strasse"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.street_number"
                    label="Hausnummer"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.door_number"
                    label="Türnummer"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    type="number"
                    required
                    v-model="user.postcode"
                    label="Postleitzahl"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.town"
                    label="Ort"
                  ></v-text-field>
                  <v-text-field
                    :rules="requiredRule"
                    required
                    v-model="user.phone"
                    label="Telefon Nr."
                  ></v-text-field>
                  <v-text-field
                    :rules="emailRule"
                    required
                    v-model="user.email"
                    label="E-Mail"
                  ></v-text-field>
                  <!-- <v-text-field
                    type="password"
                    :rules="requiredRule"
                    required
                    v-model="user.password"
                    label="Passwort"
                  ></v-text-field>-->

                  <v-checkbox
                    v-model="user.dsgvo"
                    :rules="requiredRule"
                    required
                  >
                    <template v-slot:label>
                      <div>
                        Ich stimme bis auf Widerruf der
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/dsgvo"
                              @click.stop
                              v-on="on"
                            >
                              Datenschutzerklärung
                            </a>
                          </template>
                          Datenschutzerklärung in einem neuen Fenster öffnen
                        </v-tooltip>
                        zu.
                      </div>
                    </template>
                  </v-checkbox>
                </v-form>

                <v-divider></v-divider>

                <vue-recaptcha
                  v-if="!isCordova"
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="recaptchaWebSite"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
                <v-btn
                  v-if="!isCordova"
                  class="ma-1"
                  color="primary"
                  rounded
                  outlined
                  small
                  @click="resetRecaptcha"
                >
                  Reset ReCAPTCHA
                </v-btn>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  outlined
                  rounded
                  :disabled="!valid || (!verifiedReCaptcha && !isCordova)"
                  color="primary"
                  @click="startSignUp()"
                  >{{ $t("Sign Up") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <!---if="!site_address">-->
          <v-card-title class="title">Willkommen</v-card-title>

          <v-card-text>
            <span v-if="authError.code == 'NotFound'" class="subtitle-2"
              >Bitte öffne die App per Einladungslink oder QR Code um eine
              Registrierung durchführen zu können.</span
            >

            <span
              v-else-if="authError.code == 'NotAcceptable'"
              class="subtitle-2"
              >Email Bestätigung ausständig. Bitte prüfe ob du deine Email per
              Link bestätigt hast.</span
            >

            <span
              v-else-if="authError.code == 'NotImplemented'"
              class="subtitle-2"
              >Der Administrator hat deinen Account noch nicht freigeschaltet.
              Sobald dies durchgeführt ist, bekommst du eine Bestätigungsmail
              und kannst dich in Leas-e einloggen.</span
            >
          </v-card-text>
          <v-card-actions>
            <v-btn
              rounded
              outlined
              color="primary"
              @click="$router.push('/cryptologin')"
              >zum Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { requiredRule, emailRule } from "../utils/rules";
import VueRecaptcha from "vue-recaptcha";
import { ethers } from "ethers";

export default {
  name: "SignUp",
  components: { VueRecaptcha },
  props: {
    mnemonic: "",
    password: "",
    authError: "",
  },
  data() {
    return {
      isCordova: process.env.CORDOVA_PLATFORM,
      firebase_id: "",
      user: {
        nickname: "",
        firstname: "",
        lastname: "",
        birthday: null,
        street: "",
        postcode: null,
        town: "",
        phone: "",
        password: "",
        email: "",
        dsgvo: false,

        street_number: "",
        door_number: "",
        gender: "",
      },
      //site_address: undefined,
      //site_name: "",
      genderTypes: ["m", "w", "d"],
      valid: false,
      requiredRule,
      emailRule,
      dateRule: [
        (v) => {
          return !!v || "Bitte geben Sie ein gültiges Datum ein";
        },
        (v) =>
          (v && this.checkDate(v)) || "Bitte geben Sie ein gültiges Datum ein",
      ],

      verifiedReCaptcha: false,

      // leas-e.makerspace.at
      recaptchaWebSite: "",

      //LocalHost
      //recaptchaWebSite: "6LfTOdcZAAAAAGGGhb4xj13gN6SAibUjxz8_7m1x",
      //recaptchaWebSite: "",
    };
  },
  created() {
    if (process.env.NODE_ENV === "production") {
      this.recaptchaWebSite = "6Ld-w9oZAAAAAIuHZjpyKTcbc8StOEGFyt7GT-O_";
    } else {
      this.recaptchaWebSite = "6LfCOtcZAAAAACXjcfn8U70F_MPCvtluKnl6PMm4";
    }
  },
  async mounted() {
    //console.log("Params: ", this.mnemonic, this.password);

    //console.log("Sites: ", SitesRegistryContract);

    //this.site_address = this.$store.state.auth.site;

    // try {
    //this.site_address = ethers.utils.getAddress(this.$store.state.auth.site);
    //this.site_name = this.$store.state.auth.site_name;
    //console.log("Address Link: ", this.site_address);
    // } catch (err) {
    console.log("no Address Link");
    //this.$router.push("/cryptologin");
    // }

    //console.log("Signup site: " ,this.site_address);

    if (this.isCordova) {
      cordova.plugins.firebase.messaging.getToken().then(function (token) {
        //console.log("Got device token: ", token);
        this.firebase_id = token;
      });
    }
  },
  methods: {
    ...mapActions("user", ["signUp"]),
    ...mapActions("contract", ["getContracts"]),
    onSubmit: function () {
      this.$refs.invisibleRecaptcha.execute();
    },
    checkDate(v) {
      if (
        this.$moment(v).format("X") >= -2208992400 &&
        this.$moment(v) <= this.$moment()
      ) {
        return true;
      } else {
        return false;
      }
    },
    onVerify: function (response) {
      //console.log("Verify: " + response);
      this.verifiedReCaptcha = true;
    },
    onExpired: function () {
      //console.log("Expired");
      this.verifiedReCaptcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
      this.verifiedReCaptcha = false;
    },

    startSignUp() {
      this.user.seedphrase = this.mnemonic;
      this.user.password = this.password;
      this.user.site_address = this.site_address;
      this.user.crypto_address = this.crypto_address;
      this.user.firebase_id = this.firebase_id;
      this.signUp(this.user);
    },
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      WalletAddress: ({ web3 }) => web3.Address,
      auth: ({ auth }) => auth,
    }),
    crypto_address() {
      return this.WalletAddress;
    },
    site_address() {
      try {
        return ethers.utils.getAddress(this.$store.state.auth.site);
      } catch (err) {
        return undefined;
      }
    },
    site_name() {
      return this.$store.state.auth.site_name;
    },
  },
};
</script>

<style scoped>
</style>
