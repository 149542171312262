<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <!-- <v-btn color="primary" @click="countUp">Login</v-btn>
        {{ counter }} -->
        <v-card flat color="transparent">
          <v-card-text>
            <v-card v-if="localWallet">
              <v-card-title class="title">Login</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="statusWallet == 'Error: API'"
                  >Sie haben keinen Zugriff oder der Server ist nicht
                  erreichbar. Probieren Sie es später nocheinmal oder wenden Sie
                  sich an Ihren Administrator.</v-alert
                >
                <v-alert type="success" :value="statusWallet == 'Success: API'"
                  >Du wurdest erfolgreich eingeloggt</v-alert
                >
                <v-alert
                  type="success"
                  :value="statusWallet == 'Success: Wallet Opened'"
                  >Wallet erfolgreich geöffnet</v-alert
                >
                <v-alert
                  type="error"
                  :value="statusWallet == 'Error: invalid password'"
                  >Password incorrect</v-alert
                >
              </v-card-text>
              <v-card-text>
                <v-text-field
                  type="password"
                  @keydown.enter="loadWallet()"
                  id="password"
                  v-model="password"
                  label="Password"
                ></v-text-field>
                <v-checkbox
                  v-if="isCordova"
                  v-model="autoLogin"
                  label="Remember Password"
                  @click="autoLoginFingerprint = false"
                >
                </v-checkbox>
                <v-checkbox
                  v-if="isCordova"
                  v-model="autoLoginFingerprint"
                  label="Enable Fingerprint"
                  @click="autoLogin = false"
                >
                </v-checkbox>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  outlined
                  color="primary"
                  :loading="loginStatus"
                  @click="loadWallet()"
                  >Login</v-btn
                >
              </v-card-actions>
            </v-card>
            <v-card v-else-if="!site_address">
              <v-card-title class="title">Willkommen</v-card-title>

              <v-card-text>
                <span class="subtitle-2"
                  >Bitte öffne die App per Einladungslink oder QR Code.</span
                >

                <v-divider></v-divider>
                {{ site_address }} {{ site_name }}
                <!-- <v-btn
                  small
                  color="primary"
                  @click="site_address = '0x0'"
                  >Wallet erstellen</v-btn
                > -->
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-card-title class="title"
                >Anmeldung {{ site_name }}</v-card-title
              >
              <v-divider></v-divider>
              <v-card-subtitle class="title">CryptoWallet</v-card-subtitle>

              <v-card-text v-if="!scanQr">
                <span
                  class="subtitle-2"
                  v-if="statusWallet != 'Success: Wallet created'"
                  >Bitte erstelle zuerst eine CryptoWallet um mit der
                  Registrierung fortfahren zu können.<br />Dazu wähle eine der
                  genannten Möglichkeiten.<br />Falls du noch keine Wallet
                  besitzt, wähle die Option Passwort um eine Neue zu
                  erstellen.</span
                >
                <br /><br />
                <v-alert
                  type="error"
                  :value="statusWallet == 'Error: invalid mnemonic'"
                  >Seed Phrase nicht korrect</v-alert
                >
                <v-alert
                  type="error"
                  :value="statusWallet == 'Error: File not Wallet'"
                  >Can't read JSON Wallet. Please check File.</v-alert
                >
                <v-alert
                  type="success"
                  :value="statusWallet == 'Success: Wallet created'"
                  >Wallet erstellt</v-alert
                >
                <v-alert
                  type="warning"
                  :value="statusWallet == 'Success: Wallet created'"
                  class="black--text"
                  >Bitte speicher die unten angezeigte Wortphrase an einem
                  sicheren Ort. Mit dieser kannst Du deine CryptoWallet
                  jederzeit wiederherstellen.</v-alert
                >
              </v-card-text>
              <v-tabs
                grow
                v-model="tab"
                v-if="statusWallet != 'Success: Wallet created'"
                show-arrows
              >
                <v-tab width="50px" class="caption">Passwort</v-tab>
                <v-tab class="caption">Seed Phrase</v-tab>
                <v-tab class="caption">JSON Datei</v-tab>
              </v-tabs>
              <v-card-text>
                <v-form
                  ref="formPass"
                  style="width: 100%; height: 100%"
                  v-on:submit.prevent
                >
                  <div v-if="!createPassStatus">
                    <v-text-field
                      v-if="
                        (tab == 0 || tab == 1) &&
                        statusWallet != 'Success: Wallet created'
                      "
                      type="password"
                      :rules="passwordRule"
                      required
                      id="password"
                      v-model="password"
                      label="New Password"
                    ></v-text-field>
                    <v-text-field
                      v-if="
                        (tab == 0 || tab == 1) &&
                        statusWallet != 'Success: Wallet created'
                      "
                      type="password"
                      :rules="passwortCheckRule"
                      required
                      id="password_check"
                      v-model="password_check"
                      label="Reenter Password"
                      @keydown.enter="
                        if ($refs.formPass.validate()) {
                          createWalletPass();
                        }
                      "
                    ></v-text-field>
                  </div>
                  <div v-else-if="createPassStatus">
                    Erstelle Wallet, bitte warten.
                  </div>
                </v-form>
                <v-form ref="formSeed" style="width: 100%; height: 100%">
                  <v-textarea
                    v-if="tab == 1"
                    :rules="requiredRule"
                    required
                    v-model="mnemonic"
                    label="Wortphrase zur Wiederherstellung deiner Wallet (Seed Phrase)"
                    no-resize
                    rows="2"
                  ></v-textarea>
                </v-form>
                <v-file-input
                  v-if="tab == 2"
                  v-model="selectedFile"
                  show-size
                  label="JSON Wallet File"
                ></v-file-input>
              </v-card-text>
              <v-card-text v-if="statusWallet == 'Success: Wallet created'">
                <p class="subtitle-1">Seed Phrase:</p>
                <p class="subtitle-2">{{ mnemonic }}</p>
              </v-card-text>
              <v-card-text align="center">
                <v-btn
                  class="ma-1"
                  v-if="statusWallet != 'Success: Wallet created' && tab == 0"
                  :disabled="mnemonic != ''"
                  rounded
                  outlined
                  small
                  color="primary"
                  :loading="createPassStatus"
                  @click="
                    if ($refs.formPass.validate()) {
                      createWalletPass();
                    }
                  "
                  >Wallet erstellen
                </v-btn>
                <v-checkbox
                  v-if="
                    statusWallet == 'Success: Wallet created' && mnemonic != ''
                  "
                  v-model="saveMnemonicOnSrv"
                  label="Backup Seed Phrase on Server"
                >
                </v-checkbox>
                <v-checkbox
                  v-if="
                    statusWallet == 'Success: Wallet created' && mnemonic != ''
                  "
                  v-model="savePasswordOnSrv"
                  label="Backup Password on Server"
                >
                </v-checkbox>
                <v-btn
                  class="ma-1"
                  v-if="statusWallet == 'Success: Wallet created'"
                  rounded
                  small
                  :disabled="mnemonic == ''"
                  outlined
                  color="primary"
                  :loading="loginStatus"
                  @click="loadWallet()"
                  >Weiter zur Registrierung
                </v-btn>

                <v-btn
                  class="ma-1"
                  v-if="
                    statusWallet != 'Success: Wallet created' &&
                    tab == 1 &&
                    !scanQr
                  "
                  rounded
                  outlined
                  small
                  color="primary"
                  :loading="createSeedStatus"
                  @click="
                    if (
                      $refs.formSeed.validate() &&
                      $refs.formPass.validate()
                    ) {
                      createWalletSeed();
                    }
                  "
                  >Wallet erstellen
                </v-btn>
                <vue-qr-reader
                  v-if="scanQr"
                  :videoWidth="280"
                  :videoHeight="220"
                  v-on:code-scanned="qrCodeArrived"
                  :use-back-camera="scanQrCam"
                ></vue-qr-reader>
                <v-btn
                  class="ma-1"
                  v-if="statusWallet != 'Success: Wallet created' && tab == 1"
                  rounded
                  outlined
                  small
                  color="primary"
                  @click="scanQr = !scanQr"
                  ><div v-if="!scanQr">Scan seed phrase</div>
                  <div v-else>Close Scan</div>
                </v-btn>
                <v-btn
                  class="ma-1"
                  v-if="statusWallet != 'Success: Wallet created' && tab == 2"
                  rounded
                  outlined
                  small
                  color="primary"
                  :loading="createJsonStatus"
                  @click="loadJson()"
                  >Wallet erstellen
                </v-btn>

                <v-btn
                  class="ma-1"
                  v-if="scanQr"
                  rounded
                  small
                  outlined
                  color="primary"
                  @click="toggleCam()"
                  >Toggle Cam Front/Back
                </v-btn>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ethers } from "ethers";
import { passwordRule, requiredRule, emailRule } from "../utils/rules";
import VueQrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";

export default {
  name: "CryptoLogin",
  components: {
    VueQrReader,
  },
  data() {
    return {
      password: "",
      password_check: "",
      mnemonic: "",
      valid: false,
      localWallet: localStorage.wallet,
      statusWallet: "",
      requiredRule,
      passwordRule,
      passwortCheckRule: [
        (v) => (v && v == this.password) || "Passwörter stimmen nicht überein",
      ],
      emailRule,
      walletDifficulty: 1024,
      scanQr: false,
      scanQrCam: true,
      errorMessage: "",
      loginStatus: false,
      createPassStatus: false,
      createSeedStatus: false,
      createJsonStatus: false,
      selectedFile: undefined,
      tab: "",
      //site_address: undefined,
      //site_name: "",
      autoLogin: false,
      autoLoginFingerprint: false,
      isCordova: process.env.CORDOVA_PLATFORM,
      isFingerprint: false,
      loginFromFingerprint: false,
      saveMnemonicOnSrv: true,
      savePasswordOnSrv: true,
      loadedSites: [],
      counter: 0,
    };
  },

  mounted() {
    //this.site_address = this.$store.state.auth.site;
    //this.site_name = this.$store.state.auth.site_name;
    //console.log("Login site: ", this.site_address, "Name: ", this.site_name);
    if (this.isCordova) {
      document.addEventListener("deviceready", this.onDeviceReady, false);
    }
  },
  methods: {
    ...mapActions("auth", ["logout", "cryptoAuth", "getMySelf"]),
    ...mapActions("contract", ["getContracts"]),

    countUp() {
      this.counter++;
      console.log("counter: ", this.counter);
    },

    onDeviceReady() {
      console.log(
        "Device ready: ",
        this.site_address,
        "Name: ",
        this.site_name
      );

      try {
        this.authData = JSON.parse(localStorage.getItem("authData"));
        this.autoLogin = this.authData.autoLogin;
        this.autoLoginFingerprint = this.authData.autoLoginFingerprint;
        //this.password = this.authData.password;

        if (this.autoLoginFingerprint) {
          Fingerprint.isAvailable(
            (success) => {
              this.isFingerprint = true;
              //console.log("Fingerprint success ", success);
              Fingerprint.loadBiometricSecret(
                {
                  //description: "Some biometric description",
                  disableBackup: true, // always disabled on Android
                },
                (secret) => {
                  //alert("Authentication successful, secret: " + secret);
                  this.loginFromFingerprint = true;
                  this.password = secret;
                  this.loadWallet();
                },
                (error) => {
                  //alert("Authentication invalid " + error.message);
                }
              );
            },
            function (error) {
              console.log("Fingerprint error ", error);
            }
          );
        } else if (this.autoLogin) {
          this.loadWallet();
        }
      } catch (err) {
        console.log(err);
      }
    },

    qrCodeArrived(code) {
      this.mnemonic = code;
      this.scanQr = false;
    },
    qrErrorCaptured(error) {
      console.log(error);
      switch (error.name) {
        case "NotAllowedError":
          this.errorMessage = "Camera permission denied.";
          break;
        case "NotFoundError":
          this.errorMessage = "There is no connected camera.";
          break;
        case "NotSupportedError":
          this.errorMessage =
            "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
          break;
        case "OverconstrainedError":
          this.errorMessage = "Constraints don't match any installed camera.";
          break;
        default:
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      console.error(this.errorMessage);
      this.scanQr = false;
    },
    async createWalletPass() {
      if (!localStorage.wallet) {
        this.createPassStatus = true;

        let myWallet = void 0;
        //console.log("delaying");
        await this.delay(200);
        //console.log("Generating Wallet Pass");
        myWallet = await ethers.Wallet.createRandom();
        //const encryptWallet = await myWallet.encrypt(password);

        // WARNING: Doing this substantially reduces the security
        //          of the wallet. This is highly NOT recommended.

        // We override the default scrypt.N value, which is used
        // to indicate the difficulty to crack this wallet.
        const encryptWallet = await myWallet.encrypt(this.password, {
          scrypt: {
            // The number must be a power of 2 (default: 131072)
            N: this.walletDifficulty,
          },
        });
        localStorage.wallet = encryptWallet;
        this.mnemonic = myWallet.mnemonic.phrase;

        this.statusWallet = "Success: Wallet created";
        this.createPassStatus = false;
        //this.login(myWallet);
      } else {
        //error
      }
    },
    async createWalletSeed() {
      let myWallet = void 0;
      try {
        this.createSeedStatus = true;
        await this.delay(200);
        myWallet = await ethers.Wallet.fromMnemonic(this.mnemonic.trim());
        const encryptWallet = await myWallet.encrypt(this.password, {
          scrypt: { N: this.walletDifficulty },
        });
        localStorage.wallet = encryptWallet;
        this.statusWallet = "Success: Wallet created";
        this.createSeedStatus = false;

        //this.login(myWallet);
      } catch (err) {
        console.log(err);
        this.statusWallet = err;
        this.createSeedStatus = false;
      }
    },
    async loadWallet() {
      let myWallet = void 0;

      if (localStorage.wallet) {
        try {
          this.loginStatus = true;
          myWallet = await ethers.Wallet.fromEncryptedJson(
            localStorage.wallet,
            this.password
          );

          this.login(myWallet);
          this.statusWallet = "Success: Wallet Opened";
        } catch (err) {
          console.log(err);
          this.statusWallet = err;
          this.loginStatus = false;
        }
      } else {
        //error
      }
    },
    async login(myWallet) {
      if (myWallet) {
        this.loginStatus = true;

        this.$store.state.web3.Wallet = await myWallet.connect(
          window.ethersprovider
        );

        this.$store.state.web3.Address = this.Wallet.address.toLowerCase();
        this.loginApi();

        this.loginStatus = false;
      }
    },
    toString(file) {
      if (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      }
    },
    async loadJson() {
      this.createJsonStatus = true;
      await this.delay(200);
      const encryptWallet = await this.toString(this.selectedFile);
      try {
        const jsonWallet = JSON.parse(encryptWallet);

        if (jsonWallet.crypto) {
          localStorage.wallet = encryptWallet;
          this.createJsonStatus = false;
          this.statusWallet = "Success: Wallet created";
          this.logout();
        }
      } catch (err) {
        this.statusWallet = "Error: File not Wallet";
        this.selectedFile = undefined;
        this.createJsonStatus = false;
      }
    },
    async loginApi() {
      try {
        /*if (this.mySelf ) {
        this.logout();
      }*/
        const message = Math.random().toString();
        //console.log("Message: ", message);

        const signature = await this.Wallet.signMessage(message);
        const digest = await ethers.utils.hashMessage(message);
        //console.log("Start Auth");

        await this.cryptoAuth({ digest, signature });

        // ausklammern!
        // await this.getMySelf();
        //console.log("mySelf ", JSON.stringify(this.mySelf));

        await this.getContracts();

        const SitesRegistryContract = _.find(this.Contracts, {
          name: "SiteRegistry",
        });
        this.$store.state.web3.SitesRegistryContractAddress =
          SitesRegistryContract.address;
        this.$store.state.web3.SitesRegistryContractAbi =
          SitesRegistryContract.abi;
        const sitesRegistryContract = new ethers.Contract(
          SitesRegistryContract.address,
          SitesRegistryContract.abi,
          this.Wallet
        );
        this.$store.state.web3.SitesRegistryContract = sitesRegistryContract;

        const _tokenContract = _.find(this.Contracts, { name: "Token" });
        this.$store.state.web3.TokenContractAbi = _tokenContract.abi;
        const _rentContract = _.find(this.Contracts, { name: "Rent" });
        this.$store.state.web3.RentContractAbi = _rentContract.abi;
        const _invoiceContract = _.find(this.Contracts, { name: "Invoice" });
        this.$store.state.web3.InvoiceContractAbi = _invoiceContract.abi;
        //console.log("contracts ", JSON.stringify(this.Contracts));
        //const newSite = {name:"MakerSpace2",siteOwner:"ebb8b3a8b91c61c98b68529fe440df98d8f9ef1a",siteSC:"0x8A076ae94a1c7a7573628A02bD3e2f475c0Dfe51",tokenSC:"0xCE441b3Cc9C97875Ebb2FA63B3b1c41F4f7b7519"};
        //await sitesRegistryContract.addSite(newSite);
        //console.log("SiteRegCont ", JSON.stringify(sitesRegistryContract));
        const _sites = await sitesRegistryContract.getAllSites();
        //console.log("Sites ", JSON.stringify(_sites));

        const userSites = _.filter(this.mySelf.sites, { active: true });

        let sitesArray = [];

        for (let userSite of userSites) {
          for (let site of _sites) {
            if (userSite.address.toLowerCase() == site.rentBC.toLowerCase()) {
              sitesArray.push({
                name: site.name,
                siteOwner: site.owner,
                rentBC: site.rentBC,
                tokenBC: site.tokenBC,
                invoiceBC: site.invoiceBC,
              });
            }
          }
        }
        //console.log("SITE ARRAY: ", sitesArray);
        this.$store.state.user.Sites = sitesArray;
        //console.log("SITE settled: ", this.Sites);
        //const userSites = _.filter(sites, {siteSC: userSite.address});
        //console.log(userSites);
        /*this.$store.state.web3.TokenContractAddress = tokenContractAddress;
      
        const tokenContract = new ethers.Contract(
          tokenContractAddress,
          tokenContractAbi,
          this.Wallet
        );
        this.$store.state.web3.TokenContract = tokenContract;
*/
        //console.log("Sites Array ", sitesArray );
        this.statusWallet = "Success: API";
      } catch (err) {
        //console.log("ERR API")
        this.statusWallet = "Error: API";
      }
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async toggleCam() {
      this.scanQr = !this.scanQr;
      this.scanQrCam = !this.scanQrCam;
      await this.delay(10);
      this.scanQr = !this.scanQr;
    },
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Wallet: ({ web3 }) => web3.Wallet,
      WalletBalance: ({ web3 }) => web3.EtherBalance,
      WalletAddress: ({ web3 }) => web3.Address,
      TokenContractAddress: ({ web3 }) => web3.TokenContractAddress,
      TokenContract: ({ web3 }) => web3.TokenContract,
      TokenContractAbi: ({ web3 }) => web3.TokenContractAbi,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      SitesContractAddress: ({ web3 }) => web3.SitesContractAddress,
      SitesContract: ({ web3 }) => web3.SitesContract,
      SitesContractAbi: ({ web3 }) => web3.SitesContractAbi,
      authStatus: ({ auth }) => auth.status,
      authError: ({ auth }) => auth.error,
      Contracts: ({ contract }) => contract.Contracts,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
    }),

    site_address() {
      try {
        return ethers.utils.getAddress(this.$store.state.auth.site);
      } catch (err) {
        return undefined;
      }
    },
    site_name() {
      return this.$store.state.auth.site_name;
    },
  },
  watch: {
    authStatus(val) {
      if (val === "success") {
        //this.$router.push({ name: "home" });
        if (this.isCordova) {
          if (
            this.isFingerprint &&
            !this.loginFromFingerprint &&
            this.autoLoginFingerprint
          ) {
            Fingerprint.registerBiometricSecret(
              {
                description: "Secure Password with Fingerprint",
                secret: this.password,
                invalidateOnEnrollment: true,
                disableBackup: true, // always disabled on Android
              },
              () => {
                //alert("Register Authentication successful");
              },
              (error) => {
                //alert("Register Authentication invalid " + error.message);
              }
            );
          }
          if (this.autoLoginFingerprint) {
            this.authData = {};
            this.authData.password = "";
            this.authData.autoLogin = false;
            this.authData.autoLoginFingerprint = true;
            localStorage.setItem("authData", JSON.stringify(this.authData));
          } else if (this.autoLogin) {
            this.authData = {};
            this.authData.password = this.password;
            this.authData.autoLogin = true;
            this.authData.autoLoginFingerprint = false;
            localStorage.setItem("authData", JSON.stringify(this.authData));
          }
        }
      } else if (val === "failure") {
        let _params = {};
        if (this.saveMnemonicOnSrv) {
          _params.mnemonic = this.mnemonic;
        }
        if (this.savePasswordOnSrv) {
          _params.password = this.password;
        }
        _params.loadedSites = this.loadedSites;
        _params.authError = this.authError.body;
        this.$router.push({ name: "signUp", params: _params });
      }
    },
    async Sites() {
      // console.log("Sites CHANGED", this.Sites);
      let _tokenContract = void 0;
      try {
        this.$store.state.web3.TokenContractAddress = this.Sites[0].tokenBC;
        _tokenContract = await new ethers.Contract(
          this.Sites[0].tokenBC,
          this.$store.state.web3.TokenContractAbi,
          this.Wallet
        );
        window.tc = _tokenContract;
        this.$store.state.web3.TokenContract = _tokenContract;
        this.$store.state.web3.TokenName = await _tokenContract.name();
        this.$store.state.web3.TokenSymbol = await _tokenContract.symbol();

        const _rentContract = await new ethers.Contract(
          this.Sites[0].rentBC,
          this.$store.state.web3.RentContractAbi,
          this.Wallet
        );
        this.$store.state.web3.RentContract = _rentContract;
        this.$store.state.web3.RentContractAddress = this.Sites[0].rentBC;

        const _invoiceContract = await new ethers.Contract(
          this.Sites[0].invoiceBC,
          this.$store.state.web3.InvoiceContractAbi,
          this.Wallet
        );
        this.$store.state.web3.InvoiceContract = _invoiceContract;
        this.$store.state.web3.InvoiceContractAddress = this.Sites[0].invoiceBC;

        const _tokenBalance = await _tokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        console.log(err);
      }

      try {
        const _tokenBalance = await _tokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        this.$store.state.web3.TokenBalance = 0;
      }
      this.selectedLocation = this.Sites[0];
      this.$store.state.user.selectedSite = 0;
      // console.log("Sites CHANGED Finished");
      this.loginStatus = false;
      this.$router.push({ name: "home" });
      // try {
      //   this.$store.state.web3.TokenName = this.TokenContract.name();
      //   this.$store.state.web3.TokenSymbol = this.TokenContract.symbol();
      // } catch (err) {
      //   console.log(err)
      // }
    },
    tab(tab) {
      this.scanQr = false;
    },
  },
};
</script>
