import api from "../../../api";

export default {
  async getStatus({ commit }, item) {
    commit("REQUEST_MACHINES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_MACHINES",
        await api.getStatusMachine(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_MACHINES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  
  async startStop({ commit }, item ) {
    commit("REQUEST_UPDATE_MACHINE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_MACHINE",
        await api.startStopMachine(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_MACHINE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  
};
