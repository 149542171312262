<template>
  <div class="about">
    <v-card class="ma-10" >
      <v-row class="text-center">
        <v-col cols="12">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          Trying to Login...
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex";

export default {
  name: "Start",
  data() {
    return {
     
    };
  },
  mounted() {
   
  },
  methods: {

  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Wallet: ( {web3} ) => web3.Wallet,
      WalletBalance: ( {web3} ) => web3.Balance,
      WalletAddress: ( {web3} ) => web3.Address,
      authStatus: ({ auth }) => auth.status,
    }),
  },
  watch: {

  }
};
</script>
