<template>
  <div class="homeMain" @click="select_selected = false" v-if="selectedMachine">
    <v-row wrap>
      <v-col
        order="1"
        order-sm="1"
        cols="12"
        sm="auto"
        class="max-width=100"
        v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
      >
        <v-list nav dense lazy>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              v-for="(item, i) in machines"
              :key="i"
              v-on:click="changeMachine(item)"
            >
              <v-list-item-icon v-if="item.SubCategory.icon">
                <v-icon v-text="item.SubCategory.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-icon v-else>
                <v-icon v-text="item.SubCategory"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-if="item.Error.active" class="red--text"
                  >{{ item.Name }} - Fehler Aktiv</v-list-item-title
                >
                <v-list-item-title
                  v-else
                  v-text="item.Name"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col order="1" cols="12" class="ml-5 pr-14 mt-3 mb-0 pb-0" v-else>
        <v-select
          dense
          solo
          background-color="secondary"
          v-model="selectedMachine"
          :items="machines"
          item-text="Name"
          color="white"
          return-object
        >
        </v-select>
      </v-col>
      <!-- <v-col order="10" cols="12" class="mx-5" sm="8" v-if="!normalMode">
        <v-btn width="90%" @click="reloadPage" x-small color="success">
          Vollansicht
        </v-btn>
      </v-col> -->
      <v-col
        order="2"
        order-sm="2"
        cols="12"
        lg="9"
        v-if="!selectedMachine.Buyable"
      >
        <v-card class="searchOrderNr px-5" flat>
          <!--v-if="search_order"-->
          <v-row>
            <v-col cols="12" sm="10">
              <v-data-table
                @click:row="changeMachineNextContracts"
                :mobile-breakpoint="0"
                v-if="nextContracts"
                :headers="headers_contracts"
                :items="nextContracts"
                sort-by="starttime"
                class="elevation-1"
                dense
                must-sort
                hide-default-footer
                :item-class="row_classes"
              >
                <!--:search="search_order_number"-->
                <!-- <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title class="subtitle-2"
                      >Deine kommenden Reservierungen</v-toolbar-title
                    >
                    
                   <v-spacer></v-spacer>
                    <v-text-field
                      flat
                      single-line
                      append-icon="search"
                      hide-details
                      v-model="search_order_number"
                      label="Order Number"
                    ></v-text-field>
                  </v-toolbar>
                  
                </template>-->

                <template v-slot:item.StartTime="{ item }">
                  {{ item.StartTime | moment("DD.MMM HH:mm") }}
                </template>
                <template v-slot:item.SlotCount="{ item }">
                  {{
                    Math.floor(
                      (item.SlotCount *
                        getMachine(item.MachineID).MinutesPerTimeSlot) /
                        60
                    )
                  }}
                  h
                  {{
                    item.SlotCount *
                      getMachine(item.MachineID).MinutesPerTimeSlot -
                    Math.floor(
                      (item.SlotCount *
                        getMachine(item.MachineID).MinutesPerTimeSlot) /
                        60
                    ) *
                      60
                  }}
                  min
                </template>
                <template v-slot:item.MachineID="{ item }">
                  {{ getMachine(item.MachineID).Name }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="item.statuscolor" small>{{
                    item.statusname
                  }}</v-chip>
                </template>
              </v-data-table>
            </v-col>

            <v-col cols="12" sm="2" v-if="nextContracts[0]">
              <v-row>
                <v-col class="px-3 pt-4">
                  <v-btn
                    width="100%"
                    height="58px"
                    @click="openDoor()"
                    x-small
                    color="success"
                    :disabled="!selectedMachine.checkOpenDoor"
                  >
                    <div v-if="selectedMachine.checkOpenDoor">
                      Öffne Tür/Schloss<br />für {{ selectedMachine.Name }}
                    </div>

                    <div v-else>
                      Tür/Schloss<br />für {{ selectedMachine.Name
                      }}<br />gesperrt
                    </div>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="px-3" v-if="selectedMachine.checkStartStop">
                  <v-btn
                    width="100%"
                    height="58px"
                    v-if="statusMachine == 0"
                    @click="startStopMachine()"
                    x-small
                    color="success"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading">
                      <v-progress-circular
                        indeterminate
                        :size="13"
                        :width="2"
                        color="primary"
                      ></v-progress-circular>
                      Connecting
                    </span>
                    <span v-else
                      >{{ selectedMachine.Name }}<br /><br />einschalten</span
                    >
                  </v-btn>
                  <v-btn
                    width="100%"
                    height="58px"
                    v-else-if="statusMachine == 1"
                    @click="startStopMachine()"
                    x-small
                    color="warning"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading">
                      <v-progress-circular
                        indeterminate
                        :size="13"
                        :width="2"
                        color="primary"
                      ></v-progress-circular>
                      Connecting
                    </span>
                    <span v-else
                      >{{ selectedMachine.Name }}<br />ausschalten</span
                    >
                  </v-btn>

                  <v-btn
                    width="100%"
                    height="58px"
                    v-else
                    @click="checkActualMachineStatus()"
                    x-small
                    color="grey lighten-5"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading" class="grey--text">
                      <!-- <v-progress-circular
                        indeterminate
                        :size="13"
                        :width="2"
                        color="primary"
                      ></v-progress-circular> -->
                      Connecting
                    </span>
                    <span v-else class="grey--text"
                      >{{ selectedMachine.Name }}<br /><br />Keine Antwort</span
                    >
                  </v-btn>
                </v-col>
                <v-col class="px-3" v-else>
                  <v-btn
                    disabled
                    x-small
                    color="error"
                    width="100%"
                    height="58px"
                    wrap
                  >
                    <span>{{ selectedMachine.Name }}<br />gesperrt</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="12" v-if="selectedMachine">
              <VueCalendar
                :machine="selectedMachine"
                :isActiveContract="isActiveContract"
              ></VueCalendar>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col
        order="1"
        order-sm="1"
        cols="11"
        lg="9"
        class="ml-5"
        v-else-if="selectedMachine.Category == 'certificate'"
      >
        <!--Lagerstand: {{selectedMachine.Stock}}-->
        <HomeTests :machine="selectedMachine"></HomeTests>
      </v-col>
      <v-col order="1" order-sm="1" cols="11" lg="9" class="ml-5" v-else>
        <!-- Lagerstand: {{ selectedMachine.Stock }} -->
        <HomeBuyables :machine="selectedMachine"></HomeBuyables>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ethers, BigNumber, errors } from "ethers";
import { mapState, mapActions } from "vuex";
import _ from "lodash";

//import Calendar from "./Calendar.vue";
import VueCalendar from "./VueCalendar.vue";
import HomeBuyables from "./HomeBuyables.vue";
import HomeTests from "./HomeTests.vue";
import { requiredRule, emailRule } from "../utils/rules";
import { rentErrors } from "../utils/errors";

import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import Machine from "./Machine.vue";

export default {
  name: "Home",
  components: {
    VueCalendar,
    Machine, // make the <FullCalendar> tag available
    HomeBuyables,
    HomeTests,
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      search_order: true,

      requiredRule,
      emailRule,
      //nextContracts: [],
      headers_contracts: [
        {
          text: "Deine kommenden Verträge starten am/um",
          value: "StartTime",
          align: "start",
          sortable: false,
        },

        { text: "mit einer Dauer von", value: "SlotCount", sortable: false },
        {
          text: "Vertrags-Nr",

          sortable: false,
          value: "ContractNr",
        },

        { text: "Ressouce", value: "MachineID", sortable: false },
      ],
      search_order_number: "",
      selectedItem: 0,
      machines: [],
      selectedLocation: "",
      selectedMachine: undefined,
      machineEvents: [],
      isLoading: false,
      nextContracts: [],
      event_color_success: "#e8fff8",
      normalMode: true,
      paramMachine: 0,
      paramDoor: 0,

      timeOffset: 0,
    };
  },
  created: function () {
    this.timeOffset = new Date().getTimezoneOffset();
  },
  async mounted() {
    //console.log("Home Start")
    if (!this.Wallet) {
      this.logout();
    }

    this.initEvents();

    this.paramMachine = this.storeStateMachine; //this.$store.state.auth.machine;
    this.paramDoor = this.storeStateDoor; //this.$store.state.auth.door;

    if (this.paramMachine > 0) {
      console.log("Machine Link: ", this.paramMachine, this.paramDoor);
      this.normalMode = false;
    } else {
      this.normalMode = true;
    }

    this.$store.state.myInterval = this.refresh(60000);
    //console.log("set Interval: ", this.$store.state.myInterval);
    let _time = parseInt((this.$moment().format("X") / 60).toFixed(0));
    this.$store.state.user.selectedDate = _time;

    await this.loadMachines();
    await this.loadAllMyContracts();

    if (!this.paramDoor && this.paramMachine > 0) {
      this.changeMachineNextContracts({ MachineID: this.paramMachine });

      //if (this.selectedMachine.checkStartStop) {
      if (this.selectedMachine) {
        this.startStopMachine();
      }
    } else if (this.paramDoor) {
      this.changeMachineNextContracts({ MachineID: this.paramMachine });
      //if (this.selectedMachine.checkOpenDoor) {
      if (this.selectedMachine) {
        this.openDoor();
      }
    }

    //console.log("Home ", this.Machines)
  },
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      User: ({ user }) => user,
      selectedDate: ({ user }) => user.selectedDate,
      myUpcomingContracts: ({ user }) => user.myUpcomingContracts,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Events: ({ event }) => event.Events,
      CreatedEvent: ({ event }) => event.CreatedEvent,
      Event: (state) => state.event,
      Wallet: ({ web3 }) => web3.Wallet,
      WalletBalance: ({ web3 }) => web3.EtherBalance,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      TokenName: ({ web3 }) => web3.TokenName,
      TokenSymbol: ({ web3 }) => web3.TokenSymbol,
      WalletAddress: ({ web3 }) => web3.Address,
      authStatus: ({ auth }) => auth.status,
      Web3: (web3) => web3.web3,
      actualBlock: (web3) => web3.web3.actualBlock,
      statusMachine: (machine) => machine.machine.statusMachine,
      statusLock: (lock) => lock.lock,
    }),

    storeStateMachine() {
      return this.$store.state.auth.machine;
    },
    storeStateDoor() {
      return this.$store.state.auth.door;
    },

    selectedAddress() {
      return web3.currentProvider.selectedAddress;
    },
    getTokenBalance() {
      try {
        return ethers.utils.formatEther(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    isActiveContract() {
      // for (let _contract of this.nextContracts) {
      try {
        let _contract = _.find(this.nextContracts, {
          MachineID: this.selectedMachine.MachineId,
          checkStartStop: true,
        });
        if (_contract) {
          return _contract.ContractNr;
        } else {
          return 0;
        }
      } catch (err) {
        return undefined;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout"]),
    ...mapActions("lock", ["openLock"]),
    ...mapActions("machine", ["startStop", "getStatus"]),

    reloadPage() {
      this.normalMode = true;
    },
    row_classes(item) {
      if (this.$moment().format("X") >= item.StartTime) {
        let _style = "caption success_background"; //(this.event_color_own).toString();
        return _style;
      } else {
        return "caption";
      }
    },

    getMachine(_machineId) {
      return _.find(this.machines, { MachineId: _machineId });
    },
    getNextContracts() {
      const _nextContracts = _.sortBy(this.myUpcomingContracts, [
        "StartTime",
      ]).slice(0, 3);
      // console.log("Check Next Contracts ", _nextContracts);
      for (let _contract of _nextContracts) {
        _contract.checkStartStop = this.checkStartStop(_contract);
        if (_contract.checkStartStop) {
          this.checkActualMachineStatus(_contract);
        }
        _contract.checkOpenDoor = this.checkOpenDoor(_contract);
      }
      this.nextContracts = JSON.parse(JSON.stringify(_nextContracts));
      for (let _machine of this.machines) {
        //console.log("Set Machines Status ", _machine.MachineId);
        if (
          _.find(this.nextContracts, {
            checkOpenDoor: true,
            MachineID: _machine.MachineId,
          })
        ) {
          _machine.checkOpenDoor = true;
        } else {
          _machine.checkOpenDoor = false;
        }
        if (
          _.find(this.nextContracts, {
            checkStartStop: true,
            MachineID: _machine.MachineId,
          })
        ) {
          _machine.checkStartStop = true;
        } else {
          _machine.checkStartStop = false;
        }
      }
    },

    checkStartStop(item) {
      let _check =
        item.StartTime <= this.$moment().format("X") &&
        item.EndTime >= this.$moment().format("X");
      return _check;
    },
    checkOpenDoor(item) {
      return (
        item.StartTime - 30 * 60 <= this.$moment().format("X") &&
        item.EndTime >= this.$moment().format("X")
      );
    },

    async checkActualMachineStatus(_contract) {
      // console.log("get status from checkstartstop");

      if (!_contract) {
        _contract = _.find(this.nextContracts, {
          MachineID: this.selectedMachine.MachineId,
          checkStartStop: true,
        });
      }
      //console.log(_contract);

      //this.actualContract.isLoading = true;
      this.isLoading = true;
      await this.getStatus({
        machineId: _contract.MachineID,
        rentSite: this.Sites[this.selectedSite].rentBC,
      });
      // console.log("response: ", this.statusMachine);
      //this.actualContract.isLoading = false;
      this.isLoading = false;
      /*try {
        //console.log(this.statusMachine);
        _contract.status = this.statusMachine;
      } catch (err) {
        console.log("Timed out");
        _contract.status = 3;
      }*/

      //console.log(_check)
    },

    async startStopMachine() {
      /*console.log("getStatus")

      await this.getStatus({machineId: item.MachineID, rentSite: this.Sites[this.selectedSite].rentBC});
      console.log(this.MachineStatus);
      try {
        console.log(JSON.parse(this.statusMachine).running)
      } catch (err) {
        console.log("Timed out")
      }*/
      // console.log("startStop");
      this.isLoading = true;
      let _status = 2;
      //status 0: don't change (vertragsverlängerung), 1: machine on, 2: machine off
      if (this.statusMachine == 0) {
        _status = 1;
      } else if (this.statusMachine == 1) {
        _status = 0;
      }
      await this.startStop({
        status: _status,
        contractNr: this.isActiveContract,
        rentSite: this.Sites[this.selectedSite].rentBC,
      });
      // console.log(this.statusMachine);
      this.isLoading = false;
      /*try {
        console.log(this.statusMachine);
        this.selectedMachine.status = this.statusMachine;
      } catch (err) {
        console.log("Timed out");
      }*/
    },

    async openDoor() {
      //console.log(item);
      await this.openLock({
        contractNr: this.isActiveContract,
        rentSite: this.Sites[this.selectedSite].rentBC,
      });
      console.log(this.statusLock.statusOpenError.message);
      if (this.statusLock.statusOpen == "failure") {
        this.snackbar_text = this.statusLock.statusOpenError.message;
        this.snackbar = true;
      }
    },

    async loadContracts(machineId, _now) {
      let _contracts = [];
      try {
        //console.log("LOAD MY Contracts");
        //console.log("NOW: ", _now);
        //console.log("NOW BIG: ", await BigNumber.from(_now));
        let _machine = _.find(this.machines, { MachineId: machineId });
        //console.log("LOAD MY Contracts - of Machine ", _machine, this.machines);

        const _weekContracts =
          await this.Web3.RentContract.getAllWeeklyContracts(
            machineId,
            BigNumber.from(_now)
          );

        //console.log("weekContracts: ", _weekContracts);

        for (let _weekDay in _weekContracts) {
          for (
            let _eventId = 0;
            _eventId < _weekContracts[_weekDay].length;
            _eventId++
          ) {
            let _event = _weekContracts[_weekDay][_eventId];

            // console.log(
            //   "machine , timeslot ",
            //   machineId - 1,
            //   _machine.MinutesPerTimeSlot
            // );
            const _StartTime =
              _event.StartSlot * _machine.MinutesPerTimeSlot * 60;
            const _EndTime =
              (_event.StartSlot + _event.SlotCount) *
              _machine.MinutesPerTimeSlot *
              60;

            //console.log(_event.ContractNr, _check, parseInt(this.$moment().format("X")), (_check >= parseInt(this.$moment().format("X"))));

            if (
              _event.ContractNr > 0 &&
              _event.Renter.toLowerCase() ==
                this.mySelf.crypto_address.toLowerCase() &&
              _EndTime >= parseInt(this.$moment().format("X"))
            ) {
              let _contract = array2Object(
                await this.Web3.RentContract.getContract(_event.ContractNr)
              );
              _contract.ContractNr = _event.ContractNr;
              _contract.StartTime = _StartTime;
              _contract.EndTime = _EndTime;
              _contracts.push(_contract);
            }
          }
        }
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      //console.log("_contracts: ", _contracts);
      return _contracts;
    },

    changeMachine(item) {
      //this.selectedMachine = this.machines[idx];

      this.selectedMachine = item;

      // console.log(this.selectedMachine.Name);
    },

    changeMachineNextContracts(item) {
      let machine = _.find(this.machines, {
        MachineId: item.MachineID,
      });
      this.selectedMachine = machine;
      this.selectedItem = _.findIndex(this.machines, {
        MachineId: item.MachineID,
      });
    },

    async loadMachines() {
      // console.log("Load Machines ", this.Web3.RentContract);
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].Active) {
            try {
              //console.log(_machines[idx].SubCategory)
              _machines[idx].SubCategory = JSON.parse(
                _machines[idx].SubCategory
              );
            } catch (err) {
              //console.log(err)
            }

            //} && !_machines[idx].Buyable) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
            _machines[idx].MachineId = parseInt(idx) + 1;

            if (typeof _machines[idx].SubCategory == "object") {
              if (_machines[idx].SubCategory.visible) {
                this.machines.push(_machines[idx]);
              }
            } else {
              this.machines.push(_machines[idx]);
            }
          }
          // else if (_machines[idx].Active && _machines[idx].Buyable) {
          //   _machines[idx].MachineId = parseInt(idx) + 1;
          //   this.buyables.push(_machines[idx]);
          // }
        }
        // console.log("Load Machines - Set selectedMachine", this.machines);
        this.selectedMachine = this.machines[0];

        this.selectedItem = 0;
      }
    },

    async loadAllMyContracts() {
      let _contracts = [];
      for (let _machine of this.machines) {
        if (!_machine.Buyable) {
          _contracts = _contracts.concat(
            await this.loadContracts(_machine.MachineId, this.selectedDate)
          );

          const _nextWeek = parseInt(
            (
              this.$moment
                .unix(this.selectedDate * 60)
                .add(1, "week")
                .format("X") / 60
            ).toFixed(0)
          );

          _contracts = _contracts.concat(
            await this.loadContracts(_machine.MachineId, _nextWeek)
          );
        }
      }

      let _fiteredContracts = [];
      for (let _contract of _contracts) {
        if (!_.find(_fiteredContracts, { ContractNr: _contract.ContractNr })) {
          _fiteredContracts.push(_contract);
        }
      }
      this.$store.state.user.myUpcomingContracts = _fiteredContracts;

      //this.nextContracts = (_.sortBy(_contracts,['StartTime'])).slice(0,3);
    },
    initEvents() {
      if (!this.$store.blockListener) {
        this.$store.blockListener = true;
        window.ethersprovider.on("block", async (blockNumber) => {
          // Emitted on every block change
          console.log("New Block: ", blockNumber);

          this.$store.state.web3.actualBlock = blockNumber;

          try {
            let _eBalance = await this.Wallet.getBalance();
            this.$store.state.web3.EtherBalance = _eBalance;
          } catch (err) {
            console.log(err);
          }
          try {
            if (!_.isUndefined(this.Web3.TokenContract)) {
              this.$store.state.web3.TokenName =
                await this.Web3.TokenContract.name();
              this.$store.state.web3.TokenSymbol =
                await this.Web3.TokenContract.symbol();
              const _tokenBalance = await this.Web3.TokenContract.balanceOf(
                this.WalletAddress
              );
              this.$store.state.web3.TokenBalance = _tokenBalance;
            } else {
              console.log("No Token Contract");
            }
          } catch (err) {
            console.log(err);
          }
          //console.log(this.$store.state.web3.TokenBalance);
        });
      }
    },
    refresh(_interval) {
      return setInterval(async () => {
        //console.log("refresh");
        this.getNextContracts();
        //this.$forceUpdate();
      }, _interval);
    },

    handlePaymentCompleted(res) {
      console.log("PayPal payment received ", res);
    },
    handlePaymentCancelled(res) {
      console.log("PayPal payment canceled ", res);
    },
  },
  watch: {
    /*  WalletBalance () {
      console.log("Wallet Change");
      this.balance = this.WalletBalance;
      this.account = this.WalletAddress;
    }*/
    async selectedSite(idx) {
      this.selectedLocation = this.User.Sites[idx];

      await this.loadMachines();
      await this.loadAllMyContracts();

      //console.log("get contract: ",await this.Web3.RentContract.getContract(6));

      //ToDo: Ausklammern in PROD
      window.rent = await this.Web3.RentContract;
      window.invoice = await this.Web3.InvoiceContract;
      window.sitesRegistry = await this.Web3.SitesRegistryContract;
      window.token = await this.Web3.TokenContract;
      //window.wallet = await this.Wallet;
    },
    async actualBlock() {
      if (this.User.rentingAction) {
        // console.log("Renting Action");
        await this.loadAllMyContracts();
        this.$store.state.user.rentingAction = false;
      }
    },
    myUpcomingContracts() {
      // console.log("watch myUpcomingContracts ");
      this.getNextContracts();
    },

    storeStateMachine(_machine) {
      console.log("Change storeStateMachine ", _machine);
      if (_machine >= 0) {
        this.changeMachineNextContracts({ MachineID: _machine });

        //if (this.selectedMachine.checkStartStop) {
        if (this.selectedMachine) {
          this.startStopMachine();
        }
        this.$store.state.auth.machine = -1;
      }
    },
    storeStateDoor(_door) {
      console.log("Change storeStateDoor ", _door);
      if (_door >= 0) {
        this.changeMachineNextContracts({ MachineID: _door });
        //if (this.selectedMachine.checkOpenDoor) {
        if (this.selectedMachine) {
          this.openDoor();
        }
        this.$store.state.auth.door = -1;
      }
    },
  },
};
</script>


<style lang="scss">
.box {
  border: solid 1px red;
}
.success_background {
  background-color: #e8fff8;
}
</style>
