<template>
  <div class="machine" v-if="mySelf">
    <v-card flat v-if="isError">
      <v-row class="red">
        <v-col align="center">
          <!-- class="text-center">-->
          <v-progress-circular
            indeterminate
            :size="25"
            :width="4"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <div class="pt-2 white--text">{{message}}</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";


import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import { ethers, errors } from "ethers";
import {rentErrors} from "../utils/errors";

export default {
  name: "systemChecks",
  components: {},
  props: {
    buyable: false,
  },
  data() {
    return {

      message: "Checking System",
      isError: false,
      snackbar: false,
      snackbar_text: "",

      machines: [],
      loading_data: true,

      vatGroup: [],

      orgTypesProvider: ["MachineOwner", "Insurance"],
      orgTypesAdmin: ["Provider", "MachineOwner", "Insurance"],
      orgTypes: [],
      userPermissionLevel: 2,

      affOrganizations: []
    };
  },
  created() {
    //this.editedItemMachine = this.defaultItemMachine;
  },
  async mounted() { 
    await this.loadMachines();   
    await this.loadOrgas();
    
    let check = true;
    let msg = "";
    for (let machine of this.machines) {
      this.editedItemMachine = machine;
      this.changeMO(machine.MachineOwner);
      const _check = this.checkVAT();
      if (_check == false) {
        check = false;
        msg = msg + machine.Name + ", ";
      }
    }
    if (check) {
      this.message = "System OK"
    } else {
      this.isError = true;
      this.message = "System ERROR - Please Check: " + msg;
    }
    this.loading_data = false;

  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
      UserState: ({ user }) => user,
      actualBlock: (web3) => web3.web3.actualBlock,
    }),

  },
  methods: {

    checkVAT() {
     
      try {

        const machineOwner = _.find(this.organizations, {addressBC: this.editedItemMachine.MachineOwner});
        const vatMachineOwner = this.vatGroup[this.editedItemMachine.VATCategory].vatRate;
        const provider = _.find(this.organizations, {addressBC: this.Sites[this.selectedSite].siteOwner});
        const vatProvider =  provider.VAT.VATRate[this.editedItemMachine.VATCategory];
        //console.log(this.editedItemMachine.Name,this.editedItemMachine.Active, this.editedItemMachine.VATCategory, machineOwner.name,vatMachineOwner,provider.name,vatProvider);
        if (vatMachineOwner != vatProvider && provider.VAT.VATregistered && machineOwner.VAT.VATregistered && this.editedItemMachine.Active) {
           //console.log("ERROR VAT");
          return false;
        } else {
           //console.log("OK VAT");
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    async loadOrgas() {
      const orgs = await this.Web3.SitesRegistryContract.getAllOrganisations();
      let _organizations = array2ArrayObject(orgs);
      for (let org of _organizations) {
        org.VAT = array2Object(org.VAT);
        try {
          org.bankDetails = JSON.parse(org.bankDetails);
          }
        catch (err) {
          //console.log(err);
        }
      }
    
      //console.log(_organizations)
      if (!this.mySelf.roles.includes("admin")) {
        this.orgTypes = this.orgTypesProvider;
        this.userPermissionLevel = 2;
        //console.log("Load Org Aff ");
        let affs = [];
        for (let org of _organizations) {
          const _affs = await this.Web3.SitesRegistryContract.getAllOrganisationAffiliations(
            org.addressBC
          );
          _affs.forEach((_aff) => {
            if (_aff.toLowerCase() == this.mySelf.crypto_address.toLowerCase()) {
              affs.push(org.addressBC);
            }
          });
        }
        affs = _.uniq(affs);
        //console.log(affs);
        affs.forEach((aff) => {
          this.affOrganizations.push(
            _.find(_organizations, (org) => {
              return org.addressBC.toLowerCase() == aff.toLowerCase();
            })
          );
        });
      } else {
        this.orgTypes = this.orgTypesAdmin;
        this.userPermissionLevel = 0;
        this.affOrganizations = _.filter(_organizations, {});
      }
      this.organizations = _organizations;
      
      //console.log(this.organizations);
    },
    async loadMachines() {
      //console.log("Load Machines ", this.Web3.RentContract);
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].LastErrorID > 0) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
          } else {
            _machines[idx].Error = { active: false };
          }
          _machines[idx].MachineId = parseInt(idx) + 1;
          if (_machines[idx].Permissioned) { 
            try {
             _machines[idx].permissions = array2ArrayObject(
              await this.Web3.RentContract.getMachinePermissions(_machines[idx].MachineId, 0, 0));
            } catch (err) {
              console.log(err);
              this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
              this.snackbar = true;
            }
          }
          this.machines.push(_machines[idx]);
        }
      }
    },
     
    changeMO(item) {
      try {
        let org = _.find(this.organizations, {addressBC: item})
        //console.log(org)
        this.vatGroup = [];
        for (let rateIndex in org.VAT.VATRate) {
          let obj = {};
          obj.vatIndex = parseInt(rateIndex);
          obj.vatRate = org.VAT.VATRate[rateIndex];
          this.vatGroup.push(obj);
        }
      } catch (err) {
        //
      }
    }
  },
  watch: {
   
  }
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>