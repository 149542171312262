<template>
  <div class="homeTests" v-if="mySelf && machine">
    <v-card flat>
      <v-row>
        <v-col align="center">
          <v-row justify="center" class="pa-0 pt-3">
            <v-col cols="12" sm="4" md="3" lg="3" xl="2" class="pa-0">
              <v-btn
                class="ma-4"
                small
                elevation="1"
                @click="
                  scanType = 'customer';
                  scanQr = !scanQr;
                  user_id = '';
                  $refs.user_id.reset();
                  user = {};
                  owner = '';
                  buyed = false;
                "
                >Scan Kunden QR</v-btn
              >
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="3" xl="2" class="pa-0">
              <v-btn
                class="ma-4"
                small
                elevation="1"
                @click="
                  scanQr = false;
                  user_id = '';
                  $refs.user_id.reset();
                  createItemUser();
                "
                >Neuer Kunde</v-btn
              >
            </v-col>
            <v-col
              cols="8"
              sm="4"
              md="3"
              lg="3"
              xl="2"
              class="text-center pa-0 pt-4"
            >
              <v-text-field
                ref="user_id"
                dense
                :rules="digitsRule10"
                type="number"
                v-model="user_id"
                label="SozVersNr"
                @keydown.enter="
                  scanQr = false;
                  searchUserId();
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="3" xl="2" class="pa-0">
              <v-btn
                class="ma-4"
                small
                elevation="1"
                @click="
                  scanQr = false;
                  searchUserId();
                "
                >Suche SozVersNr.</v-btn
              >
            </v-col>
            <v-col  cols="12" sm="4" md="3" lg="3" xl="2" class="pa-0">
            <v-btn
              :disabled="!user._id"
              class="ma-4 black--text"
              color="orange"

              small
              elevation="1"
              @click="printCustomerQR"
              >PrintQR
            </v-btn>
           </v-col>
            <!-- <v-col
              cols="8"
              sm="4"
              md="3"
              lg="3"
              xl="2"
              class="text-center pa-0 pt-4"
            >
              <v-text-field
                dense
                type="date"
                v-model="startTime"
                label="Ausstellungsdatum"
                hint="DD.MM.YYYY"
              ></v-text-field>
            </v-col> -->
            <!-- <v-col
              cols="8"
              sm="4"
              md="3"
              lg="3"
              xl="2"
              class="text-center pa-0 pt-4"
            >
              <input
                type="datetime-local"
                v-model="user.lastQrPrintDate"
              >
            </v-col> -->
          </v-row>
          <vue-qr-reader
            v-if="scanQr"
            :videoWidth="280"
            :videoHeight="220"
            v-on:code-scanned="qrCodeArrived"
            :use-back-camera="scanQrCam"
          ></vue-qr-reader>


          <!-- <v-row id="printJS-form" v-if="user_id">
            <v-col cols="1">
              <vue-qr               
                width="118px"
                :text="user_id"
                :correctLevel="3"
              ></vue-qr>
            </v-col>
            <v-col cols="1">
              <br/>
              {{user_id}}
              <br/>
              {{startTime}}
            </v-col>
         
         </v-row> -->
           <v-row id="printJS-form" v-if="user.user_id" class="justify-center">
            
              <vue-qr               
                width="85px"
                height="85px"
                :text="user.user_id"
                :correctLevel="3"
              ></vue-qr>   
              <!-- 118px -->
              <br/>
              {{user_id}}
              <br/>
            
              <!-- {{startTime}} -->
              <!-- {{$moment().format("DD-MM-YYYY HH:mm")}} -->
              <!-- <div v-if="user.lastQrPrintDate"> -->
                {{$moment(user.lastQrPrintDate).format("DD-MM-YYYY HH:mm")}}
              <!-- </div>
              <div v-else>
                {{$moment().format("DD-MM-YYYY HH:mm")}}
              </div>       -->
          </v-row> 

          <br />
          <v-simple-table v-if="user.user_id" class="caption" width="100%" dense>
            <template v-slot:default>
              <tbody>
                <!-- <tr v-if="user.qr">
                  <td class="text-left">WebApp Kunden Login:</td>
                  <td align="right" class="pr-0 mr-0">
                    <vue-qr
                      width="100px"
                      :text="user.qr"
                      :correctLevel="3"
                    ></vue-qr
                    ><br />
                  </td>
                </tr> -->
                <tr>
                  <td class="text-left">Name:</td>
                  <td align="right">
                    {{ user.firstname }} {{ user.lastname }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">SozVersNr:</td>
                  <td align="right">
                    {{ user.user_id }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Geburtsdatum:</td>
                  <td align="right">
                    {{ user.birthday | moment("DD.MM.YYYY") }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Strasse:</td>
                  <td align="right">
                    {{ user.street }}  {{ user.street_number }} {{ user.door_number }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Ort:</td>
                  <td align="right">{{ user.postcode }} {{ user.town }}</td>
                </tr>
                <tr>
                  <td class="text-left">Telefon:</td>
                  <td align="right">
                    {{ user.phone }}
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Email:</td>
                  <td align="right">
                    {{ user.email }}
                  </td>
                </tr>
                <!-- <tr v-if="user.password">
                  <td class="text-left">Passwort:</td>
                  <td align="right">
                    {{ user.password }}
                  </td>
                </tr> -->
              </tbody>
            </template>
          </v-simple-table>
         
          <!-- <v-data-table
            v-if="!loading_data && 0"
            :headers="headers_machineContract"
            :items="machineContracts"
            sort-by="startTime"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ machine.Name }}</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.contractNr="{ item }">
             
              {{ item.contractNr }}
            </template>
            <template v-slot:item.startTime="{ item }">
             
              <div v-if="calcTime(item).minutesPerTimeSlot > 10">
                {{ calcTime(item).start | moment("utc", "DD.MMM.YY") }}
              </div>
              <div v-else>
                {{ calcTime(item).start | moment("DD.MMM.YY HH:mm") }}
              </div>
            </template>
            <template v-slot:item.endTime="{ item }">
              <div v-if="calcTime(item).minutesPerTimeSlot > 10">
                {{ calcTime(item).end | moment("utc", "DD.MMM.YY") }}
              </div>
              <div v-else>
                {{ calcTime(item).end | moment("DD.MMM.YY HH:mm") }}
              </div>
            </template>
            <template v-slot:item.actions_qr="{ item }">
              <div @click="printQr(item)">
                <vue-qr
                  width="100px"
                  :text="getMachineQr(item)"
                  :logoImage="logoQr"
                  :correctLevel="1"
                ></vue-qr>
              </div>
            </template>

            <template v-slot:item.actions_pdf="{ item }">
        
            </template>
            <template v-slot:header.actions_pdf="{ item }">
             
            </template>
          </v-data-table> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="" v-if="owner != ''">
          <!--Lagerstand: {{selectedMachine.Stock}}-->
          <v-btn
            color="success"
            v-on:click="
              sister = false;
              startTransaction();
            "
            v-if="checkAmount && !buyed"
            height="70px"
            width="100%"
            :disabled="localRentingAction"
            >{{ textBuy }}</v-btn
          >
          <v-btn
            v-else-if="buyed && !sister"
            height="70px"
            small
            width="100%"
            ><v-icon x-large left color="success"> mdi-check </v-icon>Zertifikat
            wurde erfolgreich ausgestellt</v-btn
          >
          <v-btn
            v-else-if="!checkAmount"
            height="70px"
            small
            disabled
            width="100%"
            >Nicht genug Token ({{ calculateRentPrice.totalBudget / 1000 }}
            {{ TokenName }})</v-btn
          >
        </v-col>
        <v-col
          cols="12"
          md="6"
          class=""
          v-if="owner != '' && typeof machine.SubCategory == 'object'"
        >
          <v-btn
            color="error"
            v-on:click="
              sister = true;
              startTransaction();
            "
            v-if="checkAmount && !buyed && sisterMachine"
            height="70px"
            width="100%"
            :disabled="localRentingAction"
            >{{ textBuy2 }}</v-btn
          >
          <v-btn
            v-else-if="buyed && sister"
            height="70px"
            small
            width="100%"
            ><v-icon x-large left color="success"> mdi-check </v-icon>Zertifikat
            wurde erfolgreich ausgestellt</v-btn
          >
          <v-btn
            v-else-if="!checkAmount"
            height="70px"
            small
            disabled
            width="100%"
            >Nicht genug Token ({{ calculateRentPrice.totalBudget / 1000 }}
            {{ TokenName }})</v-btn
          >
      
        </v-col>
       
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbar_color">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_transaction" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1"
            ><v-icon small left> mdi-cash-multiple </v-icon
            ><b>Zertifikat wird ausgestellt</b></span
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center h6"
                v-if="!loadingBuy && !checkAmount"
              >
                <p class="subtitle-2">Nicht genug Token!</p>
              </v-col>

              <v-col cols="12" class="text-center h6" v-else-if="!loadingBuy">
                <div v-if="!flagCheckLastQrDate" class="red pa-2">
                  <p class="h1 white--text">ACHTUNG!</p>
                  <p class="white--text ">Testdatum ist älter als 12h!</p>
                  <p class="white--text">Bitte abbrechen und neuen PRINTQR durchführen.</p>
                </div>
                <div v-if="!flagCheckDoubleTx" class="red pa-2">
                  <p class="h1 white--text ">ACHTUNG!</p>
                  <p class="white--text">Es wurde bereits ein Test innerhalb der letzten 12h ausgestellt!</p>
                </div>
                <div v-if="!transactionError">
                  <p class="subtitle-1">
                    <b
                      >Sende
                      {{ this.calculateRentPrice.totalBudget / 1000 }}
                      {{ this.TokenSymbol }}</b
                    >
                  </p>
                  <p class="subtitle-2">von Account</p>
                  <p class="caption">{{ this.mySelf.crypto_address }}</p>
                  <p class="subtitle-2">
                    <v-icon small> mdi-arrow-down-bold </v-icon>
                  </p>
                  <p class="subtitle-1">
                    <b>{{ this.Sites[this.selectedSite].name }}</b>
                  </p>
                  <div class="caption">
                    {{ this.Sites[this.selectedSite].rentBC }}
                  </div>
                </div>
                <div v-else class="red--text">
                  Fehler bei der Transaktion, bitte wiederholen oder abbrechen!
                </div>
              </v-col>
              <v-col cols="12" class="text-center h5" v-else>
                Transaktion wird durchgeführt<br /><br />
                <v-progress-circular
                  indeterminate
                  :size="50"
                  :width="7"
                  color="secondary"
                  v-if="loadingBuy"
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!loadingBuy">
          <v-btn color="error" text @click="transactionError = false; dialog_transaction = false;"
            >Abbrechen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="buy()"
            :disabled="!checkAmount || !flagCheckLastQrDate"
            >
            <div v-if="!transactionError">Durchführen</div><div v-else>Wiederholen</div></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="dialog_contract" max-width="500px">
      <v-card
        v-if="
          selectedMachineContract.contract &&
          selectedMachineContract.contract.error
        "
      >
        <v-card-title>
          Details zu Vertrag Nr {{ selectedMachineContract.contractNr }}
        </v-card-title>
        <v-card-text>
          Maschinen Nr: {{ selectedMachineContract.contract.MachineID }}
          <br />Abgeschlossen am:
          {{
            (selectedMachineContract.contract.ContractTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Mieter:
          {{ getRenter(selectedMachineContract.contract.Renter) }}
          <br />StartSlot: {{ selectedMachineContract.contract.StartSlot }}
          <br />SlotCount: {{ selectedMachineContract.contract.SlotCount }}
          <br />Verbrauchte kW:
          {{ (selectedMachineContract.contract.Watth / 1000).toFixed(3) }}
          <br />Betriebszeit in Minuten:
          {{ selectedMachineContract.contract.UsedMin }}

          <br />Kaution: {{ selectedMachineContract.contract.Deposit / 1000 }}
          <br />Reservierungsgebühr:
          {{ selectedMachineContract.contract.SlotPrice / 1000 }}
          <br />Betriebskosten:
          {{ selectedMachineContract.contract.RunPrice / 1000 }} <br />Gebühren:
          {{ selectedMachineContract.contract.Fees / 1000 }} <br />Summe:
          {{ selectedMachineContract.contract.TotalBudget / 1000 }}

          <br />Verrechnet: {{ selectedMachineContract.contract.Invoiced }}
          <br />Token transferiert:
          {{ selectedMachineContract.contract.MoneyTransferred }}
          <br />Rücküberweisung:
          {{ selectedMachineContract.contract.RenterReturn }}
          <br />Rücküberweisung Zeit:
          {{
            (selectedMachineContract.contract.DepositPayOutTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Rechnungsnummer Provider:
          {{ selectedMachineContract.contract.InvoiceProviderID }}
          <br />Rechnungsnummer Maschinenbesitzer:
          {{ selectedMachineContract.contract.InvoiceMachineOwnerID }}

          <br />Fehlernummer: {{ selectedMachineContract.contract.ErrorID }}
          <br />Fehler aktiv:
          {{ selectedMachineContract.contract.error.active }}
          <br />Fehlermeldung:
          {{ selectedMachineContract.contract.error.Description }} <br />Unset
          Description:
          {{ selectedMachineContract.contract.error.UnsetDescription }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog_contract = false"
            >Schliessen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog v-model="dialog_qr" max-width="250px">
      <v-card align="center" class="pa-5">
        <div class="h5">
          {{ machine.Name }} Nr. {{ selectedMachineContract.contractNr }}
        </div>
        <vue-qr
          :text="machineQr"
          :logoImage="logoQr"
          :correctLevel="1"
        ></vue-qr>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_user" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">{{ formTitleUser }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            style="width: 100%; height: 100%"
            class="compact-form"
          >
            <!-- <v-text-field
              disabled
              v-model="editedItemUser.crypto_address"
              label="BC Address"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.nickname"
              label="Angezeigter Kurzname"
            ></v-text-field> -->
            <v-select
              :items="genderTypes"
              v-model="editedItemUser.gender"
              label="Geschlecht"
            ></v-select>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.firstname"
              label="Vorname"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.lastname"
              label="Nachname"
            ></v-text-field>
            <v-text-field
              :rules="digitsRule10"
              type="number"
              required
              v-model="editedItemUser.user_id"
              label="Sozialversicherungsnummer"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule && svnrCheckRule"
              type="date"
              required
              v-model="editedItemUser.birthday"
              label="Geburtstag"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.street"
              label="Strasse"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.street_number"
              label="Hausnummer"
            ></v-text-field>
            <v-text-field
              v-model="editedItemUser.door_number"
              label="Türnummer"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              type="number"
              required
              v-model="editedItemUser.postcode"
              label="Postleitzahl"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.town"
              label="Ort"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.phone"
              label="Telefon Nr."
            ></v-text-field>
            <v-text-field
              :rules="emailRule"
              required
              v-model="editedItemUser.email"
              label="E-Mail"
            ></v-text-field>
            <v-checkbox v-model="dsgvo" :rules="requiredRule" required>
              <template v-slot:label>
                <div>
                  Ich stimme ausdrücklich der
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a target="_blank" href="/dsgvo" @click.stop v-on="on">
                        Datenschutzerklärung
                      </a>
                    </template>
                    Datenschutzerklärung in einem neuen Fenster öffnen
                  </v-tooltip>
                  zu.
                </div>
              </template>
            </v-checkbox>
          </v-form>
          <div v-if="!valid" class="red--text">Das Formular ist noch nicht korrekt ausgefüllt. Bitte um Vervollständigung um Speichern zu können.</div>
        </v-card-text>      
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeUser">Abbrechen</v-btn>
          <v-btn v-if="!valid" color="blue darken-1" text @click="validate"
            >Weiter</v-btn
          >
          <v-btn v-else color="blue darken-1" text @click="saveUser"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ethers, BigNumber, errors } from "ethers";
import { mapState, mapActions } from "vuex";
import _ from "lodash";
//import api from "../api";
import {
  requiredRule,
  emailRule,
  positiveValue,
  digitsRule4,
  digitsRule10,
} from "../utils/rules";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import VueQrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";
import { rentErrors } from "../utils/errors";

 import print from 'print-js'

export default {
  name: "homeTests",
  props: {
    machine: {},
  },
  components: {
    VueQrReader,
  },
  data() {
    return {
      dsgvo: false,
      valid: false,
      requiredRule,
      emailRule,
      positiveValue,
      digitsRule4,
      digitsRule10,
      svnrCheckRule: [
        //(v) => (v && this.$moment(v, "YYYY-MM-DD").format("DDMMYY") == this.editedItemUser.user_id.trim().substring(4,10)) || "Geburtstag stimmt nicht mit SVNr überein",
        (v) => (v 
          && this.$moment(v, "YYYY-MM-DD").format("DD") == this.editedItemUser.user_id.trim().substring(4,6) 
          && (
            this.$moment(v, "YYYY-MM-DD").format("MM") == this.editedItemUser.user_id.trim().substring(6,8) 
            || (this.editedItemUser.user_id.trim().substring(6,8)) >= 13 && Number(this.editedItemUser.user_id.trim().substring(6,8) <= 15)
          )
          && this.$moment(v, "YYYY-MM-DD").format("YY") == this.editedItemUser.user_id.trim().substring(8,10) 
          ) || "Geburtstag stimmt nicht mit SVNr überein",
      ],
      // dialog_delete: false,

      //dialog_machineContract: false,
      dialog_error: false,
      dialog_cancel_contract: false,
      dialog_contract: false,

      headers_machineContract: [
        { text: "QR Code", value: "actions_qr", sortable: false },
        { text: "Nummer", value: "contractNr" },
        {
          text: "Rechnung",
          value: "actions_pdf",
          sortable: false,
          align: "center",
        },
        { text: "gültig von", value: "startTime", groupable: false },
        { text: "bis", value: "endTime", groupable: false },
        //{ text: "rentBC", value: "rentBC" },
        //{ text: "machineId", value: "machineId" },
        //{ text: "Maschinenname", value: "machine.Name"  },
        //{ text: "finalized", value: "finalized", groupable: false  },
        //{ text: "usedSec", value: "usedSec", groupable: false  },
        //{ text: "wattH", value: "wattH", groupable: false  },
        //{ text: "maxWattPerHour", value: "machine.MaxWattPerHour", groupable: false  },
        //{ text: "blockNumber", value: "blockNumber" },
        //{ text: "error", value: "error", groupable: false  },
        //{ text: "Actions", value: "actions", sortable: false, groupable: false  },
        //{ text: "Cancel", value: "cancel", sortable: false, groupable: false },
      ],

      selectedMachineContract: {},
      selectedMachineContractIndex: 0,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      machineContracts: [],
      machines: [],
      unsetDescription: "",
      loading_data: false,

      calculateRentPriceTemplate: {
        RunPrice: 0,
        deposit: 0,
        extendContractNr: 0,
        slotPrice: 0,
        totalBudget: 0,
        priceFix: 0,
        priceVar: 0,
      },
      calculateRentPrice: {},
      dialog_transaction: false,
      loadingBuy: false,
      timeOffset: 0,
      localRentingAction: false,
      localInvoiceAction: false,
      dialog_qr: false,

      machineQr: "",
      logoQr: undefined,
      lastBlock: 0,

      scanQr: false,
      scanQrCam: true,
      scanType: "",
      owner: "",
      user: {},
      showUser: false,
      buyed: false,

      dialog_user: false,
      editedIndexUser: -1,

      defaultItemUser: {
        crypto_address: "",
        nickname: "",
        firstname: "",
        lastname: "",
        birthday: "2000-01-01",
        street: "",
        postcode: null,
        town: "",
        phone: "",
        password: "",
        email: "",
        password: "",
        membership_end: null,
        member: false,
        roles: [],
        activated: true,
        sites: [],
        bc: {
          _Discount: 0,
          _RfidNr: "",
          _Active: false,
          _FairUse: false,
        },
        user_id: "",
        dsgvo: false,
        street_number: "",
        door_number: "",
        gender: "",
        firebase_id: "0",
        lastQrPrintDate: null
      },
      editedItemUser: {},
      user_id: "",
      sister: false,
      //startTime: null,
      sisterMachine: null,
      genderTypes: ["m","w","d"],
      transactionError: false,
      flagCheckLastQrDate: true,
      flagCheckDoubleTx: true
      
    };
  },
  created: function () {
    this.timeOffset = new Date().getTimezoneOffset();
    this.editedItemUser = this.defaultItemUser;
    this.calculateRentPrice = this.calculateRentPriceTemplate;
    // BUG StartTime Bleibt im Cache bei Ruhezustand
    //this.startTime = this.$moment().format("YYYY-MM-DD");
    
  },
  async mounted() {
    await this.loadSister();

    //this.getUsers();
    //this.loadMachineContracts();
    // for (let i= 172488; i<= 172601; i++) {
    //   try {
    //     await sitesRegistry.incDummy({nonce: i, gasPrice: 400000000000});
    //     console.log("Finished Nonce ", i);
    //   } catch (err) {
    //       console.log("Err Nonce ", i);
    //   }
    // }
    console.log("HomeTest");
    //printJS('printJS-form', 'html');
  },
  computed: {
    ...mapState({
      status: ({ machineContract }) => machineContract.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      MachineContracts: ({ machineContract }) =>
        machineContract.MachineContracts,
      MachineContractState: ({ machineContract }) => machineContract,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      TokenName: ({ web3 }) => web3.TokenName,
      TokenSymbol: ({ web3 }) => web3.TokenSymbol,
      Wallet: ({ web3 }) => web3.Wallet,
      WalletAddress: ({ web3 }) => web3.Address,
      actualBlock: (web3) => web3.web3.actualBlock,
      Invoices: ({ invoice }) => invoice.Invoices,
      InvoiceState: ({ invoice }) => invoice,
      Users: ({ user }) => user.Users,
      UserState: ({ user }) => user,
    }),

    formTitleMachineContract() {
      return this.editedIndexMachineContract === -1
        ? "New MachineContract"
        : "Edit MachineContract";
    },
    checkAmount() {
      try {
        //console.log("CHECK AMOUNT");
        const totalBig = ethers.utils.parseEther(
          (this.calculateRentPrice.totalBudget / 1000).toString()
        );
        //console.log(totalBig.lte(this.TokenBalance));
        return totalBig.lte(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    textBuy() {
      let text = "";
      if (this.calculateRentPrice.totalBudget > 0) {
        text =
          "Für " +
          String(this.calculateRentPrice.totalBudget / 1000) +
          " € " + this.machine.SubCategory.msg;
      } else {
        text = this.machine.SubCategory.msg;
      }
      return text;
    },
    textBuy2() {
      let text = "";
      if (this.calculateRentPrice.totalBudget > 0) {
        text =
          "Für " +
          String(this.calculateRentPrice.totalBudget / 1000) +
          " € " + this.sisterMachine.SubCategory.msg;
      } else {
        text = this.sisterMachine.SubCategory.msg;
      }
      return text;
    },
    formTitleUser() {
      return this.editedIndexUser === -1 ? "Neuer User" : "Editiere User";
    },
  },
  methods: {
    ...mapActions("machineContract", ["getMachineContracts"]),
    ...mapActions("invoice", ["getInvoices"]),
    ...mapActions("user", ["getUsers", "createUserAuth", "updateUser"]),
    ...mapActions("auth", ["getMySelf", "logout"]),

    checkLastQrDate() {    
      try {
        let duration = this.$moment.duration(this.$moment().diff(this.user.lastQrPrintDate));
        let hours = duration.asHours();
        //console.log(this.user.lastQrPrintDate, duration, hours )
        if (hours > 12)  {
          this.flagCheckLastQrDate = false;
        } else {
          this.flagCheckLastQrDate = true;
        }
      } catch(err) {
        this.flagCheckDoubleTx = false;
        console.log(err);
      }
    },
    checkDoubleTx() {   
      try { 
        if (this.user.lastMachineContract) {
          let duration = this.$moment.duration(this.$moment().diff(this.user.lastMachineContract));
          let hours = duration.asHours();
          if (hours < 12)  {
            this.flagCheckDoubleTx = false;
          } else {
            this.flagCheckDoubleTx = true;
          }
        } else {
          this.flagCheckDoubleTx = true;
        }
      } catch(err) {
        this.flagCheckDoubleTx = false;
        console.log(err);
      }
    },
    createItemUser() {
      this.buyed = false;
      this.user = {};
      this.owner = "";
      this.editedItemUser = Object.assign({}, this.defaultItemUser);
      this.editedIndexUser = -1;
      this.dialog_user = true;
      this.$refs.form.reset();
    },
    closeUser() {
      this.dialog_user = false;
      this.dsgvo = false;
      this.$nextTick(() => {
        this.editedItemUser = Object.assign({}, this.defaultItemUser);
        this.editedIndexUser = -1;
      });
    },

    async printCustomerQR() {
      this.loading_data = true;
      try {
        this.user.lastQrPrintDate = new Date();
        //console.log(this.user)
        
        let i = 0;
        await this.updateUser(this.user);
        while (this.UserState.statusUpdate != "success") {
          await this.updateUser(this.user);
          i++;
          if (i > 2) {
            this.snackbar_text = "Fehler: Datum wurde nicht gespeichert. Bitte PrintQR wiederholen";
            this.snackbar_color = "orange";
            this.snackbar = true;
            break;
          }
        } 
        if (this.UserState.statusUpdate == "success") {
          printJS('printJS-form', 'html');
          //printJS({ printable: 'printJS-form', type: 'html',  style: '.custom-h3 { font-size:"30px";}' });
        }
        this.loading_data = false;
      } catch (err) {
        this.loading_data = false;
        console.log(err);
      }
    },
    async saveUser() {
      //console.log("saveUser");

      //console.log(this.editedItemUser)
      //if (this.editedIndexUser == -1) {
      if(this.$refs.form.validate()) {
        try {
          this.editedItemUser.sites = [
            { address: this.Sites[this.selectedSite].rentBC, active: true },
          ];
          //console.log(this.editedItemUser);
          this.editedItemUser.dsgvo = this.dsgvo;
          await this.createUserAuth(this.editedItemUser);
          if (this.UserState.statusCreate == "success") {
            this.editedItemUser._id = this.UserState.User._id;
            this.editedItemUser.createdAt = this.UserState.User.createdAt;
            this.user = this.UserState.User;
            this.owner = this.UserState.User.crypto_address;
            this.dialog_user = false;
            this.user_id = this.user.user_id;
            this.searchUserId();
          } else {
            this.snackbar_text = this.UserState.User.message;
            this.snackbar = true;
          }
        } catch (err) {
          console.log("Error CreateUserAuth ", err);
        }
      }
    },
    async qrCodeArrived(code) {
      this.scanQr = false;
      // try {
      //   ethers.utils.getAddress(code);
      //   if (this.scanType == "customer") {
      //     this.owner = code;
      //   }
      //   else if (this.scanType == "product") {
      //     //
      //   }
      // } catch (err) {
      //   this.snackbar_text = "QR Code nicht gültig";
      //   this.snackbar = true;
      //   this.owner = "";
      // }
      try {
        // await this.getUsers({
        //   access_token: code,
        // });
        await this.getUsers({
          user_id: code,
        });
        //console.log(this.Users);
        if (this.Users.length > 0) {
          this.user = this.Users[0];
          this.owner = this.Users[0].crypto_address;
          //console.log(this.owner);
        } else {
          this.user = {};
          this.owner = "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    qrErrorCaptured(error) {
      console.log(error);
      switch (error.name) {
        case "NotAllowedError":
          this.errorMessage = "Camera permission denied.";
          break;
        case "NotFoundError":
          this.errorMessage = "There is no connected camera.";
          break;
        case "NotSupportedError":
          this.errorMessage =
            "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
          break;
        case "OverconstrainedError":
          this.errorMessage = "Constraints don't match any installed camera.";
          break;
        default:
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      console.error(this.errorMessage);
      this.scanQr = false;
    },

    async searchUserId() {
      this.transactionError = false;
      this.buyed = false;
      this.user = {};
      this.owner = "";
      //let user_id = this.user_id.substring(0, 4);
      //let birthdate = this.user_id.substring(4, 10);
      // let birthday = this.$moment(String(birthdate), "DDMMYY").format(
      //   "YYYY-MM-DD"
      // );
      //console.log(birthday)
     
      try {
        await this.getUsers({
          user_id: this.user_id,
        });
        //console.log(this.UserState.statusUsers)
        if (this.UserState.statusUsers === "failure") {
          this.logout();
        } else {
        // await this.getUsers({
        //   user_id: user_id, birthday: birthday
        // });

          if (this.Users.length > 0) {
            this.user = this.Users[0];
            this.owner = this.Users[0].crypto_address;

            if (this.user.lastQrPrintDate == null) {
              this.user.lastQrPrintDate = new Date();
              await this.updateUser(this.user);
            }
            //console.log(this.owner);
          } else {
            this.user = {};
            this.owner = "";
          }
        }
      } catch (err) {
        console.log(err);
      }
      //console.log(this.user)

    },

    calcTime(item) {
      let obj = {};
      try {
        //console.log(this.machine)
        //const duration = this.machine.MinutesPerTimeSlot;

        //if (duration )
        obj.start = item.startTime;
        obj.end = item.endTime - 60;
        obj.maxSlots = this.machine.MaxSlots;
        obj.minutesPerTimeSlot = this.machine.MinutesPerTimeSlot;
      } catch (err) {}
      //console.log(obj)
      return obj;
    },
    async matchMachine() {
      try {
        await this.loadMachines();
        let machineContracts = this.MachineContracts;
        for (let machineContract of machineContracts) {
          machineContract.contract = array2Object(
            await this.Web3.RentContract.getContract(machineContract.contractNr)
          );
          machineContract.machine = _.find(this.machines, {
            MachineId: machineContract.machineId,
          });
        }
        this.machineContracts = machineContracts;
      } catch (err) {
        //
      }
    },

    // getMachineQr(item) {
    //   let obj = {};
    //   obj.rentBC = this.Sites[this.selectedSite].rentBC;
    //   obj.invoiceNr = item.contract.InvoiceProviderID;
    //   obj.contractNr = item.contractNr;
    //   return JSON.stringify(obj);
    // },

    async loadMachines() {
      // console.log("Load Machines ");
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].Active && !_machines[idx].Buyable) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
            _machines[idx].MachineId = parseInt(idx) + 1;
            this.machines.push(_machines[idx]);
          }
        }
      }
    },

    // async showContract(item) {
    //   this.selectedMachineContract = item;

    //   this.dialog_contract = true;
    // },

    getRenter(_address) {
      if (_address) {
        const _user = _.find(this.Users, {
          crypto_address: _address.toLowerCase(),
        });
        if (_user) {
          return _user.firstname + " " + _user.lastname;
        } else {
          return _address;
        }
      } else {
        return 0;
      }
    },

    async calcPrice() {
      try {
        let slotStart = Math.floor(
          this.$moment().format("X") / 60 / this.machine.MinutesPerTimeSlot
        );
        //console.log(slotStart, this.machine.MachineId, this.timeOffset);
        let machineId = this.machine.MachineId;
        let slotCount = this.machine.MaxSlots;

        if (this.sister) {
          machineId = this.sisterMachine.MachineId;
          slotCount = this.sisterMachine.MaxSlots;
        }

        this.calculateRentPrice = array2Object(
          await this.Web3.RentContract.calculateRentPrice(
            this.mySelf.crypto_address,
            machineId,
            slotStart,
            slotCount,
            this.timeOffset,
            false
          )
        );
        //console.log(this.calculateRentPrice);
      } catch (err) {
        console.log(err);
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      //console.log(JSON.stringify(this.calculateRentPrice));
    },

    async buy() {

      await this.getMySelf();
      //console.log(this.statusMySelf);
      if (this.statusMySelf === "failure") {
        //this.$router.push({path: "cryptologin"})
        this.logout();
      }

      const sendToken = ethers.utils.parseEther(
        (this.calculateRentPrice.totalBudget / 1000).toString()
      );
      this.lastBlock = this.actualBlock;
      //console.log("Buy on Block: " + this.actualBlock);

      //let formatStartTime = this.$moment().format("X");
      let formatStartTime = this.$moment().format("X");
      if (this.user.lastQrPrintDate) {
        formatStartTime = this.$moment(this.user.lastQrPrintDate).format("X");
      }

      // Bug: Routine manual startTime -> Cache Problem App!!!
      // Only for Redate Cert - vaccine green pass
      // this.startTime = this.$moment().subtract(1,"day").format("YYYY-MM-DD");
      // console.log("Check Start Time: ", this.startTime)
      // if (
      //   this.$moment(this.startTime, "YYYY-MM-DD") <
      //   this.$moment(this.$moment().format("YYYY-MM-DD"), "YYYY-MM-DD")
      // ) {
      //   formatStartTime = this.$moment(this.startTime, "YYYY-MM-DD").format(
      //     "X"
      //   );
      //   console.log("Check Start Time 1: ", formatStartTime)
      // }

      if (formatStartTime >= this.$moment().subtract(1, "year").format("X")) {
        
        try {
          let slotStart = Math.floor(
            formatStartTime / 60 / this.machine.MinutesPerTimeSlot
          );

          //console.log(slotStart);
          //console.log(slotStart.toFixed(0));
          
          let rentOk = false;
          try {
            
            if (this.calculateRentPrice.totalBudget > 0) {
              await this.Web3.TokenContract.approve(
                this.Web3.RentContractAddress,
                sendToken
              );
            }
            //console.log(Date.now())
            //let nonce = await this.Wallet.getTransactionCount();
            //console.log(nonce)
            //for (let i = 0; i <= 1000; i++) {
            let machineId = this.machine.MachineId;
            let slotCount = this.machine.MaxSlots;

            if (this.sister) {
              machineId = this.sisterMachine.MachineId;
              slotCount = this.sisterMachine.MaxSlots;
            }
            //console.log(machineId, this.transactionError)
            //CHeck error
            //this.owner = "0x2a"
            // if (!this.transactionError) { 
            //   machineId = -1;
            //   } 
            //console.log(machineId)

            //console.log(this.owner)
            if (this.owner.length > 20) {
              let tx = await this.Web3.RentContract.rent(
                //this.Web3.RentContract.rent(
                machineId,
                slotStart,
                slotCount,
                this.timeOffset,
                false,
                this.owner
                //{nonce: nonce++}
              ); //.then(obj => {console.log("Transaction Finished: ", i, " Time:", Date.now())});
              //console.log(tx)
              rentOk = true;
              this.transactionError = false;
              //this.transactionError = true;
            } else {
              this.transactionError = true;
            }

          } catch (err) {
            this.transactionError = true;
            console.log("ERROR Rent")
          }
          //}

          if (rentOk) {
            //console.log("Rent Ok")
            this.loadingBuy = true;
            //console.log(Date.now())
            // console.log("Rent TX: ",tx);
            // console.log("Gas TX: ",ethers.utils.formatUnits(tx.gasPrice, 'wei'));
            // console.log("Gas Limit TX: ",ethers.utils.formatUnits(tx.gasLimit, 'wei'));
            // const receipt = await window.ethersprovider.getTransactionReceipt(tx.hash);
            // console.log(receipt)
            // if (receipt) {
            //   console.log(receipt.gasUsed)
            //   console.log("Gas Used: ",ethers.utils.formatUnits(receipt.gasUsed, 'wei'));
            // }
            this.localRentingAction = true;

            //ToDo: if site ins sites of customer not exists -> write to user
            // for (let site of this.user.sites) {
            //   if (site.address == this.Sites[this.selectedSite] && site.active == false) {

            //   }
            // }
            if (!this.user.sites) {
              this.user.sites = [];
            }
            let site = _.find(this.user.sites, {
              address: (this.Sites[this.selectedSite].rentBC).toLowerCase(),
            });
            //console.log(site);
            if (!site) {
              let obj = {};
              obj.address = this.Sites[this.selectedSite].rentBC.toLowerCase();
              obj.active = true;
              //console.log(this.user);
              //console.log(obj);
              this.user.sites.push(obj);
              await this.updateUser(this.user);
            } else if (!site.active) {
              site.active = true;
              await this.updateUser(this.user);
            }
          }
          else {      //Rent not Ok
            console.log("Rent Not OK")
            this.transactionError = true;
          }
          //console.log(this.user.sites);
        } catch (err) {
          console.log(err);
          //this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
          this.snackbar_text = extractRevertReason(err)
          this.snackbar = true;
          this.loadingBuy = false;
        }
      } else {
        this.dialog_transaction = false;
        this.snackbar_text = "Ausstellungsdatum nicht gültig";
        this.snackbar = true;
        this.loadingBuy = false;
      }

      //Wait until next Block
      // console.log("LastBlock ", lastBlock , this.actualBlock)
    },
    async loadBalance() {
      try {
        const _tokenBalance = await this.Web3.TokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        console.log(err);
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
    },

    // async loadMachineContracts() {
    //   this.loading_data = true;
    //   this.calcPrice();
    //   await this.getMachineContracts({
    //     rentBC: this.Sites[this.selectedSite].rentBC.toLowerCase(),
    //     cancelled: false,
    //     machineId: this.machine.MachineId,
    //     //startTime: {$lte: this.$moment().utc().format('X')},
    //     //endTime: {$gte: this.$moment().utc().format('X')},
    //   }); //finalized: true, invoiced: true});
    //   this.editedItemMachineContract = Object.assign(
    //     {},
    //     this.defaultItemMachineContract
    //   );
    //   await this.matchMachine();
    //   await this.getInvoices(this.Sites[this.selectedSite]);
    //   this.loading_data = false;
    // },

    // showInvoicePdf(item) {
    //   const invoice = _.find(this.Invoices, {
    //     contract_number: item.contractNr,
    //   });

    //   if (invoice) {
    //     var download_url = "/api/invoice/" + invoice.pdf + ".pdf";
    //     var x = new XMLHttpRequest();
    //     x.open("GET", download_url, true);
    //     x.setRequestHeader(
    //       "Authorization",
    //       localStorage.getItem("Authorization")
    //     );
    //     x.responseType = "blob";
    //     x.onload = function (e) {
    //       let file = new File(
    //         [x.response],
    //         invoice.prefix + invoice.number + ".pdf",
    //         { type: "application/pdf" }
    //       );
    //       var objectURL = window.URL.createObjectURL(file);
    //       window.open(objectURL);
    //     };
    //     x.send();
    //   }
    // },
    // printQr(item) {
    //   this.selectedMachineContract = item;
    //   this.machineQr = this.getMachineQr(item);
    //   this.dialog_qr = true;
    // },
    // checkInvoice(item) {
    //   const invoice = _.find(this.Invoices, {
    //     contract_number: item.contractNr,
    //   });
    //   //console.log("Check Invoice " + invoice);
    //   if (invoice) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    startTransaction() {
      this.checkDoubleTx();
      this.checkLastQrDate();
      this.dialog_transaction = true;
      //A: Wegen falschen anklicken
      // if (this.calculateRentPrice.totalBudget == 0) {
      //   this.buy();
      // }
    },
    validate() {
      this.$refs.form.validate();
    },
    async loadSister() {
      if (typeof this.machine.SubCategory == "object") {
        if (this.machine.SubCategory.sister) {
          let sister = array2Object(
            await this.Web3.RentContract.getMachine(this.machine.SubCategory.sister)
          );
          sister.MachineId = this.machine.SubCategory.sister;
          sister.SubCategory = JSON.parse(sister.SubCategory);
          this.sisterMachine = sister;
        } else {
          this.sisterMachine = null;
        }
      } 
    }
  },
  watch: {
    user_id () {
      if (this.user != {}) {
        this.buyed = false;
        this.user = {};
        this.owner = "";
      }
    },
    async machine() {
      //console.log("Watch Machine: ",this.machine)
      //this.loadMachineContracts();
      await this.loadSister();
      //console.log(this.sisterMachine)
      this.buyed = false;
      this.owner = "";
      this.user = {};
    },
    actualBlock(blockNr) {
      if (this.lastBlock < blockNr - 2 && this.localRentingAction) {
        this.loadBalance();
        //this.loadMachineContracts();
        this.localRentingAction = false;
        this.buyed = true;
        this.loadingBuy = false;
        this.localInvoiceAction = true;
        this.dialog_transaction = false;
      }
      // ToDo: ersetzen durch BC Event
      if (this.lastBlock < blockNr - 4 && this.localInvoiceAction) {
        //console.log("invoice ready ")
        this.getInvoices(this.Sites[this.selectedSite]);
        this.localInvoiceAction = false;
      }
    },
  
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 8px !important;
}

/* table tr { border:1px solid #dddddd !important; } */
</style>