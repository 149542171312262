<template>
  <div class="machine" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <!-- class="text-center">-->
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-else
            :headers="headers_machine"
            :items="filteredMachines"
            sort-by="lastname"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            :item-class="row_classes"
            :search="search_number"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title v-if="!buyable">{{
                  $t("Resources")
                }}</v-toolbar-title>
                <v-toolbar-title v-else>{{ $t("Buyables") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="activeMachines"
                  small
                  @click="activeMachines = !activeMachines"
                  >Switch to Deactivated</v-btn
                >
                <v-btn v-else small @click="activeMachines = !activeMachines"
                  >Switch to Activated</v-btn
                >
                <v-spacer></v-spacer>
                <v-text-field
                  flat
                  single-line
                  append-icon="search"
                  hide-details
                  v-model="search_number"
                  label="Suche"
                ></v-text-field>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemMachine"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_machine" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <p class="subtitle-1">{{ formTitleMachine }}</p>
                    </v-card-title>

                    <v-card-subtitle>
                      <span class="caption">All Prices incl. VAT</span>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          disabled
                          v-model="editedItemMachine.MachineId"
                          label="MachineId"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.Name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.Category"
                          label="Category"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.SubCategory"
                          label="SubCategory/Icon"
                        ></v-text-field>
                        <v-text-field
                          v-if="buyable"
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.Stock"
                          label="Lagerstand"
                        ></v-text-field>
                        <!--<v-text-field
                          :rules="addressBCRule"
                          required
                          v-model="editedItemMachine.MachineOwner"
                          label="MachineOwner"
                        ></v-text-field>-->
                        <v-row>
                          <v-col cols="4" class="pt-0">
                            <v-checkbox
                              v-on:click="changeAffs"
                              v-model="onlyAffsOrganizations"
                              label="Meine Unternehmen"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="8">
                            <v-select
                              :items="selectedOrganizations"
                              item-text="name"
                              item-value="addressBC"
                              label="Unternehmen"
                              v-model="editedItemMachine.MachineOwner"
                              v-on:change="changeMO"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.MaxWattPerHour"
                          label="Maximaler Energieverbrauch/Stunde  (Wh)"
                        ></v-text-field>
                        <v-checkbox
                          v-model="editedItemMachine.Active"
                          label="Aktiv"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editedItemMachine.Permissioned"
                          label="Nur eingeschränkter Zugang"
                        ></v-checkbox>
                        <v-select
                          v-if="editedItemMachine.Permissioned"
                          :items="Users"
                          :item-text="getUserName"
                          item-value="crypto_address"
                          label="Berechtigte"
                          v-model="editedItemMachine.newPermissions"
                          multiple
                        >
                        </v-select>

                        <v-text-field
                          type="number"
                          :disabled="editedIndexMachine >= 0"
                          v-model="editedItemMachine.MinutesPerTimeSlot"
                          label="Minuten / Slot"
                        ></v-text-field>
                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.MaxSlots"
                          label="Maximale Reservierungszeit in Slots"
                        ></v-text-field>

                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.SlotPrice"
                          label="Reservierungsgebühr / Slot in 1/1000"
                        ></v-text-field>
                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.RunPricePerHour"
                          label="Betriebskosten / h in 1/1000"
                        ></v-text-field>
                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.RunPriceKiloWattPerHour"
                          label="Energiekosten / kWh in 1/1000"
                        ></v-text-field>
                        <v-text-field
                          type="number"
                          v-model="editedItemMachine.Deposit"
                          label="Kaution pro Buchung in 1/1000"
                        ></v-text-field>
                        <!--                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.CancelDuration"
                          label="CancelDuration"
                        ></v-text-field>-->
                        <!-- <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemMachine.VATCategory"
                          label="VATCategory"
                        ></v-text-field> -->
                        <v-select
                          v-if="machineOwnerVat"
                          :items="vatGroup"
                          item-text="vatRate"
                          item-value="vatIndex"
                          label="VAT Satz in Promille"
                          v-model="editedItemMachine.VATCategory"
                        >
                        </v-select>
                        <div
                          v-if="!checkVAT && dialog_machine"
                          class="red--text title"
                        >
                          Fehler - VAT Sätze differenzieren, bitte bei den
                          Unternehmen überprüfen!
                        </div>
                        <v-text-field
                          disabled
                          v-if="editedItemMachine.LastErrorID > 0"
                          v-model="editedItemMachine.LastErrorID"
                          label="Fehler aktiv"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="saveMachineStatus"
                        @click="closeMachine"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!valid"
                        color="blue darken-1"
                        text
                        @click="validate"
                        >Save</v-btn
                      >
                      <v-btn
                        v-else
                        color="blue darken-1"
                        text
                        :loading="saveMachineStatus"
                        @click="saveMachine"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog_qr" max-width="250px">
                  <v-card align="center" class="pa-5">
                    <div class="h5">{{ editedItemMachine.Name }}</div>
                    <vue-qr
                      :text="machineQr"
                      :logoImage="logoQr"
                      :correctLevel="1"
                    ></vue-qr>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog_serviceTime" max-width="350px">
                  <v-card align="center" class="pa-5">
                    <v-card-title>
                      <p class="subtitle-1">
                        Servicezeiten {{ editedItemMachine.Name }}
                      </p>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        dense
                        solo
                        background-color="secondary"
                        v-model="machineOpenDay"
                        :items="days"
                        item-text="name"
                        item-value="idx"
                        color="white"
                        v-on:change="setTimeSlots"
                      >
                      </v-select>
                      <v-text-field
                        :disabled="
                          machineWholeDay[machineOpenDay] ||
                          machineDayClosed[machineOpenDay]
                        "
                        dense
                        type="time"
                        v-model="machineOpenStart"
                        label="Start"
                      ></v-text-field>
                      <v-text-field
                        :disabled="
                          machineWholeDay[machineOpenDay] ||
                          machineDayClosed[machineOpenDay]
                        "
                        dense
                        type="time"
                        v-model="machineOpenEnd"
                        label="End"
                      >
                      </v-text-field>
                      <v-checkbox
                        :disabled="machineDayClosed[machineOpenDay]"
                        class="pt-0"
                        dense
                        color="blue"
                        v-model="machineWholeDay[machineOpenDay]"
                        label="Durchgehend gesperrt"
                      >
                      </v-checkbox>
                      <v-checkbox
                        :disabled="machineWholeDay[machineOpenDay]"
                        class="pt-0"
                        dense
                        color="blue"
                        v-model="machineDayClosed[machineOpenDay]"
                        label="Durchgehend buchbar"
                      >
                      </v-checkbox>
                      <v-btn
                        small
                        v-on:click="setMachineOpenTime()"
                        :loading="loadingMachineOpenTime"
                        >Tag speichern</v-btn
                      >
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog_serviceTime = false"
                        >Close</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:header.Permissioned="{ item }">
              <div v-if="mySelf.roles.includes('admin')">Permissioned</div>
            </template>
            <template v-slot:header.Stock="{ item }">
              <div v-if="buyable">Lagerstand</div>
            </template>
            <template v-slot:item.Stock="{ item }">
              <div v-if="item.Buyable">
                {{ item.Stock }}
              </div>
            </template>
            <template v-slot:header.Buyable="{ item }">
              <div v-if="mySelf.roles.includes('admin')">Buyable</div>
            </template>

            <template v-slot:item.Permissioned="{ item }">
              <div v-if="mySelf.roles.includes('admin')">
                <div v-if="item.Permissioned">
                  <v-icon x-small class="mr-2">mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon x-small class="mr-2">mdi-cancel</v-icon>
                </div>
              </div>
            </template>
            <template v-slot:item.Buyable="{ item }">
              <div v-if="mySelf.roles.includes('admin')">
                <div v-if="item.Buyable">
                  <v-icon x-small class="mr-2">mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon x-small class="mr-2">mdi-cancel</v-icon>
                </div>
              </div>
            </template>
            <template v-slot:item.MachineOwner="{ item }">
              {{ getOrganisation(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemMachine(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <template v-slot:header.actions_serviceTime="{ item }">
              <div v-if="!buyable">ServiceTime</div>
            </template>
            <template v-slot:item.actions_serviceTime="{ item }">
              <v-icon
                v-if="!buyable"
                small
                class="mr-2"
                @click="editedItemMachineServiceTime(item)"
                >mdi-calendar</v-icon
              >
            </template>
            <template v-slot:item.actions_qr="{ item }">
              <v-icon small class="mr-2" @click="printQr(item)"
                >mdi-qrcode</v-icon
              >
            </template>
            <template v-slot:item.Active="{ item }">
              <div v-if="item.Active">
                <v-icon x-small class="mr-2">mdi-check</v-icon>
              </div>
              <div v-else>
                <v-icon x-small class="mr-2">mdi-cancel</v-icon>
              </div>
            </template>
            <template v-slot:item.SlotPrice="{ item }">
              {{ item.SlotPrice / 1000 }}
            </template>
            <template v-slot:item.RunPricePerHour="{ item }">
              {{ item.RunPricePerHour / 1000 }}
            </template>
            <template v-slot:item.RunPriceKiloWattPerHour="{ item }">
              {{ item.RunPriceKiloWattPerHour / 1000 }}
            </template>
            <template v-slot:item.Deposit="{ item }">
              {{ item.Deposit / 1000 }}
            </template>

            <!--<template v-slot:item.actions2="{ item }">
              <div v-if="item.Error">
                <v-icon
                  v-if="item.Error.active"
                  small
                  class="mr-2"
                  @click="unsetError(item)"
                  >mdi-sync-alert</v-icon
                >
              </div>
            </template>-->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { requiredRule, addressBCRule } from "../utils/rules";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import { ethers, errors } from "ethers";
import { rentErrors } from "../utils/errors";

export default {
  name: "machine",
  components: {},
  props: {
    buyable: false,
  },
  data() {
    return {
      valid: false,
      requiredRule,
      addressBCRule,

      dialog_delete: false,
      dialog_serviceTime: false,
      dialog_machine: false,
      search_number: "",
      headers_machine: [
        { text: "Edit", value: "actions", sortable: false, align: "center" },
        {
          text: "ServiceTime",
          value: "actions_serviceTime",
          sortable: false,
          align: "center",
        },
        {
          text: "QR Code",
          value: "actions_qr",
          sortable: false,
          align: "center",
        },
        //{ text: "Unset Error", value: "actions2", sortable: false },
        { text: "Id", value: "MachineId" },
        { text: "Name", value: "Name" },
        { text: "Stock", value: "Stock" },
        { text: "Category", value: "Category" },
        { text: "SubCategory", value: "SubCategory" },
        { text: "MachineOwner", value: "MachineOwner" },
        { text: "Max kWh", value: "MaxWattPerHour" },
        //{ text: "VATRate", value: "VATRate" },
        //{ text: "VATavailable", value: "VATavailable" },
        { text: "Active", value: "Active" },
        { text: "Permissioned", value: "Permissioned" },
        { text: "Buyable", value: "Buyable" },
        { text: "Min / Slot", value: "MinutesPerTimeSlot" },
        { text: "Max Slots", value: "MaxSlots" },
        { text: "Res.Gebühr/Slot", value: "SlotPrice" },
        { text: "Betriebskosten/Slot", value: "RunPricePerHour" },
        { text: "Kosten/kWh", value: "RunPriceKiloWattPerHour" },
        { text: "Kaution", value: "Deposit" },
        { text: "VATCategory", value: "VATCategory" },
        //{ text: "CancelDuration", value: "CancelDuration" },
        { text: "LastErrorID", value: "LastErrorID" },
      ],

      editedIndexMachine: -1,

      defaultItemMachine: {
        MachineId: 0,
        Name: "",
        Category: "",
        SubCategory: "",
        MachineOwner: "",
        MaxWattPerHour: 0,
        VATCategory: 0,
        Active: true,
        Permissioned: false,
        Buyable: false,
        Stock: 0,
        MinutesPerTimeSlot: 0,
        MaxSlots: 0, //maximum number of slots per contract
        SlotPrice: 0, // Reserve Price
        RunPricePerHour: 0, //Fixkosten Kosten
        RunPriceKiloWattPerHour: 0, //Variable Kosten
        Deposit: 0,
        CancelDuration: 0, //if Buyable: TimeLimit after buy (rent) event; during this duration the contract can still be cancelled: suggestion 15 min == 900 s
        LastErrorID: 0,
        check: true,
      },
      editedItemMachine: {},
      snackbar: false,
      snackbar_text: "",

      machines: [],
      loading_data: true,
      saveMachineStatus: false,
      activeMachines: true,
      organizations: [],
      machineQr: "",
      logoQr: undefined,
      dialog_qr: false,

      vatGroup: [],
      //users: []
      //permissionList: []

      machineOpenStart: 0,
      machineOpenEnd: 0,
      machineOpenTime: [],
      loadingMachineOpenTime: false,
      loadingInsurance: false,
      loadingVoucherPrefix: false,
      machineWholeDay: [false, false, false, false, false, false, false],
      machineOpenDay: 0,
      machineDayClosed: [false, false, false, false, false, false, false],
      days: [
        { idx: 0, name: "Montag" },
        { idx: 1, name: "Dienstag" },
        { idx: 2, name: "Mittwoch" },
        { idx: 3, name: "Donnerstag" },
        { idx: 4, name: "Freitag" },
        { idx: 5, name: "Samstag" },
        { idx: 6, name: "Sonntag" },
      ],

      orgTypesProvider: ["MachineOwner", "Insurance"],
      orgTypesAdmin: ["Provider", "MachineOwner", "Insurance"],
      orgTypes: [],
      userPermissionLevel: 2,

      selectedOrganizations: [],
      affOrganizations: [],
      onlyAffsOrganizations: true,
    };
  },
  created() {
    this.editedItemMachine = this.defaultItemMachine;
  },
  async mounted() {
    //console.log("Machine")
    let rentBC = this.Sites[this.selectedSite].rentBC;

    await this.getUsers({
      sites: { $elemMatch: { address: rentBC.toLowerCase(), active: true } },
      roles: { $in: ["user", "superUser"] },
    });
    //console.log(this.Users)
    await this.loadMachines();
    // const _Machines = await this.Web3.RentContract.getAllMachines();
    // let _machines = array2ArrayObject(_Machines);
    // _machines.forEach( (_machine, idx)  => {
    //   _machine.MachineId = idx + 1;
    //   this.machines.push(_machine);
    // });

    // this.organizations = array2ArrayObject(
    //   await this.Web3.SitesRegistryContract.getAllOrganisations()
    // );

    await this.loadOrgas();

    this.editedItemMachine = Object.assign({}, this.defaultItemMachine);

    this.checkMachines();

    this.loading_data = false;
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
      UserState: ({ user }) => user,
      actualBlock: (web3) => web3.web3.actualBlock,
    }),

    formTitleMachine() {
      return this.editedIndexMachine === -1 ? "New Machine" : "Edit Machine";
    },
    filteredMachines() {
      return _.filter(this.machines, {
        Active: this.activeMachines,
        Buyable: this.buyable,
      });
    },
    checkVAT() {
      try {
        const machineOwner = _.find(this.organizations, {
          addressBC: this.editedItemMachine.MachineOwner,
        });
        const vatMachineOwner =
          this.vatGroup[this.editedItemMachine.VATCategory].vatRate;
        const provider = _.find(this.organizations, {
          addressBC: this.Sites[this.selectedSite].siteOwner,
        });
        const vatProvider =
          provider.VAT.VATRate[this.editedItemMachine.VATCategory];

        if (
          vatMachineOwner != vatProvider &&
          provider.VAT.VATregistered &&
          machineOwner.VAT.VATregistered
        ) {
          return false;
        } else {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    machineOwnerVat() {
      try {
        const machineOwner = _.find(this.organizations, {
          addressBC: this.editedItemMachine.MachineOwner,
        });

        if (machineOwner.VAT.VATregistered) {
          return 1;
        } else {
          return 0;
        }
      } catch (err) {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    checkMachines() {
      for (let machine of this.machines) {
        this.editedItemMachine = machine;
        this.changeMO(machine.MachineOwner);

        if (!this.checkVATMethod(machine)) {
          this.editedItemMachine.check = false;
        } else {
          this.editedItemMachine.check = true;
        }
      }
    },
    getUserName(user) {
      return user.firstname + " " + user.lastname;
    },
    changeAffs() {
      if (this.onlyAffsOrganizations) {
        this.selectedOrganizations = this.affOrganizations;
      } else {
        this.selectedOrganizations = this.organizations;
      }
    },
    async loadOrgas() {
      const orgs = await this.Web3.SitesRegistryContract.getAllOrganisations();
      let _organizations = array2ArrayObject(orgs);
      for (let org of _organizations) {
        org.VAT = array2Object(org.VAT);
        try {
          org.bankDetails = JSON.parse(org.bankDetails);
        } catch (err) {
          //console.log(err);
        }
      }

      //console.log(_organizations)
      if (!this.mySelf.roles.includes("admin")) {
        this.orgTypes = this.orgTypesProvider;
        this.userPermissionLevel = 2;
        //console.log("Load Org Aff ");
        let affs = [];
        for (let org of _organizations) {
          const _affs =
            await this.Web3.SitesRegistryContract.getAllOrganisationAffiliations(
              org.addressBC
            );
          _affs.forEach((_aff) => {
            if (
              _aff.toLowerCase() == this.mySelf.crypto_address.toLowerCase()
            ) {
              affs.push(org.addressBC);
            }
          });
        }
        affs = _.uniq(affs);
        //console.log(affs);
        affs.forEach((aff) => {
          this.affOrganizations.push(
            _.find(_organizations, (org) => {
              return org.addressBC.toLowerCase() == aff.toLowerCase();
            })
          );
        });
      } else {
        this.orgTypes = this.orgTypesAdmin;
        this.userPermissionLevel = 0;
        this.affOrganizations = _.filter(_organizations, {});
      }
      this.organizations = _organizations;

      //console.log(this.organizations);
    },
    async loadMachines() {
      //console.log("Load Machines ", this.Web3.RentContract);
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].LastErrorID > 0) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
          } else {
            _machines[idx].Error = { active: false };
          }
          _machines[idx].MachineId = parseInt(idx) + 1;
          if (_machines[idx].Permissioned) {
            try {
              _machines[idx].permissions = array2ArrayObject(
                await this.Web3.RentContract.getMachinePermissions(
                  _machines[idx].MachineId,
                  0,
                  0
                )
              );
            } catch (err) {
              console.log(err);
              this.snackbar_text =
                rentErrors[parseInt(extractRevertReason(err))];
              this.snackbar = true;
            }
          }
          this.machines.push(_machines[idx]);
        }
      }
    },
    async loadPermission() {
      try {
        const _perms = await this.Web3.RentContract.getMachinePermissions(
          this.editedItemMachine.MachineId,
          0,
          0
        );
        let permissions = JSON.parse(JSON.stringify(_perms));
        permissions = permissions.slice(1, permissions.length);
        for (let idx in permissions) {
          permissions[idx] = permissions[idx].toLowerCase();
        }
        this.editedItemMachine.permissions = permissions;
        this.editedItemMachine.newPermissions = permissions;
      } catch (err) {
        console.log(err);
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
        this.editedItemMachine.permissions = [];
        this.editedItemMachine.newPermissions = [];
      }

      // if (this.editedItemMachine.permissions.length > 0) {
      //   for (let user of this.Users) {
      //     if (this.editedItemMachine.permissions.includes(user.crypto_address)) {
      //       user.granted = true;
      //     } else {
      //       user.granted = false;
      //     }
      //   }
      // }
    },

    printQr(item) {
      this.editedIndexMachine = this.machines.indexOf(item);
      this.editedItemMachine = Object.assign({}, item);
      this.machineQr =
        "https://leas-e.makerspace.at/#/site/" +
        this.Sites[this.selectedSite].rentBC +
        "/machine/" +
        item.MachineId;
      this.dialog_qr = true;
    },
    // async unsetError(item) {
    //   console.log(item);
    //   if (item.LastErrorID > 0) {
    //     try {
    //       await this.Web3.RentContract.unsetError(item.MachineId, 0, 0);
    //       this.snackbar_text = "Machine Error Unset";
    //       this.snackbar = true;
    //       this.loadMachines();
    //     } catch (err) {
    //       this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
    //       this.snackbar = true;
    //     }
    //   } else {
    //     this.snackbar_text = "Kein Fehler aktiv";
    //     //this.snackbar_color = "success";
    //     this.snackbar = true;
    //   }
    // },
    async createItemMachine() {
      this.editedItemMachine = Object.assign({}, this.defaultItemMachine);
      this.editedItemMachine.MachineId = this.machines.length + 1;
      this.editedItemMachine.Buyable = this.buyable;
      this.editedIndexMachine = -1;
      this.dialog_machine = true;
    },

    async editItemMachine(item) {
      //console.log("editItem ", item);
      this.editedIndexMachine = this.machines.indexOf(item);
      this.editedItemMachine = Object.assign({}, item);
      this.changeMO(this.editedItemMachine.MachineOwner);
      await this.loadPermission();

      this.machineOpenTime = await this.Web3.RentContract.getAllTimeSlots(
        item.MachineId
      );
      this.setTimeSlots();

      this.selectedOrganizations = this.affOrganizations;

      this.dialog_machine = true;
    },

    async editedItemMachineServiceTime(item) {
      //console.log("editItem ", item);
      this.editedIndexMachine = this.machines.indexOf(item);
      this.editedItemMachine = Object.assign({}, item);
      this.changeMO(this.editedItemMachine.MachineOwner);

      this.machineOpenTime = await this.Web3.RentContract.getAllTimeSlots(
        item.MachineId
      );
      this.setTimeSlots();

      this.dialog_serviceTime = true;
    },
    /*async deleteItemMachine(item) {
      const index = this.machines.indexOf(item);
      try {
        await this.Web3.RentContract.delOrganisation(item.addressBC);
        this.machines.splice(index, 1);
      } catch (err) {
        console.log(err);
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
    },*/

    closeMachine() {
      this.dialog_machine = false;
      this.$nextTick(() => {
        /*this.editedItemMachine = Object.assign(
          {},
          this.defaultItemMachine
        );*/
        this.editedItemMachine = {};
        this.editedIndexMachine = -1;
      });
      this.resetValidation();
      //this.permissionList = [];
      this.checkMachines();
    },

    async saveMachine() {
      //console.log("saveMachine")
      try {
        this.editedItemMachine.MachineOwner = ethers.utils.getAddress(
          this.editedItemMachine.MachineOwner
        );

        if (this.editedIndexMachine > -1) {
          try {
            this.saveMachineStatus = true;
            //console.log("Save");
            //const MachineId = this.editedItemMachine.MachineId;
            //this.editedItemMachine = _.omit(this.editedItemMachine, "MachineId");
            //console.log(this.editedItemMachine);
            let tx = await this.Web3.RentContract.changeMachine(
              this.editedItemMachine.MachineId,
              this.editedItemMachine
            );
            //console.log(tx)
            //console.log(this.editedItemMachine.MachineId,
            //  this.editedItemMachine)
            //this.editedItemMachine.MachineId = MachineId;

            const to_disable = _.difference(
              this.editedItemMachine.permissions,
              this.editedItemMachine.newPermissions
            );
            const to_enable = _.difference(
              this.editedItemMachine.newPermissions,
              this.editedItemMachine.permissions
            );

            for (let idx in to_disable) {
              await this.Web3.RentContract.setMachinePermissionForRenter(
                this.editedItemMachine.MachineId,
                to_disable[idx],
                false
              );
            }
            for (let idx in to_enable) {
              await this.Web3.RentContract.setMachinePermissionForRenter(
                this.editedItemMachine.MachineId,
                to_enable[idx],
                true
              );
            }

            Object.assign(
              this.machines[this.editedIndexMachine],
              this.editedItemMachine
            );
          } catch (err) {
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
          }
          this.saveMachineStatus = false;
        } else {
          //let _gas = await this.Web3.RentContract.estimateGas.addMachine(this.editedItemMachine);
          //console.log("estimated gas: ", _gas);
          //console.log("gas limit: ", await this.Web3.RentContract.addMachine(this.editedItemMachine).gasLimit);
          try {
            this.saveMachineStatus = true;
            await this.Web3.RentContract.addMachine(this.editedItemMachine);
            //, {gasLimit: _gas}
            for (let idx in this.editedItemMachine.newPermissions) {
              await this.Web3.RentContract.setMachinePermissionForRenter(
                this.editedItemMachine.MachineId,
                permission[idx],
                true
              );
            }
            this.machines.push(this.editedItemMachine);
          } catch (err) {
            console.log(err);
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
          }
          this.saveMachineStatus = false;
        }

        this.closeMachine();
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getOrganisation(item) {
      for (let _org of this.organizations) {
        if (item.MachineOwner.toLowerCase() == _org.addressBC.toLowerCase()) {
          return _org.name;
        }
      }
    },
    changeMO(item) {
      try {
        let org = _.find(this.organizations, { addressBC: item });
        //console.log(org)
        this.vatGroup = [];
        for (let rateIndex in org.VAT.VATRate) {
          let obj = {};
          obj.vatIndex = parseInt(rateIndex);
          obj.vatRate = org.VAT.VATRate[rateIndex];
          this.vatGroup.push(obj);
        }
      } catch (err) {
        //
      }
    },
    checkVATMethod(item) {
      try {
        const machineOwner = _.find(this.organizations, {
          addressBC: item.MachineOwner,
        });
        const vatMachineOwner = this.vatGroup[item.VATCategory].vatRate;
        const provider = _.find(this.organizations, {
          addressBC: this.Sites[this.selectedSite].siteOwner,
        });
        const vatProvider = provider.VAT.VATRate[item.VATCategory];
        //console.log(machineOwner, vatMachineOwner, provider, vatProvider);
        if (
          vatMachineOwner != vatProvider &&
          provider.VAT.VATregistered &&
          machineOwner.VAT.VATregistered
        ) {
          return false;
        } else {
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    row_classes(item) {
      if (!item.check) {
        return "caption background-color: red";
      } else {
        return "caption";
      }
    },

    async setMachineOpenTime() {
      //console.log("setMachineOpenTime")

      const machineId = this.editedItemMachine.MachineId;
      this.loadingMachineOpenTime = true;
      let start = 0;
      let end = 0;
      if (this.machineWholeDay[this.machineOpenDay] == true) {
        end = 1440;
      } else {
        let _time = this.machineOpenStart.split(":");
        start = parseInt(_time[0]) * 60 + parseInt(_time[1]);
        _time = this.machineOpenEnd.split(":");
        end = parseInt(_time[0]) * 60 + parseInt(_time[1]);
      }
      //for (let i = 0; i < 7; i++) {
      if (this.machineDayClosed[this.machineOpenDay] == true) {
        //console.log("Del Time Slot")
        try {
          await this.Web3.RentContract.delTimeSlot(
            machineId,
            this.machineOpenDay,
            0
          );
        } catch (err) {
          this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
          this.snackbar = true;
          this.loadingMachineOpenTime = false;
        }
      } else {
        let time = this.machineOpenTime[this.machineOpenDay][0];
        if (!time) {
          try {
            //console.log("Add Time Slot")
            await this.Web3.RentContract.addTimeSlot(
              machineId,
              this.machineOpenDay,
              start,
              end
            );
          } catch (err) {
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
            this.loadingMachineOpenTime = false;
          }
        } else {
          try {
            //console.log("Change Time Slot")
            await this.Web3.RentContract.changeTimeSlot(
              machineId,
              this.machineOpenDay,
              0,
              start,
              end
            );
          } catch (err) {
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
            this.loadingMachineOpenTime = false;
          }
        }
      }

      //console.log(await this.Web3.RentContract.getAllTimeSlots(0));
    },
    setTimeSlots() {
      let time = this.machineOpenTime[this.machineOpenDay][0];
      //console.log(time)
      if (!time) {
        this.machineDayClosed[this.machineOpenDay] = true;
      } else if (time.stop == 1440) {
        this.machineWholeDay[this.machineOpenDay] = true;
      } else {
        let start = time.start;
        let end = time.stop;
        //console.log(start, time.stop)
        let hour = parseInt(start / 60);

        this.machineOpenStart =
          hour.toString().padStart(2, "0") +
          ":" +
          (start - hour * 60).toString().padStart(2, "0");
        hour = parseInt(end / 60);
        this.machineOpenEnd =
          hour.toString().padStart(2, "0") +
          ":" +
          (end - hour * 60).toString().padStart(2, "0");
        this.machineDayClosed[this.machineOpenDay] = false;
        this.machineWholeDay[this.machineOpenDay] = false;
      }
    },
  },
  watch: {
    async actualBlock() {
      if (this.loadingMachineOpenTime) {
        this.machineOpenTime = await this.Web3.RentContract.getAllTimeSlots(
          this.editedItemMachine.MachineId
        );
        this.loadingMachineOpenTime = false;
        //console.log(this.machineOpenTime)
      }
    },
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>