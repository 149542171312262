var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mySelf)?_c('div',{staticClass:"invoice"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',[(_vm.loading_data)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"width":7,"color":"secondary"}}):_c('v-data-table',{staticClass:"elevation-1 caption",attrs:{"headers":_vm.headers_invoice,"items":_vm.Invoices,"sort-by":"issue_date","sort-desc":"","dense":"","must-sort":"","footer-props":{ 'items-per-page-options': [25, 50, 100, -1] },"items-per-page":50,"item-class":"caption"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Invoices")))]),_c('v-spacer')],1)]},proxy:true},{key:"header.actions_send",fn:function(ref){
var item = ref.item;
return [((_vm.mySelf.roles.includes('admin') || _vm.mySelf.roles.includes('provider')))?_c('div',[_vm._v("Sende Email")]):_vm._e()]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.prefix + item.number))])]}},{key:"item.issuer_address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getIssuer(item))+" ")]}},{key:"item.recipient_address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRecipient(item))+" ")]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.issue_date,"DD.MM.YYYY"))+" ")]}},{key:"item.gross",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.gross).toFixed(2))+" ")]}},{key:"item.send",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.send,"DD.MM.YYYY HH:mm"))+" ")]}},{key:"item.actions_pdf",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.showInvoicePdf(item)}}},[_vm._v("picture_as_pdf")])]}},{key:"item.actions_send",fn:function(ref){
var item = ref.item;
return [((_vm.mySelf.roles.includes('admin') || _vm.mySelf.roles.includes('provider')))?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.clickSendInvoice(item)}}},[_vm._v("email")]):_vm._e()]}}],null,false,1279289727)})],1)],1)],1),_c('v-snackbar',{attrs:{"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}],null,false,4147570357),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }