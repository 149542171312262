import api from "../../../api";
import router from "../../../router";
import types from "./types";

export default {
  async signUp({ commit }, user) {
    commit("REQUEST_SIGNUP");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SIGNUP",
        await api.signUp(user)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SIGNUP");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async activateAccount({ commit }, { token }) {
    commit(types.REQUEST_ACTIVATE_ACCOUNT);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_ACTIVATE_ACCOUNT,
        await api.activateAccount({ token })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_ACTIVATE_ACCOUNT);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async forgotPassword({ commit }, { email }) {
    commit(types.REQUEST_FORGOT_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_FORGOT_PASSWORD,
        await api.forgotPassword({ email })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_FORGOT_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async resetPassword({ commit }, { token }) {
    commit(types.REQUEST_RESET_PASSWORD);
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        types.SUCCESS_REQUEST_RESET_PASSWORD,
        await api.resetPassword({ token })
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_RESET_PASSWORD);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateUser({ commit }, user) {
    commit("REQUEST_UPDATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_USER",
        await api.updateUser(user)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_USER", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getUsers({ commit }, query) {
    commit("REQUEST_USERS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_USERS", await api.getUsers(query));
    } catch (err) {
      commit("FAILURE_REQUEST_USERS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUser({ commit }, item ) {
    commit("REQUEST_CREATE_USER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_USER",
        await api.createUser( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_USER", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUserAuth({ commit }, item ) {
    commit("REQUEST_CREATE_USER_AUTH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_USER_AUTH",
        await api.createUserAuth( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_USER_AUTH", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createUserApp({ commit }, item ) {
    commit("REQUEST_CREATE_USER_APP");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_USER_APP",
        await api.createUserApp( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_USER_APP", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};