import api from "../../../api";

export default {
  async getLocks({ commit }) {
    commit("REQUEST_LOCKS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_LOCKS", await api.getLocks());
    } catch (err) {
      commit("FAILURE_REQUEST_LOCKS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteLock({ commit }, id) {
    commit("REQUEST_DELETE_LOCK");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_DELETE_LOCK", await api.deleteLock(id));
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_LOCK");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateLock({ commit }, item) {
    commit("REQUEST_UPDATE_LOCK");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_UPDATE_LOCK", await api.updateLock(item));
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_LOCK");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createLock({ commit }, item) {
    commit("REQUEST_CREATE_LOCK");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_CREATE_LOCK", await api.createLock(item));
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_LOCK");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async openLock({ commit }, item) {
    commit("REQUEST_OPEN_LOCK");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_OPEN_LOCK", await api.openLock(item));
    } catch (err) {
      commit("FAILURE_REQUEST_OPEN_LOCK", err);
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
