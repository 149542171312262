import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  User: void 0,
  Users: void 0,
  status: void 0,
  statusUpdate: void 0,

  statusDelete: void 0,
  statusCreate: void 0,
  statusAccountActivation: void 0,
  statusForgotPassword: void 0,
  statusResetPassword: void 0,
  statusUser: void 0,
  statusUsers: void 0,
  Sites: void 0,
  selectedSite: undefined,
  selectedDate: void 0,
  myUpcomingContracts: void 0,
  rentingAction: false
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
