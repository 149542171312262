<template>
  <div class="invoice" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-else
            :headers="headers_invoice"
            :items="Invoices"
            sort-by="issue_date"
            sort-desc
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{$t("Invoices")}}</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:header.actions_send="{ item }">
              <div v-if="(mySelf.roles.includes('admin') || mySelf.roles.includes('provider'))">Sende Email</div>
            </template>
            <template v-slot:item.number="{ item }">
              <div align="right"> {{ item.prefix + item.number}}</div>
            </template>
            <template v-slot:item.issuer_address="{ item }">
               {{getIssuer(item)}}
            </template>
              <template v-slot:item.recipient_address="{ item }">
                {{getRecipient(item)}}
            </template>
             <template v-slot:item.issue_date="{ item }">
              {{ item.issue_date | moment("DD.MM.YYYY") }}
            </template>
            <template v-slot:item.gross="{ item }">
              {{ parseFloat(item.gross).toFixed(2)}}
            </template>
            <template v-slot:item.send="{ item }">
              {{ item.send | moment("DD.MM.YYYY HH:mm") }}
            </template>
            <template v-slot:item.actions_pdf="{ item }">
              <v-icon  small @click="showInvoicePdf(item)">picture_as_pdf</v-icon>
            </template>
            <template v-slot:item.actions_send="{ item }">
              <v-icon v-if="(mySelf.roles.includes('admin') || mySelf.roles.includes('provider'))" small @click="clickSendInvoice(item)">email</v-icon>
            </template>
            <!--<template v-slot:item.birthday="{ item }"><div  class="caption">{{item.birthday}} </div></template>-->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";
import { array2ArrayObject} from "../functions/utils";
import { ethers, errors } from "ethers";

export default {
  name: "invoice",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_delete: false,
    
      dialog_invoice: false,
      dialog_invoice_site: false,
      headers_invoice: [
        //{ text: "Prefix", value: "prefix" },
        { text: "Öffnen", value: "actions_pdf", sortable: false , align: "center"},
        { text: "Sende Email", value: "actions_send", sortable: false , align: "center"},
        { text: "Nummer", value: "number" },
        { text: "Datum", value: "issue_date"},
        { text: "ContractNr", value: "contract_number" , align: "center" },
        { text: "Rechnungssumme", value: "gross", align: "right" },
        { text: "Aussteller", value: "issuer_address" },
        { text: "Empfänger", value: "recipient_address" },
        //{ text: "pdf", value: "pdf" },
        { text: "Versendet am", value: "send" },

      ],
      editedIndexInvoice: -1,
      
      defaultItemInvoice: {
        invoice_nr: "",
        provider_address: "",
        customer_address: "",
        pdf: "",
        send_to_customer: null
      },
      editedItemInvoice: this.defaultItemInvoice,
      snackbar: false,
      snackbar_text: "",

      //allSites: [],
      loading_data: true,
 
      organizations: []
    };
  },
  async mounted() {
    //const sites = await this.Web3.SitesRegistryContract.getAllSites();
    //this.allSites = array2ArrayObject(sites);
    //console.log(this.allSites);
    //console.log(this.Sites[this.selectedSite]);
    await this.getInvoices(this.Sites[this.selectedSite]);
    //await this.getInvoices();
    // console.log("Invoice loaded ", this.Invoices);
    /*for (let invoice of this.Invoices) {
      if (!_.isUndefined(invoice.contract_number)) {
      console.log("Loading Contract ", invoice.contract_number);
        let _contract = await this.Web3.RentContract.getContract(invoice.contract_number);
        invoice.contract = Array2Object(_contract);
      }
    }*/
    this.editedItemInvoice = Object.assign({}, this.defaultItemInvoice);

    this.organizations = array2ArrayObject(await this.Web3.SitesRegistryContract.getAllOrganisations());
    if (this.mySelf.roles.includes('admin') || this.mySelf.roles.includes('provider')) {
      await this.getUsers({});
    } 
    //this.amount = ethers.utils.parseUnits("5", "ether");

    //console.log (this.amount);
    this.loading_data = false;


    //console.log(await this.Web3.RentContract.getContract(92));
  },
  computed: {
    ...mapState({
      status: ({ invoice }) => invoice.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Invoices: ({ invoice }) => invoice.Invoices,
      InvoiceState: ({ invoice }) => invoice,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
    }),
    
    formTitleInvoice() {
      return this.editedIndexInvoice === -1 ? "New Invoice" : "Edit Invoice";
    },
  },
  methods: {
    ...mapActions("invoice", [
      "getInvoices",
      "sendInvoice"
    ]),
     ...mapActions("user", [
      "getUsers",
    ]),
    clickSendInvoice(item) {
      this.sendInvoice(item._id);
      item.send = this.$moment().toDate();
    },
    getIssuer(item) {
      for (let _org of this.organizations) {
        if (item.issuer_address.toLowerCase() == _org.addressBC.toLowerCase()) {
          return _org.name;
        }
      }
    },
    getRecipient(item) {
      for (let _org of this.organizations) {
        if (item.recipient_address.toLowerCase() == _org.addressBC.toLowerCase()) {
          return _org.name;
        }
      }
      if (this.mySelf.roles.includes('admin') || this.mySelf.roles.includes('provider')) {
        for (let _user of this.Users) {
          if (item.recipient_address.toLowerCase() == _user.crypto_address.toLowerCase()) {
            return _user.firstname + ' ' + _user.lastname;
          }
        }
      } else {
        return item.recipient_address.toLowerCase();
      }
    },
    /*comp_site (site) {
      try {
        let site_array = ""
        site.forEach(obj => {
          let _site = _.find(this.allSites, s => {return s.rentBC.toLowerCase() == obj.address.toLowerCase()});
          if (_site && obj.active) {
            site_array += _site.name + ", ";
          }
        })      
        return site_array
      }
      catch {
        return ""
      }
    },*/
    
    async editItemInvoice(item) {
      //console.log("editItem");
      this.editedIndexInvoice = this.Invoices.indexOf(item);
      this.editedItemInvoice = Object.assign({}, item);
      this.dialog_invoice = true;
    },

    async editItemInvoiceSite(item) {
      //console.log("editItem");
      this.editedIndexInvoice = this.Invoices.indexOf(item);
      this.editedItemInvoice = Object.assign({}, item);
      this.dialog_invoice_site = true;
    },

    async deleteItemInvoice(item) {
      const index = this.Invoices.indexOf(item);
      await this.deleteInvoice(item._id);
      if (this.InvoiceState.statusDelete == "success") {
        this.Invoices.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeInvoice() {
      this.dialog_invoice = false;
      this.$nextTick(() => {
        this.editedItemInvoice = Object.assign({}, this.defaultItem);
        this.editedIndexInvoice = -1;
      });
    },

    closeInvoiceSite() {
      this.dialog_invoice_site = false;
      this.$nextTick(() => {
        this.editedItemInvoice = Object.assign({}, this.defaultItem);
        this.editedIndexInvoice = -1;
      });
    },
    async saveInvoice() {
      //console.log("saveInvoice");

      if (this.editedIndexInvoice > -1) {
        await this.updateInvoice(this.editedItemInvoice);

        if (this.InvoiceState.statusUpdate == "success") {
          this.editedItemInvoice._id = this.InvoiceState.Invoice._id;
          this.editedItemInvoice.updatedAt = this.$moment(
            this.InvoiceState.Invoice.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          Object.assign(this.Invoices[this.editedIndexInvoice], this.editedItemInvoice);
        } else {
          this.snackbar_text = this.InvoiceState.Invoice.message;
          this.snackbar = true;
        }
      } else {
        await this.createInvoice(this.editedItemInvoice);
        if (this.InvoiceState.statusCreate == "success") {
          this.editedItemInvoice._id = this.InvoiceState.Invoice._id;
          this.editedItemInvoice.roles = ["invoice"];
          this.editedItemInvoice.activated = true;
          this.editedItemInvoice.updatedAt = this.$moment(
            this.InvoiceState.Invoice.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          this.Invoices.push(this.editedItemInvoice);
        } else {
          this.snackbar_text = this.InvoiceState.Invoice.message;
          this.snackbar = true;
        }
      }
      this.closeInvoice();
    },
    showInvoicePdf (item) {
        var download_url = "/api/invoice/" + item.pdf + ".pdf";
        var x=new XMLHttpRequest();
        x.open("GET", download_url, true);
        x.setRequestHeader("Authorization", localStorage.getItem("Authorization") );
        x.responseType = 'blob';
        x.onload=function(e){
          let file = new File([x.response], item.prefix+item.number+".pdf",{type: "application/pdf"})
          var objectURL = window.URL.createObjectURL(file);
          window.open(objectURL);
          }
        x.send();
    }    
  },
  watch: {
  
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
   font-size: 10px !important;
}
</style>