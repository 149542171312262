import Web3 from 'web3'
import { ethers } from "ethers";

async function getWeb3() {
    // Enable auto reconnection
    const options = {
        reconnect: {
            auto: true,
            delay: 1000, // ms
            maxAttempts: 5,
            onTimeout: false
        }
    };

    //window.wsProvider = new Web3.providers.WebsocketProvider('wss://leas-e.makerspace.at:8546', options);
    //window.wsProvider = new Web3.providers.WebsocketProvider('wss://wss-leas-e.makerspace.at', options);
    //window.ethersprovider = new ethers.providers.Web3Provider(window.wsProvider);

    window.wsProvider =  new Web3.providers.HttpProvider('https://leas-e.makerspace.at/http/');  
    window.ethersprovider = new ethers.providers.Web3Provider(window.wsProvider);
    //let provider = new Web3.providers.HttpProvider('http://leas-e.makerspace.at:8545');   


    //window.ethersprovider = new ethers.providers.JsonRpcProvider('https://leas-e.makerspace.at:8545');

    window.web3 = new Web3(window.wsProvider);
    window.web3.eth.handleRevert = true;

    return;

}
export { getWeb3 };