<template>
  <div class="homeCheckContract">
    <v-card flat v-if="!loading_data">
      <v-row>
        <v-col align="center" v-if="MachineCheck.cert_valid" >
          <div v-if="checkContract">
            <v-row>
              <v-col align="center" class="pt-0" v-if="MachineCheck.cert_valid" >
                <br/>
                <b>{{MachineCheck.name}} und Prüfzertifikat</b>            
                <br/>
                <v-icon color="success" size="100px">mdi-check</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center" class="pt-0" v-if="MachineCheck.cert_valid" >
                <b>Vorname:</b> {{MachineCheck.user.firstname}} 
                <br/>
                <b>Nachname:</b> {{MachineCheck.user.lastname}} 
                <br/>
                <b>Geburtsdatum:</b> {{MachineCheck.user.birthday | moment("DD.MM.YYYY")}} 
                <br/>
                <br/>
                <b>Getestet am:</b> 
                <br/>
                <b>{{MachineCheck.startTime | moment("DD.MM.YYYY HH:mm")}}</b>
                <br/>
                <br/>
                <b>Eintrittstest gültig bis: </b>
                <br/>
                <div v-if="MachineCheck.endTime >= $moment().format('X')" class="green--text">
                  <b>{{MachineCheck.endTime | moment("DD.MM.YYYY HH:mm")}}</b>
                </div>
                <div v-else class="red--text">
                  <b>{{MachineCheck.endTime | moment("DD.MM.YYYY HH:mm")}}</b>
                </div>
                <br/>
                <br/>
                <b>Berufsgruppentest gültig bis: </b>
                <br/>
                <div v-if="MachineCheck.endTime2 >= $moment().format('X')" class="green--text">
                  <b>{{MachineCheck.endTime2 | moment("DD.MM.YYYY HH:mm")}}</b>
                </div>
                <div v-else class="red--text">
                  <b>{{MachineCheck.endTime2 | moment("DD.MM.YYYY HH:mm")}}</b>
                </div>
                <br/>
                <br/>
                <div v-if="MachineCheck.organisation.name">
                  <b>Aussteller:</b> 
                  <br/>
                  {{MachineCheck.organisation.name}}, {{ MachineCheck.organisation.codeTown }}, {{ MachineCheck.organisation.country }}
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-icon color="error" size="100px">mdi-cancel</v-icon>
            <br/>
            Test nicht mehr gültig
          </div>
          
        </v-col>
        <v-col align="center" v-else >
          <v-icon color="error" size="100px">mdi-cancel</v-icon>
          <br/>
          Zertifikat ungültig
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
//import VueQrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";

export default {
  name: "homeCheckContract",
  props: {

  },
  components: {

  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      loading_data: true,

      startTime: 0,
      endTime: 0
    };
  },
  created: function () {

  },
  async mounted() {
    //console.log("Check Contract ",this.$route.query)
    await this.checkMachineContract(this.$route.query);
    this.loading_data = false;
  },
  computed: {
    ...mapState({
      status: ({ machineContract }) => machineContract.status,
      loading: (state) => state.loading,
      Drawer: (state) => state.drawer,
      MachineCheck: ({ machineContract }) =>
        machineContract.MachineCheck,
    }),
    checkContract () {
      // this.startTime = this.MachineCheck.contract.StartSlot * this.MachineCheck.machine.MinutesPerTimeSlot * 60;
      // this.endTime = (this.MachineCheck.contract.StartSlot + this.MachineCheck.contract.SlotCount) * this.MachineCheck.machine.MinutesPerTimeSlot * 60;
      //console.log(this.startTime, this.endTime, parseInt(this.$moment().format('X')))
      // if (this.startTime <= parseInt(this.$moment().format('X')) &&
      //   this.endTime >= parseInt(this.$moment().format('X'))){
      //   return true;
      // } else {
      //   return false;
      // } 
      if (this.MachineCheck.cert_valid) { // && this.MachineCheck.time_valid){
        return true;
      } else {
        return false;
      } 
    },
  },
  methods: {
    ...mapActions("machineContract", ["checkMachineContract"]),
    
  },
  watch: {
  
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>