<template>
  <v-container style="height: 400px"> 
     <!-- style="min-height: 520px"> -->
    <v-row wrap justify="center"  v-if="firebase_id">
      <v-col cols="12" md="6" lg="5" v-if="users.length >= 1">
        <v-select
          dense
          solo
          background-color="secondary"
          v-model="selectedUser"
          :items="users"
          :item-text="getUserName"
          label="Wähle Benutzer"
          color="white"
          return-object
          v-on:change="login"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row wrap justify="center" v-if="firebase_id">
      <v-col cols="12" md="6" lg="5" class="text-center mb-3 mt-5" >
        <v-btn
          color="green"
          
          dark
          width="100%"
          @click="
            show_login = false;
            createItemUser();
          "
        >
          <v-icon class="mr-5"> mdi-account-plus </v-icon> Registriere neuen
          Benutzer
        </v-btn>
      </v-col>
    </v-row>
    <v-row wrap justify="center" v-if="firebase_id">
      <v-col cols="12" md="6" lg="5" class="text-center  mb-2" >
        <v-btn
          color="green"
          
          dark
          width="100%"
          @click="show_login = !show_login"
        >
          <v-icon class="mr-5">mdi-login</v-icon> Anmelden mit Passwort
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col style="max-width: 500px" v-if="show_login">
        <v-card flat color="transparent">
          <v-card-text>
            <v-card>
              <v-card-title class="title">Benutzer anmelden</v-card-title>
              <v-card-text>
                <v-alert type="error" :value="status === 'failure'"
                  >User ID or password incorrect.</v-alert
                >
              </v-card-text>
              <v-card-text>
                <v-alert type="success" :value="password_success"
                  >Passwort erfolgreich geändert.</v-alert
                >
              </v-card-text>
              <v-card-text>
                <v-form ref="form" v-model="valid" style="width: 100%; height: 100%">
                  <v-text-field
                    :rules="digitsRule10"
                    required
                    id="user_id"
                    v-model="user_id"
                    label="Sozialversicherungsnummer"
                  ></v-text-field>
                  <v-text-field
                    v-if="!changePasswordFlag"
                    type="password"
                    @keydown.enter="startLogin"
                    id="password"
                    v-model="password"
                    label="Password"
                  ></v-text-field>
                  <v-text-field
                    v-if="changePasswordFlag"
                    type="password"
                   
                    v-model="password"
                    label="Altes Passwort"
                  ></v-text-field>
                  <v-text-field
                    v-if="changePasswordFlag"
                    type="password"
                    :rules="passwordRule"
                    v-model="new_password"
                    label="Neues Passwort"
                  ></v-text-field>
                  <v-text-field
                    v-if="changePasswordFlag"
                    type="password"
                    :rules="passwortCheckRule"
                    label="Passwort bestätigen"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <!-- <v-btn
                  rounded
                  outlined
                  color="primary"
                  @click="$router.push('home')"
                  >Zurück</v-btn
                > -->
                 <v-btn
                  v-if="!changePasswordFlag"
                  outlined
                  rounded
                  color="primary"
                  @click="password_success = false; changePasswordFlag = !changePasswordFlag;"
                  >Passwort ändern</v-btn
                >
                <v-spacer></v-spacer>

                <v-btn
                  rounded
                  :disabled="!valid"
                  v-if="!changePasswordFlag"
                  outlined
                  color="primary"
                  :loading="loading"
                  @click="password_success = false; startLogin();"
                  >Anmelden</v-btn
                >
                <!-- <v-btn
                  v-if="mySelf"
                  rounded
                  :disabled="!valid"
                  outlined
                  color="primary"
                  :loading="loading"
                  @click="$router.push({ name: 'homeReadOnly' })"
                >Login</v-btn> -->
              </v-card-actions>
              
              <!-- <p align="center"> -->
               
               
                <v-btn
                  v-if="changePasswordFlag"
                  outlined
                  rounded
                  color="primary"
                  :disabled="!valid"
                  @click="changePassword();"
                  >Neues Passwort Speichern</v-btn
                >
              
                <v-btn
                  v-if="changePasswordFlag"
                  outlined
                  rounded
                  color="primary"
                 
                  @click="changePasswordFlag = false"
                  >Abbrechen</v-btn
                >
                <!-- <router-link :to="{ name: 'resetPassword' }">{{
                  $t("Forgot password?")
                }}</router-link> -->
                <!-- <br />
                <br />
                <router-link
                  :to="{name: 'signUp'}"
                >{{$t("You don't have an account yet and you want to sign up?")}}</router-link>
                -->
                <br />
                <br /> 
              <!-- </p> -->
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_user" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1">{{ formTitleUser }}</span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            style="width: 100%; height: 100%"
            class="compact-form"
          >
            <!-- <v-text-field
              disabled
              v-model="editedItemUser.crypto_address"
              label="BC Address"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.nickname"
              label="Angezeigter Kurzname"
            ></v-text-field> -->
            <v-select
              :items="genderTypes"
              v-model="editedItemUser.gender"
              label="Geschlecht"
            ></v-select>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.firstname"
              label="Vorname"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.lastname"
              label="Nachname"
            ></v-text-field>
            <v-text-field
              :rules="digitsRule10"
              type="number"
              required
              v-model="editedItemUser.user_id"
              label="Sozialversicherungsnummer (10-stellig)"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule && svnrCheckRule"
              type="date"
              required
              v-model="editedItemUser.birthday"
              label="Geburtstag"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.street"
              label="Strasse"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.street_number"
              label="Hausnummer"
            ></v-text-field>
            <v-text-field
              v-model="editedItemUser.door_number"
              label="Türnummer"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              type="number"
              required
              v-model="editedItemUser.postcode"
              label="Postleitzahl"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.town"
              label="Ort"
            ></v-text-field>
            <v-text-field
              :rules="requiredRule"
              required
              v-model="editedItemUser.phone"
              label="Telefon Nr."
            ></v-text-field>
            <v-text-field
              :rules="emailRule"
              required
              v-model="editedItemUser.email"
              label="E-Mail"
            ></v-text-field>
            <v-text-field
              required
              :rules="emailCheckRule"
              label="E-Mail bestätigen"
            ></v-text-field>
            <v-checkbox v-model="dsgvo" :rules="requiredRule" required>
              <template v-slot:label>
                <div>
                  Hiermit erkläre ich ausdrücklich meine Zustimmung zur elektronischen Erfassung und Weiterverarbeitung meiner Daten sowie
                  die freiwillige Durchführung eines Testabstrichs zur Durchführung eines Antigen-Schnelltests auf SARS-CoV-2 / Covid-19
                  (gemäß Informationen und Datenschutzerklärung
                  
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <!-- <a
                        target="_blank"
                        href="/dsgvo"
                        @click.stop
                        v-on="on"
                      >
                        Datenschutzerklärung
                      </a> -->
                           <a
                        target="_blank"
                        href="/DSGVO-Groco-Teststrasse.pdf"
                        @click.stop
                        v-on="on"
                      >
                        hier klicken
                      </a>
                    </template>
                    Datenschutzerklärung in einem neuen Fenster öffnen
                  </v-tooltip>
                  )
                </div>
              </template>
            </v-checkbox>

          </v-form>
          <div v-if="!valid" class="red--text">Das Formular ist noch nicht korrekt ausgefüllt. Bitte um Vervollständigung um Speichern zu können.</div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeUser">Abbrechen</v-btn>
          <v-btn v-if="!valid" color="blue darken-1" text @click="validate">Weiter</v-btn>
          <v-btn v-else color="blue darken-1" text @click="saveUser">Speichern</v-btn>
        </v-card-actions>
              <input type="text"
              style="color:white"
              v-model="editedItemUser.floor"
              name="Stiege"
              size=1
            >
      </v-card>
            
    </v-dialog>
     <v-snackbar v-model="snackbar" :color="snackbar_color">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { digitsRule10, emailRule, requiredRule, passwordRule } from "../utils/rules";

export default {
  name: "LoginFirebase",
  data() {
    return {
      passwortCheckRule: [
        (v) => (v && v == this.new_password) || "Passwörter stimmen nicht überein",
      ],
      emailCheckRule: [
        (v) => (v && this.editedItemUser.email && v.trim() == this.editedItemUser.email.trim()) || "Emails stimmen nicht überein",
      ],
      svnrCheckRule: [
        (v) => (v && this.$moment(v, "YYYY-MM-DD").format("DDMMYY") == this.editedItemUser.user_id.substring(4,10)) || "Geburtstag stimmt nicht mit SVNr überein",
      ],
     
      new_password: "",
      dsgvo: false,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      emailRule,
      requiredRule,
      password: "",
      passwordRule,
      //email: "",
      valid: false,
      //emailValid: false,
      //emailRule,
      routeOnce: true,
      firebase_id: null,
      user_id: "",
      digitsRule10,
      users: [],
      selectedUser: {},
      show_login: true,

      dialog_user: false,
      editedIndexUser: -1,
      defaultItemUser: {
        crypto_address: "",
        nickname: "",
        firstname: "",
        lastname: "",
        birthday: "2000-01-01",
        street: "",
        street_number: "",
        door_number: "",
        postcode: null,
        town: "",
        phone: "",
        password: "",
        email: "",
        password: "",
        membership_end: null,
        member: false,
        roles: [],
        activated: true,
        sites: [],
        bc: {
          _Discount: 0,
          _RfidNr: "",
          _Active: false,
          _FairUse: false,
        },
        user_id: "",
        dsgvo: false,
        gender: "w"
      },
      editedItemUser: {},
      genderTypes: ["m","w","d"],
      changePasswordFlag: false,
      password_success: false
    };
  },
  created() {
    this.$store.state.showDrawer = false;
    this.editedItemUser = this.defaultItemUser;
  },
  async mounted() {
    // if (this.$route.query.email && this.$route.query.password) {
    //   this.email = this.$route.query.email;
    //   this.password = this.$route.query.password;
    //   this.authenticate({email: this.email, password: this.password})
    // }
    //console.log(this.$route.query)
    //console.log(this.$route.query.firebase_id)
    //console.log(this.$route.query.back)
    if (this.$route.query.firebase_id) {
      this.show_login = false;
      this.firebase_id = this.$route.query.firebase_id;
      localStorage.setItem("firebase_id", this.firebase_id);
      const users = JSON.parse(localStorage.getItem("users"));
      if (users) {
        this.users = users;
      }
      //console.log(this.users.length)
      if (this.users.length == 1 && !this.$route.query.back) {
       
          this.selectedUser.user_id = this.users[0].user_id;
          this.selectedUser.password = this.users[0].password;
          this.login();
       
      } else if (this.users.length > 1) {
        //await this.authenticate({user_id: this.users[0].user_id, password: this.users[0].password, firebase_id: this.firebase_id});
      } else {
        //this.show_login = true;
        //console.log("no credentials")
      }
    }

    if (this.$route.query.user_id && this.$route.query.password) {
      this.selectedUser.user_id = this.$route.query.user_id;
      this.selectedUser.password = this.$route.query.password;
      this.login();
    }

    //console.log(this.$refs)
  },
  methods: {
    ...mapActions("auth", ["authenticate", "getMySelf"]),
    ...mapActions("user", ["createUserApp", "updateUser"]),
    async login() {
      try {
        await this.authenticate({
          user_id: this.selectedUser.user_id,
          password: this.selectedUser.password,
          firebase_id: this.firebase_id,
        });
        this.$router.push("/home-read-only");
      } catch (err) {
        //
      }
    },
    async startLogin() {
      try {
        await this.authenticate({
          user_id: this.user_id,
          password: this.password,
          firebase_id: this.firebase_id,
        });
        await this.getMySelf();
        if (this.mySelf && this.firebase_id) {
          let user = _.find(this.users, { user_id: this.user_id });
          if (!user) {
            let user = {};
            user.firstname = this.mySelf.firstname;
            user.lastname = this.mySelf.lastname;
            user.user_id = this.user_id;
            user.password = this.password;
            this.users.push(user);

            localStorage.setItem("users", JSON.stringify(this.users));
          } else {
            //console.log("user_id already exists in LocalStorage, Save");
            user.password = this.password;
            localStorage.setItem("users", JSON.stringify(this.users));
          }
        }
        this.$router.push("/home-read-only");
      } catch (err) {
        //
      }
    },
    getUserName(item) {
      return item.firstname + " " + item.lastname;
    },
    createItemUser() {
      //console.log("create Item")
      this.buyed = false;
      this.user = {};
      this.owner = "";
      //console.log("create Item2 ", this.editedItemUser, this.defaultItemUser)
      this.editedItemUser = Object.assign({}, this.defaultItemUser);
      //this.editedItemUser = JSON.parse(JSON.stringify(this.defaultItemUser));
      //console.log("create Item3 ", this.editedItemUser)
      this.editedIndexUser = -1;
      this.dialog_user = true;
    },
    closeUser() {

      //console.log(this.$moment(this.editedItemUser.birthday, "YYYY-MM-DD").format("DDMMYY"), this.editedItemUser.user_id, this.editedItemUser.user_id.trim().substring(4,10))
      this.dialog_user = false;
      this.$refs.form.reset();
      this.dsgvo = false;
      this.$nextTick(() => {
        this.editedItemUser = Object.assign({}, this.defaultItemUser);
        this.editedIndexUser = -1;
      });
     
    },
    async saveUser() {
      
      //console.log("saveUser");

      if(this.$refs.form.validate()) {
        //if (this.editedIndexUser == -1) {
        try {
          // this.editedItemUser.sites = [
          //   { address: this.Sites[this.selectedSite].rentBC, active: true },
          // ];
          //console.log(this.editedItemUser);
          this.editedItemUser.email = this.editedItemUser.email.trim();
          this.editedItemUser.firebase_id = this.firebase_id;
          this.editedItemUser.dsgvo = this.dsgvo;
          // Robot Trap
          this.editedItemUser.floor = "";
          await this.createUserApp(this.editedItemUser);
          if (this.UserState.statusCreate == "success") {
            //this.editedItemUser._id = this.UserState.User._id;
            //this.editedItemUser.createdAt = this.UserState.User.createdAt;
            //this.user = this.UserState.User;
            //this.owner = this.user.crypto_address;
            const new_user = this.UserState.User;
            this.dialog_user = false;
            let user = _.find(this.users, { user_id: this.user_id });
            if (!user) {
              let user = {};
              user.firstname = new_user.firstname;
              user.lastname = new_user.lastname;
              user.user_id = new_user.user_id;
              user.password = new_user.password;
              this.users.push(user);

              localStorage.setItem("users", JSON.stringify(this.users));
            } else {
              //console.log("user_id already exists in LocalStorage");
              user.password = new_user.password;
              localStorage.setItem("users", JSON.stringify(this.users));
            }
            this.selectedUser.user_id = new_user.user_id;
            this.selectedUser.password = new_user.password;
            this.login();
          } else {
            this.snackbar_text = this.UserState.User.message;
            this.snackbar = true;
          }
        } catch (err) {
          console.log("Error CreateUserAuth ", err);
        }
      }
        //}
    },
    validate() {
      this.$refs.form.validate();
    },
    async changePassword() {
      await this.authenticate({
          user_id: this.user_id,
          password: this.password,
          firebase_id: this.firebase_id,
        });
      await this.getMySelf();
      if (this.mySelf) {
        let obj = this.mySelf;
        obj.password = this.new_password;
        await this.updateUser(obj);
        this.password = this.new_password;
        this.password_success = true;
        this.changePasswordFlag = false;
      }
      
    }
  },
  computed: {
    ...mapState({
      status: ({ auth }) => auth.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      UserState: ({ user }) => user,
      statusUpdate: ({ user }) => user.statusUpdate,
    }),
    formTitleUser() {
      return this.editedIndexUser === -1 ? "Neuer User" : "Editiere User";
    },
  },
  watch: {
    //  mySelf(val) {
    //     if (this.routeOnce) {
    //       this.routeOnce = false;
    //       this.$router.push('/home-read-only').then(res => {
    //         //console.log(res)
    //         });
    //     }
    //   }
  },
};
</script>

<style>
.box {
  border: solid 1px red;
}
</style>