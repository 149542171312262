const REQUEST_INVOICES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_INVOICES = (state, { body }) => {
  state.Invoices = body;
  state.status = "success";
};

const FAILURE_REQUEST_INVOICES = state => {
  state.status = "failure";
};
/*
const REQUEST_INVOICE_PROVIDER = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_INVOICE_PROVIDER = (state, { body }) => {
  state.Invoice = body;
  state.status = "success";
};

const FAILURE_REQUEST_INVOICE_PROVIDER = state => {
  state.status = "failure";
};

const REQUEST_DELETE_INVOICE = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_INVOICE = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_INVOICE = state => {
  state.statusDelete = "failure";
};*/
/*
const REQUEST_UPDATE_INVOICE = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_INVOICE = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_INVOICE = state => {
  state.statusUpdate = "failure";
};

const REQUEST_INVOICE_HISTORY = state => {
  state.statusHistory = "fetching";
};

const SUCCESS_REQUEST_INVOICE_HISTORY = (state, { body }) => {
  //console.log("SUCCESS_HISTORY")
  //console.log(body.history)
  state.History = body.history;
  state.statusHistory = "success";
};

const FAILURE_REQUEST_INVOICE_HISTORY = state => {
  state.statusHistory = "failure";
};
*/
/*
const REQUEST_CREATE_INVOICE_PROVIDER = state => {
  state.statusCreateProvider = "fetching";
};

const SUCCESS_REQUEST_CREATE_INVOICE_PROVIDER = state => {
  state.statusCreateProvider = "success";
};

const FAILURE_REQUEST_CREATE_INVOICE_PROVIDER = state => {
  state.statusCreateProvider = "failure";
};*/

const REQUEST_SEND_INVOICE = state => {
  state.statusSend = "fetching";
};

const SUCCESS_REQUEST_SEND_INVOICE = state => {
  state.statusSend = "success";
};

const FAILURE_REQUEST_SEND_INVOICE = state => {
  state.statusSend = "failure";
};
/*
const REQUEST_SEND_INVOICE_PROVIDER_ALL = state => {
  state.statusSendProviderAll = "fetching";
};

const SUCCESS_REQUEST_SEND_INVOICE_PROVIDER_ALL = state => {
  state.statusSendProviderAll = "success";
};

const FAILURE_REQUEST_SEND_INVOICE_PROVIDER_ALL = state => {
  state.statusSendProviderAll = "failure";
};*/

const REQUEST_CREATE_VOUCHER_INVOICE = state => {
  state.statusCreateVoucher = "fetching";
};

const SUCCESS_REQUEST_CREATE_VOUCHER_INVOICE = (state, { body }) => {
  state.statusCreateVoucher = "success";
  state.User = body;
};

const FAILURE_REQUEST_CREATE_VOUCHER_INVOICE = state => {
  state.statusCreateVoucher = "failure";
};

export default {
  REQUEST_INVOICES,
  SUCCESS_REQUEST_INVOICES,
  FAILURE_REQUEST_INVOICES,
  /*REQUEST_INVOICE_PROVIDER,
  SUCCESS_REQUEST_INVOICE_PROVIDER,
  FAILURE_REQUEST_INVOICE_PROVIDER,
  REQUEST_CREATE_INVOICE_PROVIDER,
  SUCCESS_REQUEST_CREATE_INVOICE_PROVIDER,
  FAILURE_REQUEST_CREATE_INVOICE_PROVIDER,*/
  /*
  REQUEST_INVOICE_HISTORY,
  SUCCESS_REQUEST_INVOICE_HISTORY,
  FAILURE_REQUEST_INVOICE_HISTORY,
  */
 /*
  REQUEST_DELETE_INVOICE,
  SUCCESS_REQUEST_DELETE_INVOICE,
  FAILURE_REQUEST_DELETE_INVOICE,
  REQUEST_SEND_INVOICE_PROVIDER_ALL,
  SUCCESS_REQUEST_SEND_INVOICE_PROVIDER_ALL,
  FAILURE_REQUEST_SEND_INVOICE_PROVIDER_ALL,*/
  REQUEST_SEND_INVOICE,
  SUCCESS_REQUEST_SEND_INVOICE,
  FAILURE_REQUEST_SEND_INVOICE,

  REQUEST_CREATE_VOUCHER_INVOICE,
  SUCCESS_REQUEST_CREATE_VOUCHER_INVOICE,
  FAILURE_REQUEST_CREATE_VOUCHER_INVOICE,
};
