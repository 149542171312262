import api from "../../../api";

export default {
  async getInvoices({ commit }, {rentBC}) {
    commit("REQUEST_INVOICES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_INVOICES",
        await api.getInvoices({rentBC})
      );
    } catch (err) {
      commit("FAILURE_REQUEST_INVOICES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },/*
  async getInvoiceProvider({ commit }, id) {
    commit("REQUEST_INVOICE_PROVIDER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_INVOICE_PROVIDER",
        await api.getInvoiceProvider(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_INVOICE_PROVIDER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },*/
  /*
  async getInvoiceHistory({ commit }, id) {
    commit("REQUEST_INVOICE_HISTORY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_INVOICE_HISTORY",
        await api.getInvoiceHistory(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_INVOICE_HISTORY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },*/
  /*
  async deleteInvoice({ commit }, item) {
    commit("REQUEST_DELETE_INVOICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_INVOICE",
        await api.deleteInvoice(item.id, item.param)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_INVOICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },*/
  /*
  async updateInvoice({ commit }, item ) {
    commit("REQUEST_UPDATE_INVOICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_INVOICE",
        await api.updateInvoice(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_INVOICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  */
  /*async createInvoiceProvider({ commit }, id ) {
    commit("REQUEST_CREATE_INVOICE_PROVIDER");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_INVOICE_PROVIDER",
        await api.createInvoiceProvider( id )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_INVOICE_PROVIDER");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },*/
  /*
  async deactivateInvoice({ commit }, id) {
    commit("REQUEST_DEACTIVATE_INVOICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_INVOICE",
        await api.deactivateInvoice(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DEACTIVATE_INVOICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }*/
/*
  async sendInvoiceProviderAll({ commit }, id ) {
    commit("REQUEST_SEND_INVOICE_PROVIDER_ALL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SEND_INVOICE_PROVIDER_ALL",
        await api.sendInvoiceProviderAll( id )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SEND_INVOICE_PROVIDER_ALL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },*/
  async sendInvoice({ commit }, id ) {
    commit("REQUEST_SEND_INVOICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SEND_INVOICE",
        await api.sendInvoice( id )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SEND_INVOICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createVoucherInvoice({ commit }, {from, to, amount, site}) {
    commit("REQUEST_CREATE_VOUCHER_INVOICE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_VOUCHER_INVOICE",
        await api.createVoucherInvoice(from, to, amount, site)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_VOUCHER_INVOICE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
