const REQUEST_MACHINES = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_MACHINES = (state, { body }) => {
  try {
    const res = JSON.parse(body);
    state.statusMachine = res.status;
    state.status = "success";
  }
  catch (err) {
    state.statusMachine = undefined;
    state.statusUpdate = "failure";
  }
  
};

const FAILURE_REQUEST_MACHINES = state => {
  state.status = "failure";
};


const REQUEST_UPDATE_MACHINE = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_MACHINE = (state, { body }) => {
  try {
    const res = JSON.parse(body);
    state.statusMachine = res.status;
    state.statusUpdate = "success";
  }
  catch (err) {
    state.statusMachine = undefined;
    state.statusUpdate = "failure";
  }
  
};

const FAILURE_REQUEST_UPDATE_MACHINE = state => {
  state.statusUpdate = "failure";
};


export default {
  REQUEST_MACHINES,
  SUCCESS_REQUEST_MACHINES,
  FAILURE_REQUEST_MACHINES,
  REQUEST_UPDATE_MACHINE,
  SUCCESS_REQUEST_UPDATE_MACHINE,
  FAILURE_REQUEST_UPDATE_MACHINE,
};
