<template>
  <div >   
      <v-row justify="center">
        <v-col cols="8">
          <v-card  >
            <v-card-title>About Us</v-card-title>
            <v-card-text>
              <br/>
              Thomas Schwarzl IT Consulting <br/>
              Puchstrasse 17 <br/>
              8020 Graz <br/>
              Austria<br/>
              <br/>
              schwarzl-it@makerspace.at<br/>
              <br/>
              <b>What we are doing: </b><br/>
              <br/>
              -  Software<br>
              -  Electronics<br>
              -  Blockchain<br>
              -  3D Printing
              <br/>
              <br/>
              <b>Our running projects</b><br/>
              <br/>
              <a href="https://positive-energy-buildings.eu/" target="blank">H2020 Projekt EXCESS</a>
              <br/>
              <a href="https://leas-e.makerspace.at" target="blank">Leas-e - The Crypto Renting System</a>
              <br/>
              <a href="https://antigen-selftest.online" target="blank">Teasy - The Crypto Test Certificate System</a>
              <br/>
              <br/>
              <b>Social Media</b><br/>
              <br/>            
              <a href="https://www.linkedin.com/in/thomas-schwarzl-2965a9140/" target="blank">linkedIn</a>
              <br/>            
              <a href="4Ab24DEA13A6baffffcBd61c27e8c4b80972fd8D" target="blank">Teasy Siegersdorf</a>
            </v-card-text>
           </v-card>
        </v-col>
      </v-row>
  </div>
</template>

<script>

import { mapState, mapActions } from "vuex";

export default {
  name: "About",
  data() {
    return {
     text: ""
    };
  },
  mounted() {
   
  },
  methods: {

  },
  computed: {
   
  },
  watch: {

  }
};
</script>
