<template>
  <div id="app">
    <link
      href="https://use.fontawesome.com/releases/v5.0.6/css/all.css"
      rel="stylesheet"
    />
    <link
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      rel="stylesheet"
    />
    <v-app>
      <v-dialog v-model="deleteWalletDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline"> Delete Wallet </v-card-title>
          <v-card-text
            >Are you really sure that you want to delete your wallet in this
            browser? Maybe you should save the seed phrase before.</v-card-text
          >
          <v-card-actions>
            <v-btn
              class="mx-3"
              color="error"
              rounded
              outlined
              small
              @click="deleteWalletDialog = false"
            >
              No
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-3"
              color="success"
              rounded
              outlined
              small
              @click="deleteWallet()"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        v-model="drawer"
        right
        app
        :style="`margin-top: ${$vuetify.application.top}px`"
        clipped
        temporary
        width="300"
      >
        <!-- 
        absolute
         
        overlay-color="red"
        overlay-opacity="50%"
        
        -->
        <!--&& mySelf.roles.includes('admin')"-->
        <v-list class="pt-3">
          <v-list-item class="pt-2" v-if="Sites">
            <v-select
              dense
              solo
              background-color="secondary"
              v-model="selectedLocation"
              :items="Sites"
              item-text="name"
              color="white"
              v-on:change="changeLocation"
            >
            </v-select>
          </v-list-item>

          <!-- <v-list-item class="pt-2" v-if="mySelf">
           <v-list-item-content>
              <v-list-item-title class="text-capitalize h6"
                >Willkommen {{ mySelf.nickname }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>-->
          <v-list-item class="pt-2" v-if="Wallet && mySelf">
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                $t("Crypto Wallet")
              }}</v-list-item-title>

              <p class="text-capitalize caption percent80">
                <br />{{ WalletAddress }}
              </p>
              <p v-if="isAdmin" class="text-capitalize caption">
                EtherBalance: {{ walletBalance }}
              </p>
              <!--<p v-else class="text-capitalize caption">
                EtherBalance: {{ walletBalance }}
              </p>-->
              <p class="text-capitalize caption">
                Guthaben: {{ tokenBalance }} {{ TokenSymbol }}
              </p>
              <br />
              <br />
              <v-btn
                small
                width="100%"
                color="orange"
                v-on:click="dialog_paypal = true"
                >PayPal - Token kaufen</v-btn
              >
              <br />
              <br />
              <v-btn x-small depressed outlined @click="showQr = !showQr">
                <div v-if="!showQr">Zeige QR Code deiner Accountadresse</div>
                <div v-else>Schliesse QR Code der Accountadresse</div>
              </v-btn>
              <div v-if="showQr">
                <vue-qr :text="WalletAddress" :correctLevel="1"></vue-qr>
              </div>

              <br />
              <v-btn
                x-small
                depressed
                outlined
                @click="
                  showSeed = !showSeed;
                  checkPassword = false;
                  password = '';
                  passwordOk = true;
                "
              >
                <div v-if="!showSeed">Zeige QR Code deiner Seed Phrase</div>
                <div v-else>Schliesse SeedPhrase QR Code</div>
              </v-btn>
              <div v-if="showSeed">
                <v-text-field
                  v-if="!checkPassword"
                  type="password"
                  v-model="password"
                  label="Wallet Password für SeedPhrase"
                  @keydown.enter="checkWalletPassword"
                ></v-text-field>
                <div class="caption red--text" v-if="!passwordOk">
                  Passwort falsch
                </div>
                <v-btn
                  v-if="!checkPassword"
                  :loading="loadWallet"
                  small
                  depressed
                  outlined
                  @click="checkWalletPassword"
                  >Show Seed</v-btn
                >

                <p v-if="Wallet.mnemonic && checkPassword" class="caption">
                  <br /><b>Seed Phrase:</b> <br /><br />{{
                    Wallet.mnemonic.phrase
                  }}
                  <vue-qr
                    :text="Wallet.mnemonic.phrase"
                    :correctLevel="1"
                  ></vue-qr>
                  <br />
                  <v-btn
                    x-small
                    depressed
                    outlined
                    color="error"
                    @click="deleteWalletDialog = true"
                    >Delete Wallet
                  </v-btn>
                </p>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--<v-list-item class="pt-2" v-on:click="$router.push('home')">
            <v-list-item-action>
              <v-icon medium color="blue">mdi-calendar</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                $t("Home")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
          <v-list-item
            v-for="item in items"
            :key="item.title"
            v-on:click="
              drawer = false;
              pushRoute(item);
            "
            class="pt-2"
          >
            <v-list-item-action>
              <v-icon medium :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pt-2" v-if="mySelf" v-on:click="logout()">
            <v-list-item-action>
              <v-icon medium>mdi-logout</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                $t("Logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="pt-2"
            v-if="!Wallet"
            v-on:click="$router.push('/cryptoLogin')"
          >
            <v-list-item-action>
              <v-icon medium color="red">mdi-login</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{
                $t("Login")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item class="pt-2">
            <v-list-item-content>
              <v-list-item-title class="caption text-capitalize"
                >Leas-e - The Crypto System<br />Version {{ leaseVersion
                }}<br />2022 Schwarzl IT</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-card flat>
        <v-app-bar clipped-left fixed app color="primary">
          <!--<v-icon color="black" v-on:click="drawer = !drawer">menu</v-icon> -->
          <v-col cols="auto" align="left" class="ma-0 pa-0">
            <!-- <v-img class="white--text align-start" width="36px" src="/img/icons/logo_lease_silber_64.png"></v-img> -->
            <!-- <span style="background-image"></span> -->
            <!-- <img src="/img/icons/logo_lease_silber_64.png" width="40px" /> -->
            <img src="img/icons/logo_lease_64.png" width="40px" />
            <!-- <img
              src="/img/icons/TEASY_logo64.png"
              width="40px"
            /> -->
          </v-col>
          <v-col cols="9" align="left">
            <span class="mt-2 yellow--text caption text-sm-h6">LEAS-E </span>
            <span class="mt-2 white--text caption text-sm-h6"
              >The Crypto System</span
            >
            <!-- <span class="mt-2 yellow--text caption text-sm-h6">TEASY </span>
            <span class="mt-2 white--text caption text-sm-h6"
              >makes testing easy</span
            > -->
            <span
              v-if="sitesLoaded"
              class="mt-2 yellow--text caption text-sm-h6"
            >
              - {{ Sites[selectedSite].name }}</span
            >
          </v-col>

          <!--<v-col cols="8">
            
            <v-toolbar-title class="white--text h6 text-center"
              >Leas-e BlockChain Renting System<br />
              
              </v-toolbar-title
            >
                
            
          </v-col>-->
          <v-col align="right" v-if="!mySelf" class="pa-0 ma-0">
            <!--<v-btn small icon :to="{ name: 'login' }">
              <v-icon color="white">mdi-key-variant</v-icon>
            </v-btn>-->
            <!--<v-btn class="ml-3" icon :to="{ name: 'signUp' }">
              <v-icon color="white">mdi-account-plus</v-icon>
            </v-btn>-->
            <v-btn class="ml-3" large icon v-on:click="drawer = !drawer">
              <v-icon color="white">mdi-menu</v-icon>
            </v-btn>
          </v-col>
          <!--<v-col cols="1" align="left" v-if="!mySelf">
            <v-btn small icon :to="{ name: 'signUp' }">
              <v-icon color="white">mdi-account-plus</v-icon>
            </v-btn>
          </v-col>-->
          <v-col align="right" v-else class="pa-0 ma-0">
            <!--<v-btn icon v-on:click="logout()" class="text-white">
              {{ mySelf.firstname.substring(0, 1) }}.{{
                mySelf.lastname.substring(0, 1)
              }}.
              <v-icon color="white">mdi-logout</v-icon>
            </v-btn>-->

            <v-btn
              large
              icon
              class="ml-3"
              v-on:click="drawer = !drawer"
              v-if="$store.state.showDrawer"
            >
              <v-icon color="white">mdi-menu</v-icon>
            </v-btn>
          </v-col>
        </v-app-bar>

        <v-main>
          <router-view></router-view>
        </v-main>
      </v-card>
      <!--
      <v-card v-if="!mySelf" color="black">
        <v-row row pb-2 pt-3>
          <v-col xs12 pl-1 ml-1 pr-1 mr-1>
            <v-card class="card--flex-toolbar">
              <v-app-bar flat color="primary">
                <v-spacer></v-spacer>

                <v-toolbar-title class="white--text text-capitalize h3">ATUS Kalsdorf Tennis Web</v-toolbar-title>
                <v-spacer></v-spacer>
                <p class="caption" align="right">Schwarzl IT 2020</p>
              </v-app-bar>
              <v-progress-linear
                color="blue"
                :active="loading"
                :height="5"
                class="ma-0"
                indeterminate
              ></v-progress-linear>
              <v-img class="white--text align-start" width="100%" src="/tennis-1.jpg">
                <router-view></router-view>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      -->
      <v-dialog v-model="dialog_paypal" max-width="500px">
        <PayPalDialog :dialog="dialog_paypal"></PayPalDialog>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";

import { test, getWeb3 } from "./utils/web3";
import { ethers } from "ethers";
import Machine from "./views/Machine.vue";
import PayPalDialog from "./views/PayPalDialog.vue";

export default {
  components: { Machine, PayPalDialog },
  name: "App",
  data() {
    return {
      drawer: false,
      items: [],
      //mini: true,
      right: null,
      showQr: false,
      deleteWalletDialog: false,
      isAdmin: 0,
      selectedLocation: "",
      leaseVersion: "2.3.0",
      checkPassword: false,
      showSeed: false,
      password: "",
      loadWallet: false,
      passwordOk: true,
      dialog_paypal: false,
      isCordova: process.env.CORDOVA_PLATFORM,
    };
  },
  async mounted() {
    console.log("Lease App Mounted ", this.isCordova);

    if (this.isCordova) {
      document.addEventListener("deviceready", this.onDeviceReady, false);
    }
    // document.addEventListener(
    //   "deviceready",
    //   () => {
    //     console.log("Device ready2");
    //     universalLinks.subscribe("leaseAppStartEvent", this.universalLinkEvent);
    //   },
    //   false
    // );
    //this.theme1 = JSON.stringify(this.$vuetify.theme.themes);
    await this.registerWeb3();

    //console.log("App Mounted");
  },
  created() {},
  computed: {
    ...mapState({
      status: ({ user }) => user.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Wallet: ({ web3 }) => web3.Wallet,
      WalletBalance: ({ web3 }) => web3.EtherBalance,
      WalletAddress: ({ web3 }) => web3.Address,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      authStatus: ({ auth }) => auth.status,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Web3: ({ web3 }) => web3,
      TokenName: ({ web3 }) => web3.TokenName,
      TokenSymbol: ({ web3 }) => web3.TokenSymbol,
      showPayPal: (state) => state.showPayPal,
    }),
    walletBalance() {
      try {
        return ethers.utils.formatEther(this.WalletBalance);
      } catch (err) {
        return 0;
      }
    },
    tokenBalance() {
      try {
        return ethers.utils.formatEther(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    sitesLoaded() {
      try {
        if (!_.isUndefined(this.Sites) && !_.isUndefined(this.selectedSite)) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
  },
  watch: {
    mySelf() {
      this.items = [];
      try {
        //if (this.mySelf.roles.includes("user")) {
        //}

        //if (this.mySelf.roles.includes("admin") || this.mySelf.roles.includes("provider")) {
        this.$router.options.routes.forEach((route) => {
          if (!_.isUndefined(route.icon)) {
            this.items.push({
              title: route.name,
              icon: route.icon,
              color: route.color,
              path: route.path,
            });
          }
        });
        if (this.mySelf.roles.includes("admin")) {
          this.isAdmin = 1;
        }
        //}
      } catch {}
    },
    Sites(value) {
      //console.log("GET SITES APP: ",value);
      this.selectedLocation = value[0];
    },

    /*async selectedSite(idx) {
      this.$store.state.web3.TokenContractAddress = this.Sites[idx].tokenBC;
      const tokenContract = new ethers.Contract(
        this.Sites[idx].tokenBC,
        this.Web3.TokenContractAbi,
        this.Wallet
      );
      this.$store.state.web3.TokenContract = tokenContract;
      try {
        const tokenBalance = await tokenContract.balanceOf(this.WalletAddress);
        this.$store.state.web3.TokenBalance = tokenBalance;
      } catch (err) {
        this.$store.state.web3.TokenBalance = 0;
      }
    },*/
    /*TokenBalance() {
      console.log("Token Changed")
    }*/
    showPayPal(now) {
      if (!now) {
        this.dialog_paypal = now;
      }
    },
    dialog_paypal(now) {
      console.log("CHANGE STATE PAYPAL ", now);
      this.$store.state.showPayPal = now;
    },
  },
  methods: {
    ...mapActions("auth", ["getMySelf", "logout", "cryptoAuth"]),
    ...mapActions("web3", ["registerWeb3"]),

    onDeviceReady() {
      console.log("Device ready");
      cordova.plugins.firebase.messaging.requestPermission().then(function () {
        console.log("Push messaging is allowed");
      });
      cordova.plugins.firebase.messaging.getToken().then(function (token) {
        console.log("Got device token: ", token);
      });
      cordova.plugins.firebase.messaging.onMessage(function (payload) {
        console.log("New foreground FCM message: ", payload);
      });
      cordova.plugins.firebase.messaging.onBackgroundMessage(function (
        payload
      ) {
        console.log("New background FCM message: ", payload);
      });

      console.log("Subcribe to leaseAppStartEvent");
      universalLinks.subscribe("leaseAppStartEvent", this.universalLinkEvent);

      let permissions = cordova.plugins.permissions;
      permissions.checkPermission(permissions.CAMERA, function (status) {
        if (status.hasPermission) {
          //console.log("Yes :D ");
        } else {
          //console.warn("No :( ");
          permissions.requestPermission(
            permissions.CAMERA,
            function (status) {},
            function (error) {}
          );
        }
      });

      //alert("Device Ready");
    },
    universalLinkEvent(eventData) {
      try {
        console.log(
          "Event leaseAppStartEvent fired: " + JSON.stringify(eventData)
        );
        let _path = [];
        let _start = 1;
        if (eventData.hash) {
          _path = eventData.hash.split("/");
          //_start = 1;
        } else if (eventData.path) {
          _path = eventData.path.split("/");
        }

        console.log(_path.length);
        console.log(_start);
        console.log(JSON.stringify(_path));

        if (_path.length >= 4 && _path.length <= 5) {
          if (_path[_start] == "site") {
            try {
              //console.log(_path[_start + 1]);
              this.$store.state.auth.site = ethers.utils.getAddress(
                _path[_start + 1]
              );
            } catch (err) {
              console.log("Site could not be resolved ", err);
            }
            if (_path[_start + 2] == "machine") {
              try {
                //console.log(parseInt(_path[[_start + 3]]));
                let _machine = parseInt(_path[_start + 3]);

                this.$store.state.auth.machine = _machine;
              } catch (err) {
                console.log("Machine could not be resolved ", err);
              }
            } else if (_path[_start + 2] == "door") {
              try {
                // console.log(parseInt(_path[_start + 3]));
                let _door = parseInt(_path[_start + 3]);

                this.$store.state.auth.door = _door;
              } catch (err) {
                console.log("Door could not be resolved ", err);
              }
            } else if (_path[_start + 2] == "name") {
              try {
                // console.log(_path[_start + 3]);

                this.$store.state.auth.site_name = _path[_start + 3];
              } catch (err) {
                console.log("Site Name could not be resolved ", err);
              }
            }
          }
        } else if (_path.length >= 2 && _path.length <= 3) {
          if (_path[_start] == "activate-account") {
            console.log(
              "Link accountActivation detected, Token: ",
              _path[_start + 1]
            );
            try {
              this.$router.push({
                name: "accountActivation",
                params: { token: _path[_start + 1] },
              });
            } catch (err) {
              console.log("activate-account failed ", err);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    },

    async checkWalletPassword() {
      this.passwordOk = true;
      this.loadWallet = true;
      //let myWallet = void 0;

      if (localStorage.wallet) {
        try {
          //let myWallet =
          await ethers.Wallet.fromEncryptedJson(
            localStorage.wallet,
            this.password
          );

          this.checkPassword = true;
          this.loadWallet = false;
        } catch (err) {
          //console.log(err);
          this.passwordOk = false;
          this.loadWallet = false;
        }
      } else {
        //error

        this.loadWallet = false;
      }
    },

    pushRoute(item) {
      let item_path = item.path;
      //item_path = item_path.slice(1, item.path.length);
      //console.log(item_path);
      //this.mini = true
      //this.drawer = false
      this.$router
        .push(item_path)
        .catch(() => {})
        .finally(() => {
          if (item_path != "/home") {
            try {
              if (!_.isUndefined(this.$store.state.myInterval)) {
                // console.log(
                //   "Clear Interval ",
                //   item_path,
                //   " Nr:",
                //   this.$store.state.myInterval
                // );
                clearInterval(this.$store.state.myInterval);
                this.$store.state.myInterval = undefined;
                //console.log("Clear Interval")
              }
            } catch (e) {
              //
            }
          }
        });
    },
    actualRoute(path) {
      let getRoute = _.find(this.items, { path });
      if (_.isUndefined(getRoute)) {
        getRoute = {
          color: "",
          icon: "",
          name: "",
        };
      }
      return getRoute;
    },
    deleteWallet() {
      localStorage.removeItem("wallet");
      this.logout();
    },
    /*checkUser (role) {
      return this.mySelf.roles.includes(role);
    }*/

    async changeLocation(value) {
      const idx = _.findIndex(this.Sites, { name: this.selectedLocation });
      console.log("Change Location ", value, idx);
      let _tokenContract = void 0;
      try {
        this.$store.state.web3.TokenContractAddress = this.Sites[idx].tokenBC;
        _tokenContract = await new ethers.Contract(
          this.Sites[idx].tokenBC,
          this.$store.state.web3.TokenContractAbi,
          this.Wallet
        );
        this.$store.state.web3.TokenContract = _tokenContract;
        this.$store.state.web3.TokenName = await _tokenContract.name();
        this.$store.state.web3.TokenSymbol = await _tokenContract.symbol();

        this.$store.state.web3.RentContractAddress = this.Sites[idx].rentBC;
        const _rentContract = await new ethers.Contract(
          this.Sites[idx].rentBC,
          this.$store.state.web3.RentContractAbi,
          this.Wallet
        );
        this.$store.state.web3.RentContract = _rentContract;

        this.$store.state.web3.InvoiceContractAddress =
          this.Sites[idx].invoiceBC;
        const _invoiceContract = await new ethers.Contract(
          this.Sites[idx].invoiceBC,
          this.$store.state.web3.InvoiceContractAbi,
          this.Wallet
        );
        this.$store.state.web3.InvoiceContract = _invoiceContract;
      } catch (err) {
        console.log(err);
      }

      try {
        const _tokenBalance = await _tokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        console.log(err);
        this.$store.state.web3.TokenBalance = 0;
      }

      this.$store.state.user.selectedSite = idx;
    },
  },
};
</script>

<style lang='scss'>
/*@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
*/

.percent80 {
  transform: scale(0.85);
  transform-origin: left;
}
.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}
.siteSelection {
  width: 100%;
  position: fixed;
  z-index: 2;
}

.body {
  overscroll-behavior-y: contain !important;
  // overflow-y: hidden !important; // override Vuetify's default style
}

.html {
  overscroll-behavior-y: contain !important;
  // overflow-y: hidden !important; // override Vuetify's default style
}
body {
  overscroll-behavior-y: contain !important;
  // overflow-y: hidden !important; // override Vuetify's default style
}
html {
  overscroll-behavior-y: contain !important;
  // overflow-y: hidden !important; // override Vuetify's default style
}

#app {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  background-color: #ffffff;
}

.imageposition {
  position: relative;
  left: -15px;
  top: -3px;
}
.max {
  max-width: 10px;
}
</style>

