import {
  AuthResource,
  AuthCryptoResource,
  SignUpResource,
  ActivateAccountResource,
  MySelfResource,
  ForgotPasswordResource,
  ResetPasswordResource,
  // CreatePersonResource,
  // PersonResource,
  // PersonDeleteResource,
  // PersonUpdateResource,
  // PersonDeactivateResource,
  // PersonHistoryResource,

  /*
  EventCreateResource,
  EventResource,
  EventDeleteResource,
  EventUpdateResource,
*/
  UserResource,
  UserCreateResource,
  UserCreateAuthResource,
  UserCreateAppResource,
  UserReadResource,
  UserUpdateResource,
  UserDeleteResource,

  ContractResource,
  ContractCreateResource,
  ContractUpdateResource,
  ContractDeleteResource,


  LockResource,
  LockCreateResource,
  LockUpdateResource,
  LockDeleteResource,
  LockOpenResource,

  MachineGetStatusResource,
  MachineStartStopResource,

  InvoiceResource,
  /*InvoiceDeleteResource,
  InvoiceProviderResource,
  InvoiceCreateProviderResource,*/
  InvoiceSendResource,
  InvoiceCreateVoucherResource,
  /*InvoiceSendProviderAllResource,*/

  CertificateResource,
  CertificateSendResource,
  CertificateDeleteResource,

  
  MachineContractResource,
  MachineContractUpdateResource,
  MachineContractCheckResource,

  PaypalResource,
  PaypalCreateResource,
  PaypalUpdateResource,
  PaypalDeleteResource,
  
} from "./resources";

export default {
  authenticate(obj) {
    return AuthResource.save({}, obj);
  },
  cryptoAuth({ digest, signature }) {
    return AuthCryptoResource.save({}, { digest, signature});
  },
  mySelf() {
    return MySelfResource.get();
  },
  /*signUp({ email, password, displayname, fullname }) {
    return SignUpResource.save({}, { email, password, displayname, fullname });
  },*/
  signUp(user) {
    return SignUpResource.save({}, user);
  },
  activateAccount({ token }) {
    return ActivateAccountResource.get({ token }, {});
  },
  forgotPassword({ email }) {
    return ForgotPasswordResource.save({ email });
  },
  resetPassword({ token }) {
    return ResetPasswordResource.save({ token }, {});
  },
  // createPerson(person) {
  //   return CreatePersonResource.save({},person);
  // },
  // getPersons() {
  //   return PersonResource.get();
  // },
  // getPersonHistory(id) {
  //   return PersonHistoryResource.get({ id }, {});
  // },
  // deletePerson(id) {
  //   return PersonDeleteResource.delete({ id }, {});
  // },
  // deactivatePerson(id) {
  //   return PersonDeactivateResource.update({ id }, {});
  // },
  // updatePerson(person) {
  //   return PersonUpdateResource.update({ id: person._id }, person);
  // }, 
  getUsers(query) {
    return UserResource.get(query, {});
  },
  createUser(user) {
    return UserCreateResource.save({},user);
  },
  createUserAuth(user) {
    //console.log(user)
    return UserCreateAuthResource.save({},user);
  },
  createUserApp(user) {
    //console.log(user)
    return UserCreateAppResource.save({},user);
  },
  readUser({id}){
    return UserReadResource.get({id}, {});
  },
  deleteUser(id) {
    return UserDeleteResource.delete({ id }, {});
  },
  updateUser(user) {
    return UserUpdateResource.update({ id: user._id }, user);
  }, 
  

  getContracts() {
    return ContractResource.get();
  },
  deleteContract(id) {
    return ContractDeleteResource.delete({ id }, {});
  },
  updateContract(contract) {
    return ContractUpdateResource.update({ id: contract._id }, contract);
  }, 
  createContract(contract) {
    return ContractCreateResource.save({},contract);
  },

  getLocks() {
    return LockResource.get();
  },
  deleteLock(id) {
    return LockDeleteResource.delete({ id }, {});
  },
  updateLock(lock) {
    return LockUpdateResource.update({ id: lock._id }, lock);
  }, 
  createLock(lock) {
    return LockCreateResource.save({}, lock);
  },
  openLock(obj) {
    return LockOpenResource.save({}, obj);
  },


  getStatusMachine(obj) {
    return MachineGetStatusResource.save({}, obj);
  },
  startStopMachine(obj) {
    return MachineStartStopResource.save({}, obj);
  },

  /*
  getEvent() {
    return EventResource.get();
  },
  deleteEvent(id) {
    return EventDeleteResource.delete({ id }, {});
  },
  updateEvent(event) {
    return EventUpdateResource.update({ id: event._id }, event);
  }, 
  createEvent(event) {
    return EventCreateResource.save({},event);
  },
*/
  getInvoices({rentBC}) {
    //console.log({rentBC});
    return InvoiceResource.get({rentBC}, {});
  },
  /*createInvoiceProvider(id) {
    return InvoiceCreateProviderResource.save({id}, {});
  },
  deleteInvoice(id, param) {
    return InvoiceDeleteResource.delete({id}, {param});
  },
  getInvoiceProvider(id) {
    return InvoiceProviderResource.get(id, {});
  },
  sendInvoiceProviderAll(id) {
    return InvoiceSendProviderAllResource.save({id}, {});
  },*/
  sendInvoice(id) {
    return InvoiceSendResource.save({id}, {});
  },
  createVoucherInvoice(from, to, amount, site) {
    return InvoiceCreateVoucherResource.update({ }, {from: from, to: to, amount: amount, site: site});
  }, 

  getMachineContracts(query) {
    return MachineContractResource.get(query, {});
  },
  updateMachineContract(machinecontract) {
    return MachineContractUpdateResource.update({ id: machinecontract._id }, machinecontract);
  }, 
  checkMachineContract(query) {
    return MachineContractCheckResource.get(query, {});
  }, 

  getPaypal(query) {
    return PaypalResource.get(query, {});
  },
  deletePaypal(id) {
    return PaypalDeleteResource.delete({ id }, {});
  },
  updatePaypal(paypal) {
    return PaypalUpdateResource.update({ id: paypal._id }, paypal);
  }, 
  createPaypal(paypal) {
    return PaypalCreateResource.save({}, paypal);
  },

  getCertificates({rentBC}) {
    //console.log({rentBC});
    return CertificateResource.get({rentBC}, {});
  },
  sendCertificate(id) {
    return CertificateSendResource.save({id}, {});
  },

  delCertificates(query) {
    //console.log(query)
    return CertificateDeleteResource.delete( query, {});
  }
};
