<template>
  <div>
    <v-card flat class="calendarSection">
      <v-row no-gutters>
        <v-overlay :value="loading_data" absolute :opacity="0.05">
          <v-progress-circular
            color="blue"
            indeterminate
            size="200"
          ></v-progress-circular>
        </v-overlay>
        <v-col cols="12" class="pr-4">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                small
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Heute
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>

          <!--<v-sheet height="520" >-->
          <v-calendar
            :categories="categories"
            type="category"
            :categoryShowAll="true"
            categoryDays="7"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="calendarEvents"
            :event-color="getEventColor"
            :event-ripple="false"
            class="greybox"
            locale-first-day-of-year="4"
            locale="de"
            :interval-height="intervalheight"
            interval-width="60"
            :short-intervals="false"
            :interval-minutes="intervalminutes"
            :first-interval="firstinterval"
            :interval-count="intervalcount"
            :weekdays="weekday"
            :short-weekdays="true"
          >
            <!-- @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
            @touchstart:time="startTimeTouch"
            @touchmove:time="mouseMove"
            @touchend:time="endDragTouch"
            @touchcancel.native="cancelDrag" -->
            <template v-slot:day-body="{ date, week }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY }"
              ></div>
              <div
                class="v-current-time2"
                v-bind:style="styleObject(date, week)"
              ></div>
            </template>
            <template v-slot:event="{ event, timed, eventSummary }">
              <div class="v-event-draggable" v-html="eventSummary()"></div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
          <!-- <v-dialog v-model="dialog_transaction" max-width="400px">
            <v-card>
              <v-card-title>
                <span class="subtitle-1"
                  ><v-icon small left> mdi-cash-multiple </v-icon
                  ><b> Gutschein Token Transfer</b></span
                >
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-center h6"
                      v-if="!loading && !checkAmount"
                    >
                      <p class="subtitle-2">Nicht genug Token!</p>
                    </v-col>

                    <v-col
                      cols="12"
                      class="text-center h6"
                      v-else-if="!loading"
                    >
                      <p
                        class="subtitle-1 red--text"
                        v-if="machine.Error.active"
                      >
                        Warnung! <br />
                        Ein aktiver Maschinenfehler liegt vor!<br /><br />
                      </p>
                      <p class="subtitle-1">
                        <b
                          >Sende
                          {{ this.calculateRentPrice.totalBudget / 1000 }}
                          {{ this.TokenSymbol }}</b
                        >
                      </p>
                      <p class="subtitle-2">von Account</p>
                      <p class="caption">{{ this.mySelf.crypto_address }}</p>
                      <p class="subtitle-2">
                        <v-icon small> mdi-arrow-down-bold </v-icon>
                      </p>
                      <p class="subtitle-1">
                        <b>{{ this.Sites[this.selectedSite].name }}</b>
                      </p>
                      <div class="caption">
                        {{ this.Sites[this.selectedSite].rentBC }}
                      </div>
                    </v-col>
                    <v-col cols="12" class="text-center h5" v-else>
                      Transaktion wird durchgeführt<br /><br />
                      <v-progress-circular
                        indeterminate
                        :size="50"
                        :width="7"
                        color="secondary"
                        v-if="loading"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions v-if="!loading">
                <v-btn color="error" text @click="dialog_transaction = false"
                  >Abbrechen</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="rent()"
                  :disabled="!checkAmount"
                  >Transaktion durchführen</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog> -->
        </v-col>
        <!-- <v-col
          v-if="typeof machine == 'object'"
          order="1"
          order-sm="2"
          cols="12"
          sm="5"
          lg="4"
          xl="3"
          class="pl-4"
        >
          <v-sheet height="60"> </v-sheet>

          <div class="font-weight-bold">Willkommen {{ mySelf.nickname }}</div>
          <p>Guthaben: {{ getTokenBalance }} {{ TokenSymbol }}</p>
          <br />



          <p class="body-2 font-weight-bold">Daten {{ machine.Name }}</p>

          <table class="caption" width="100%">
        
            <tr>
              <td align="left" width="70%">
      
                Max. Reservierung:<br />
                Max. Energie / h:<br />
                Höhe Kaution:<br />
                Reservierung / {{machine.MinutesPerTimeSlot}} min:<br />
                Betriebskosten / h:<br />
                Energiekosten / kWh:<br /><br />
              </td>

              <td align="right">
             
                {{ (machine.MaxSlots * machine.MinutesPerTimeSlot / 60).toFixed(2)}} h<br />
                {{ machine.MaxWattPerHour / 1000}} kWh<br />
                {{ machine.Deposit / 1000 }} {{ TokenSymbol }}<br />
                {{ machine.SlotPrice / 1000 }} {{ TokenSymbol }}<br />
                {{ machine.RunPricePerHour / 1000 }} {{ TokenSymbol }}<br />
                {{ machine.RunPriceKiloWattPerHour / 1000}} {{ TokenSymbol }}<br />
                <br />
              </td>
            </tr>
          </table>
          <br />

          <div class="body-2 font-weight-bold">Preiskalkulation</div>
          <div class="caption">Maximum bei 100% Nutzung</div>
          <br />

          <table class="caption" width="100%" v-if="calculateRentPrice">
  
            <tr>
              <td align="left" width="70%">
       
                Kaution:<br />
                Reservierung:<br />
       
                Maschinenkosten:<br />
                inkludierter Rabatt:<br /><br />
                <b>Summe:</b><br />
              </td>
              <td align="right">
                {{ calculateRentPrice.deposit / 1000 }} {{ TokenSymbol }}<br />
                {{ calculateRentPrice.slotPrice / 1000 }} {{ TokenSymbol
                }}<br />
  
                {{ calculateRentPrice.RunPrice / 1000 }} {{ TokenSymbol }}<br />
                {{ renter._Discount / 10 }}%<br /><br />
                <b
                  >{{ calculateRentPrice.totalBudget / 1000 }}
                  {{ TokenSymbol }}</b
                ><br />
              </td>
            </tr>
            <br />
            <tr v-if="renter._FairUse">
              <td align="left" id="columnKey3">Privat/FairUse</td>
              <td align="right">
                <v-simple-checkbox
                  class="right--align"
                  color="blue"
                  v-model="fair_use"
                >
                </v-simple-checkbox>
              </td>
            </tr>
          </table>
          <br />

          <v-btn
            v-if="selectedEvent"
            small
            v-on:click="dialog_cancel = true"
            width="100%"
            >Stornieren Nr {{ selectedEvent.name }}</v-btn
          >
          <v-btn
            v-else-if="checkAmount"
            small
            v-on:click="dialog_transaction = true"
            :disabled="!new_event.start"
            width="100%"
            >Reservieren</v-btn
          >

          <v-btn v-else small disabled width="100%">Nicht genug Token</v-btn>
          <br />
          <br />
          <v-btn
            width="100%"
            :disabled="
              isActiveContract == 0 || !isActiveContract || machine.Error.active
            "
            small
            v-on:click="dialog_error = true"
            color="warning"
            >Maschinenfehler melden</v-btn
          >
        </v-col> -->
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_error" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="machine.Name" class="subtitle-1"
            >Fehlermeldung für Maschine {{ machine.Name }}<br />
            Vertragsnummer: {{ isActiveContract }}
          </span>
        </v-card-title>

        <v-card-text>
          <!--<v-form
            v-model="valid"
            style="width: 100%; height: 100%"
            class="compact-form"
          >-->
          <v-textarea
            v-model="errorMsg"
            label="Genaue Fehlerbeschreibung"
            rows="5"
          ></v-textarea>

          <!--</v-form>-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeError">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="setError"
            >Fehler melden</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_cancel" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="selectedEvent" class="h6"
            >Details zu Vertrag Nr {{ selectedEvent.name }}</span
          >
        </v-card-title>
        <v-card-text>
          Maschinen Nr: {{ selectedContract.MachineID }} <br />Abgeschlossen am:
          {{
            (selectedContract.ContractTime * 60) | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Mieter: {{ getRenter(selectedContract.Renter) }}
          <br />StartSlot: {{ selectedContract.StartSlot }} <br />SlotCount:
          {{ selectedContract.SlotCount }} <br />Verbrauchte kW:
          {{ (selectedContract.Watth / 1000).toFixed(3) }} <br />Betriebszeit in
          Minuten: {{ selectedContract.UsedMin }}

          <br />Kaution: {{ selectedContract.Deposit / 1000 }}
          <br />Reservierungsgebühr: {{ selectedContract.SlotPrice / 1000 }}
          <br />Betriebskosten: {{ selectedContract.RunPrice / 1000 }}
          <br />Gebühren: {{ selectedContract.Fees / 1000 }} <br />Summe:
          {{ selectedContract.TotalBudget / 1000 }}

          <br />Verrechnet: {{ selectedContract.Invoiced }} <br />Token
          transferiert: {{ selectedContract.MoneyTransferred }}
          <br />Rücküberweisung: {{ selectedContract.RenterReturn }}
          <br />Rücküberweisung Zeit:
          {{
            (selectedContract.DepositPayOutTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Rechnungsnummer Provider:
          {{ selectedContract.InvoiceProviderID }} <br />Rechnungsnummer
          Maschinenbesitzer: {{ selectedContract.InvoiceMachineOwnerID }}

          <br />Fehlernummer: {{ selectedContract.ErrorID }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeCancel"
            >Schliessen</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !cancelContractFlag &&
              ((isOwnEvent && checkStartTime(selectedContract)) ||
                isAdminProvider)
            "
            color="red darken-1"
            text
            @click="cancelContractFlag = true"
            >Vertrag Stornieren</v-btn
          >
          <v-btn
            v-if="cancelContractFlag"
            color="red darken-1"
            text
            :loading="cancelling"
            @click="cancelReservation"
            >Bist du sicher?</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
} from "../functions/utils";
import { ethers, BigNumber, errors } from "ethers";
import { rentErrors } from "../utils/errors";

export default {
  name: "Calendar",
  props: {
    //machine: {},
    isActiveContract: 0,
    /*events: {
      type: Array,
      default: [],
    },*/
  },
  components: {
    //FullCalendar,
  },
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      dialog_transaction: false,
      lastBlock: 0,
      loading: false,

      focus: "",
      calendarEvents: [],
      new_event: {},
      events: [],

      selectedEvent: null,
      ownEvent: null,
      foreignEvent: null,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      intervalminutes: 30,
      firstinterval: 0,
      intervalcount: 48,
      intervalheight: 15,

      dialog_event: false,
      dialog_book: false,
      dialog_commit: false,

      fair_use: false,

      calculateRentPriceTemplate: {
        RunPrice: 0,
        deposit: 0,
        extendContractNr: 0,
        slotPrice: 0,
        totalBudget: 0,
        priceFix: 0,
        priceVar: 0,
      },
      calculateRentPrice: this.calculateRentPriceTemplate,

      renter: {},
      weekEvents: [],

      spaceOpenTime: [],
      machineDownTime: [],
      windowWidth: 0,
      windowHeight: 0,

      eventSlot: {
        slotStartInMin: 0,
        slotStartInMinOfDay: 0,
        slotStartDay: 0,
        slotEndInMin: 0,
        slotEndInMinOfDay: 0,
        slotStart: 0,
        slotEnd: 0,
        slotCount: 0,
      },

      ready: false,
      dialog_error: false,
      dialog_cancel: false,
      errorMsg: "",
      valid: false,
      cancelling: false,

      event_color_own: "#00bea5",
      event_color_foreign: "#B2B2B2",
      selectedContract: {},
      cancelContractFlag: false,
      isOwnEvent: false,
      timeOffset: 0,

      machines: [],
      machine: {},
      categories: [],
      loading_data: true,
    };
  },
  created: function () {
    //window.addEventListener("resize", this.handleResize);
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.timeOffset = new Date().getTimezoneOffset();
  },
  async mounted() {
    if (!this.mySelf.roles.includes("user")) {
      await this.getUsers();
    }
    this.loadTime();
    await this.loadMachines();

    //await this.loadEvents(this.machine.MachineId, this.selectedDate);
    this.events = [];
    for (let machine of this.machines) {
      // console.log(machine.MachineId)
      this.machine = machine;
      await this.loadEvents(this.machine.MachineId, this.selectedDate);
    }
    this.calendarEvents = JSON.parse(JSON.stringify(this.events));
    //  this.loading_data = false;
    //console.log(this.machines)
    this.ready = true;
    this.scrollToTime();
    this.loading_data = false;
  },
  methods: {
    ...mapActions("user", ["getUsers"]),

    styleObject(day, week) {
      let obj = {};
      if (day == this.$moment().format("YYYY-MM-DD")) {
        obj = {
          content: "",
          position: "absolute",
          top: "0px",
          width: "100%", //"94px",
          height: "100%", //15 * this.intervalcount + "px",
          opacity: 0.08,
        };
      } else {
        obj = {
          opacity: 0,
        };
      }
      return obj;
    },
    closeError() {
      this.dialog_error = false;
    },
    async loadBalance() {
      try {
        const _tokenBalance = await this.TokenContract.balanceOf(
          this.WalletAddress
        );
        this.$store.state.web3.TokenBalance = _tokenBalance;
      } catch (err) {
        //
      }
    },
    async loadMachines() {
      // console.log("Load Machines ", this.Web3.RentContract);
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];
      this.categories = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].Active && !_machines[idx].Buyable) {
            this.categories.push(_machines[idx].Name);
            // const _error = array2Object(
            //   await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            // );
            // _machines[idx].Error = _error;
            _machines[idx].MachineId = parseInt(idx) + 1;
            this.machines.push(_machines[idx]);
          }
        }
        // console.log("Load Machines - Set selectedMachine", this.machines);
        this.selectedMachine = this.machines[0];
        this.selectedItem = 0;
      }
    },
    async setError() {
      try {
        await this.Web3.RentContract.setError(
          this.machine.MachineId,
          this.isActiveContract,
          this.errorMsg
        );
        //this.loadMachines();
        this.$forceUpdate();
        //this.calendarEvents = this.events;
        this.machine.Error.active = true;
        this.dialog_error = false;
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
    },
    closeCancel() {
      this.cancelContractFlag = false;
      this.dialog_cancel = false;
      this.cancelling = false;
    },

    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    startDrag({ event, timed }) {
      // console.log("start Drag");
      if (event && timed) {
        // console.log("start Drag - Event found");
        if (event.name == this.new_event.name) {
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
          this.selectedEvent = null;
        } else if (event.color == this.event_color_own) {
          // console.log("start Drag - My Event found");
          this.ownEvent = event;
          this.calendarEvents = JSON.parse(JSON.stringify(this.events));
        } else if (event.color == this.event_color_foreign) {
          this.foreignEvent = event;
          this.selectedEvent = null;
          this.calendarEvents = JSON.parse(JSON.stringify(this.events));
        }
      }
    },
    startTimeTouch(tms) {
      document.documentElement.style.overflow = "hidden";
      startTime(tms);
    },
    startTime(tms) {
      // console.log("start Time");

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        //console.log("start dragging");
        this.dragTime = mouse - start;
      } else if (this.ownEvent) {
      } else if (this.foreignEvent) {
      } else {
        this.selectedEvent = null;
        this.calendarEvents = JSON.parse(JSON.stringify(this.events));
        this.createStart = this.roundTime(mouse);
        const mouseRounded = this.roundTime(mouse, false);

        this.createEvent = {
          name: "Reservation",
          color: "blue", //this.rndElement(this.colors),
          start: this.createStart,
          end: mouseRounded,
          timed: true,
        };

        this.new_event = this.createEvent;
        this.calendarEvents.push(this.createEvent);
      }
    },
    extendBottom(event) {
      if (event.name == this.new_event.name) {
        this.createEvent = event;
        this.createStart = event.start;
        this.extendOriginal = event.end;
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        //console.log((max - this.createEvent.start) / 1000 / 60 / this.machine.MinutesPerTimeSlot )
        if (
          (max - this.createEvent.start) /
            this.machine.MinutesPerTimeSlot /
            1000 /
            60 <=
          this.machine.MaxSlots
        ) {
          this.createEvent.end = max;
        }
      }
    },
    endDragTouch() {
      document.documentElement.style.overflow = "auto";
      endDrag();
    },

    async endDrag() {
      //console.log("endDrag");
      if (this.createEvent || this.dragEvent) {
        //console.log("create Event");
        this.eventSlot.slotStartInMin = this.new_event.start / 1000 / 60;
        this.eventSlot.slotStartInMinOfDay =
          (this.new_event.start -
            this.$moment(this.new_event.start).startOf("day")) /
          1000 /
          60;
        this.eventSlot.slotStartDay =
          this.$moment(this.new_event.start).isoWeekday() - 1;
        this.eventSlot.slotCount =
          (this.new_event.end - this.new_event.start) /
          1000 /
          (this.machine.MinutesPerTimeSlot * 60);
        if (this.eventSlot.slotCount == 0) {
          this.eventSlot.slotCount = 1;
        }
        this.eventSlot.slotEndInMin =
          this.eventSlot.slotStartInMin +
          this.eventSlot.slotCount * this.machine.MinutesPerTimeSlot;
        this.eventSlot.slotEndInMinOfDay =
          this.eventSlot.slotStartInMinOfDay +
          this.eventSlot.slotCount * this.machine.MinutesPerTimeSlot;
        this.eventSlot.slotStart =
          this.eventSlot.slotStartInMin / this.machine.MinutesPerTimeSlot;

        this.eventSlot.slotEnd =
          this.eventSlot.slotStart + this.eventSlot.slotCount;

        //console.log(this.mySelf.crypto_address, this.machine.MachineId,slotStartDay, slotStartInMin, slotEndInMin, slotStartInMinOfDay, slotEndInMinOfDay, slotCount, this.fair_use);
        //this.calculateRentPrice = await this.Web3.RentContract.calculateRentPrice(this.mySelf.crypto_address, this.machine.MachineId, slotStart, slotCount, this.fair_use);
        //this.calculateRentPrice = array2Object(await this.Web3.RentContract.calculateRentPrice(this.mySelf.crypto_address, this.machine.MachineId, slotStart, slotCount, this.fair_use));

        this.calcPrice();
      } else if (this.ownEvent) {
        // console.log("own Event selected");
        this.isOwnEvent = true;
        this.selectedEvent = this.ownEvent;
        this.selectedContract = array2Object(
          await this.Web3.RentContract.getContract(this.ownEvent.name)
        );
        this.dialog_cancel = true;
      } else if (this.foreignEvent) {
        // console.log("foreign Event selected");
        if (
          this.mySelf.roles.includes("admin") ||
          this.mySelf.roles.includes("provider") ||
          this.mySelf.roles.includes("machineOwner")
        ) {
          this.isOwnEvent = true;
        } else {
          this.isOwnEvent = false;
        }
        this.selectedEvent = this.foreignEvent;
        this.selectedContract = array2Object(
          await this.Web3.RentContract.getContract(this.foreignEvent.name)
        );
        this.dialog_cancel = true;
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.foreignEvent = null;
      this.ownEvent = null;
    },
    async calcPrice() {
      if (this.eventSlot.slotCount != 0) {
        try {
          // console.log("Calculate Price: ", this.mySelf.crypto_address, this.machine.MachineId, this.eventSlot.slotStart, this.eventSlot.slotCount, this.timeOffset, this.fair_use);

          this.calculateRentPrice = array2Object(
            await this.Web3.RentContract.calculateRentPrice(
              this.mySelf.crypto_address,
              this.machine.MachineId,
              this.eventSlot.slotStart,
              this.eventSlot.slotCount,
              this.timeOffset,
              this.fair_use
            )
          );
        } catch (err) {
          //console.log(err)
          this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
          this.snackbar = true;
        }
      }
    },
    cancelDrag() {
      document.documentElement.style.overflow = "auto";
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.calendarEvents.indexOf(this.createEvent);
          if (i !== -1) {
            this.calendarEvents.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = this.machine.MinutesPerTimeSlot; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    setToday() {
      let _time = parseInt((this.$moment().format("X") / 60).toFixed(0));
      this.$store.state.user.selectedDate = _time;

      this.focus = "";
    },
    prev() {
      let _time = parseInt(
        (
          this.$moment
            .unix(this.selectedDate * 60)
            //.subtract(1, "week")
            .subtract(1, "day")
            .format("X") / 60
        ).toFixed(0)
      );
      this.$store.state.user.selectedDate = _time;
      this.$refs.calendar.prev();
    },
    next() {
      let _time = parseInt(
        (
          this.$moment
            .unix(this.selectedDate * 60)
            //.add(1, "week")
            .add(1, "day")
            .format("X") / 60
        ).toFixed(0)
      );
      this.$store.state.user.selectedDate = _time;
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },

    async loadEvents(machineId, _now) {
      // console.log("LOAD EVENTS");

      // console.log("NOW: ", _now);
      // console.log("machineId: ", machineId);
      //console.log("NOW BIG: ", await BigNumber.from(_now));
      this.weekEvents = await this.Web3.RentContract.getAllWeeklyContracts(
        machineId,
        BigNumber.from(_now)
      );

      let _events = [];
      for (let _weekDay in this.weekEvents) {
        //console.log("weekday: ", _weekDay, this.weekEvents[_weekDay], this.weekEvents[_weekDay].length)
        for (
          let _eventId = 0;
          _eventId < this.weekEvents[_weekDay].length;
          _eventId++
        ) {
          const _event = this.weekEvents[_weekDay][_eventId];
          if (_event.ContractNr > 0) {
            let obj = {};
            obj.category = this.machine.Name;
            if (
              _event.Renter.toLowerCase() ==
              this.mySelf.crypto_address.toLowerCase()
            ) {
              obj.color = this.event_color_own;
            } else {
              obj.color = this.event_color_foreign;
            }
            obj.start =
              _event.StartSlot * this.machine.MinutesPerTimeSlot * 60 * 1000;
            obj.end =
              (_event.StartSlot + _event.SlotCount) *
              this.machine.MinutesPerTimeSlot *
              60 *
              1000;

            obj.name = _event.ContractNr.toString();
            obj.timed = true;
            if (!_.find(_events, { name: obj.name })) {
              _events.push(obj);
            }
          }
        }

        //console.log("end day: ", _weekDay)
      }
      // console.log("ADD EVENTS: ", _events)
      this.events = this.events.concat(_events);
    },

    async rent() {
      this.loading = true;
      const sendToken = ethers.utils.parseEther(
        (this.calculateRentPrice.totalBudget / 1000).toString()
      );
      this.lastBlock = this.actualBlock;
      try {
        await this.Web3.TokenContract.approve(
          this.Web3.RentContractAddress,
          sendToken
        );
        await this.Web3.RentContract.rent(
          this.machine.MachineId,
          this.eventSlot.slotStart,
          this.eventSlot.slotCount,
          this.timeOffset,
          this.fair_use,
          this.mySelf.crypto_address
        );

        this.loadBalance();

        this.$store.state.user.rentingAction = true;
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      this.calendarEvents = JSON.parse(JSON.stringify(this.events));
      this.new_event = {};
      this.calculateRentPrice = this.calculateRentPriceTemplate;
    },

    async cancelReservation() {
      this.cancelContractFlag = false;
      try {
        this.cancelling = true;
        await this.Web3.RentContract.cancelContract(
          parseInt(this.selectedEvent.name)
        );
        this.selectedEvent = null;
        this.$store.state.user.rentingAction = true;
        this.dialog_cancel = false;
        this.loadBalance();
        this.cancelling = false;
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
    },

    async loadTime() {
      //ToDo: in vuex
      this.spaceOpenTime = await this.Web3.RentContract.getAllTimeSlots(0);
      console.log("loadTime spaceOpenTime ", this.spaceOpenTime);

      this.intervalminutes = 60; //this.machine.MinutesPerTimeSlot;

      let firstStart = 0;
      let latestEnd = 0;

      for (let _int = 0; _int < 7; _int++) {
        let _firstinterval =
          this.spaceOpenTime[_int][0][0] / this.intervalminutes;
        let _intervalcount =
          this.spaceOpenTime[_int][0][1] / this.intervalminutes -
          this.firstinterval; //28,

        console.log("loadTime ", _firstinterval, _intervalcount);
        if (_int == 0 || _firstinterval < firstStart) {
          firstStart = _firstinterval;
        }
        if (_int == 0 || _intervalcount > latestEnd) {
          latestEnd = _intervalcount;
        }
      }

      this.firstinterval = firstStart;
      this.intervalcount = latestEnd;
    },

    checkStartTime(_contract) {
      const StartTime =
        _contract.StartSlot * this.machine.MinutesPerTimeSlot * 60;
      //console.log(_contract, StartTime, this.$moment().format('X'));
      return StartTime > parseInt(this.$moment().format("X"));
    },
    getRenter(_address) {
      if (_address) {
        if (this.mySelf.roles.includes("user")) {
          return _address;
        } else {
          const _user = _.find(this.Users, {
            crypto_address: _address.toLowerCase(),
          });
          if (_user) {
            return _user.firstname + " " + _user.lastname;
          } else {
            return _address;
          }
        }
      } else {
        return 0;
      }
    },
  },
  computed: {
    ...mapState({
      Web3: (web3) => web3.web3,
      mySelf: ({ auth }) => auth.mySelf,
      selectedSite: ({ user }) => user.selectedSite,
      actualBlock: (web3) => web3.web3.actualBlock,
      WalletAddress: ({ web3 }) => web3.Address,
      TokenBalance: ({ web3 }) => web3.TokenBalance,
      TokenName: ({ web3 }) => web3.TokenName,
      TokenSymbol: ({ web3 }) => web3.TokenSymbol,
      Sites: ({ user }) => user.Sites,
      selectedDate: ({ user }) => user.selectedDate,
      Users: ({ user }) => user.Users,
    }),
    isAdminProvider() {
      if (
        this.mySelf.roles.includes("admin") ||
        this.mySelf.roles.includes("provider")
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTokenBalance() {
      try {
        return ethers.utils.formatEther(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    checkAmount() {
      try {
        // console.log("CHECK AMOUNT");
        const totalBig = ethers.utils.parseEther(
          (this.calculateRentPrice.totalBudget / 1000).toString()
        );
        // console.log(totalBig.lte(this.TokenBalance));
        return totalBig.lte(this.TokenBalance);
      } catch (err) {
        return 0;
      }
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
  },
  watch: {
    fair_use() {
      this.calcPrice();
    },
    async actualBlock() {
      if (this.lastBlock != 0) {
        this.loading = false;
        this.dialog_transaction = false;
        this.lastBlock = 0;
      }

      this.events = [];
      for (let machine of this.machines) {
        // console.log(machine.MachineId)
        this.machine = machine;
        await this.loadEvents(this.machine.MachineId, this.selectedDate);
      }
      let _events = JSON.parse(JSON.stringify(this.events));
      if (this.new_event.start) {
        // console.log("new event: ", this.new_event);
        _events.push(this.new_event);
      }

      this.calendarEvents = JSON.parse(JSON.stringify(_events));
    },

    async selectedDate() {
      console.log("NEW selected Date ", this.selectedDate);
      //this.loading_data = true;
      //await this.loadEvents(this.machine.MachineId, this.selectedDate);
      this.events = [];
      for (let machine of this.machines) {
        // console.log(machine.MachineId)
        this.machine = machine;
        await this.loadEvents(this.machine.MachineId, this.selectedDate);
      }

      this.calendarEvents = JSON.parse(JSON.stringify(this.events));
      //this.loading_data = false;
    },
  },
};
</script>


<style lang='scss'>
/*

.card--flex-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}
body {
  overflow-x: hidden;
}

.calendarSection {
  top: 0px;
}
.fc-toolbar {
  font-size: 0.6rem !important;
}
.btn-primary {
  font-weight: 400 !important;
  padding: 0.1rem 0.2rem !important;
  font-size: 0.8rem !important;
}*/
/*.v-calendar-daily__body {
  //touch-action: none !important;
    overflow: hidden !important;
}*/
.v-btn--fab.v-size--default {
  height: 20px !important;
  width: 20px !important;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
.v-current-time {
  height: 1px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.v-current-time2 {
  background-color: #ffff00;
  position: absolute;
  pointer-events: none;
}
.box {
  border: solid 1px red;
}
.greybox {
  border: solid 1px lightgrey;
}
.v-event-draggable {
  font-size: 9px;
}

.v-calendar-category__category {
  font-size: 9px !important;
}
</style>