<template>
  <div class="organization" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <!-- class="text-center">-->
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-else
            :headers="headers_organization"
            :items="organizations"
            sort-by="lastname"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
            :search="search_number"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Organizations") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  flat
                  single-line
                  append-icon="search"
                  hide-details
                  v-model="search_number"
                  label="Suche"
                ></v-text-field>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemOrganization"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_organization" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{
                        formTitleOrganization
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          :rules="addressBCRule"
                          required
                          v-model="editedItemOrganization.addressBC"
                          label="Crypto Address"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule && minPermissionLevelRule"
                          type="number"
                          required
                          v-model="editedItemOrganization.permissionLevel"
                          label="Berechtigungslevel"
                        ></v-text-field>
                        <v-select
                          :items="orgTypes"
                          v-model="editedItemOrganization.orgType"
                          label="Organisationstyp"
                        ></v-select>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.street"
                          label="Strasse Nr"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.codeTown"
                          label="Postcode Stadt"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.country"
                          label="Land"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.eMail"
                          label="eMail"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.phone"
                          label="phone"
                        ></v-text-field>
                        <v-text-field
                          
                          
                          v-model="editedItemOrganization.bankDetails.name"
                          label="Konto Name"
                        ></v-text-field>
                        <v-text-field
                          
                          
                          v-model="editedItemOrganization.bankDetails.iban"
                          label="IBAN"
                        ></v-text-field>
                        <v-text-field
                          
                          
                          v-model="editedItemOrganization.bankDetails.bic"
                          label="BIC"
                        ></v-text-field>
                        <v-text-field
                          required
                          v-model="editedItemOrganization.VAT.VATNr"
                          label="VATNr"
                        ></v-text-field>
                        <v-checkbox
                          v-model="vat"
                          label="VAT Registered"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editedItemOrganization.VAT.OnlyTokenSettlement"
                          label="Keine Rechnungslegung (MachineOwner)"
                        ></v-checkbox>
                        <v-btn
                          color="green"
                          fab
                          x-small
                          dark
                          class="mb-2"
                          @click="addVatGroup"
                          ><v-icon> add </v-icon></v-btn
                        >
                        Add VAT Group
                        <v-data-table
                          :headers="headers_vatGroup"
                          :items="vatGroup"
                          sort-by="lastname"
                          class="elevation-1 caption"
                          dense
                          must-sort
                          :footer-props="{
                            'items-per-page-options': [25, 50, 100, -1],
                          }"
                          :items-per-page="50"
                          item-class="caption"
                        >
                          <template v-slot:item.vatRate="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.vatRate"
                            >
                              {{ props.item.vatRate }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.vatRate"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                          <template v-slot:item.actions_delete="{ item }">
                            <v-icon small @click="deleteVATGroup(item)"
                              >mdi-delete</v-icon
                            >
                          </template>
                        </v-data-table>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeOrganization"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!valid"
                        color="blue darken-1"
                        text
                        @click="validate"
                        >Save</v-btn
                      >
                      <v-btn
                        v-if="valid"
                        color="blue darken-1"
                        text
                        @click="saveOrganization"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog
                  v-model="dialog_organization_invoice"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{
                        formTitleOrganization
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          v-if="
                            editedItemOrganization.orgType == 'Provider' ||
                            editedItemOrganization.orgType == 'MachineOwner'
                          "
                          disabled
                          v-model="editedItemOrganization.InvoiceNr"
                          label="Last Invoice Nr"
                        ></v-text-field>
                        <v-text-field
                          v-if="
                            editedItemOrganization.orgType == 'Provider' ||
                            editedItemOrganization.orgType == 'MachineOwner'
                          "
                          :rules="requiredRule"
                          required
                          v-model="editedItemOrganization.InvoicePrefix"
                          label="InvoicePrefix"
                        ></v-text-field>
                        <v-text-field
                          v-if="editedItemOrganization.orgType == 'Provider'"
                          :rules="requiredRule"
                          type="number"
                          required
                          v-model="editedItemOrganization.ProviderRate"
                          label="ProviderRate in Promille"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="closeOrganizationInvoice"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!valid"
                        color="blue darken-1"
                        text
                        @click="validate"
                        >Save</v-btn
                      >
                      <v-btn
                        v-else-if="!changeInvoice"
                        color="blue darken-1"
                        @click="changeInvoice = true"
                        text
                        >Save</v-btn
                      >
                      <v-btn
                        v-else
                        color="yellow darken-2"
                        text
                        @click="saveOrganizationInvoice"
                        >Are you really sure about this?</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.VAT="{ item }">
              {{ JSON.stringify(item.VAT) }}
            </template>
            <template v-slot:item.actions_edit="{ item }">
              <v-icon small class="mr-2" @click="editItemOrganization(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <template v-slot:item.actions_delete="{ item }">
              <v-icon small @click="deleteItemOrganization(item)"
                >mdi-delete</v-icon
              >
            </template>
            <template v-slot:item.actions_invoice="{ item }">
              <v-icon
                v-if="
                  item.orgType == 'Provider' || item.orgType == 'MachineOwner'
                "
                small
                class="mr-2"
                @click="editItemOrganizationInvoice(item)"
                >mdi-pencil</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, addressBCRule } from "../utils/rules";
import {
  array2ArrayObject,
  extractRevertReason,
  extractReason,
  array2Object,
} from "../functions/utils";
import { ethers, errors } from "ethers";
import {rentErrors} from "../utils/errors";

export default {
  name: "organization",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      addressBCRule,
      userPermissionLevel: 2,
      minPermissionLevelRule: null,

      dialog_delete: false,
      dialog_vat: false,

      dialog_organization: false,
      dialog_organization_invoice: false,
      search_number: "",
      headers_organization: [
        { text: "Edit", value: "actions_edit", sortable: false },
        { text: "Delete", value: "actions_delete", sortable: false },
        { text: "Invoice", value: "actions_invoice", sortable: false },
        { text: "Name", value: "name" },
        { text: "address", value: "addressBC" },
        { text: "Level", value: "permissionLevel" },
        { text: "orgType", value: "orgType" },
        { text: "street", value: "street" },
        { text: "codeTown", value: "codeTown" },
        { text: "country", value: "country" },
        { text: "eMail", value: "eMail" },
        { text: "phone", value: "phone" },
        { text: "VAT", value: "VAT" },
        { text: "Kontoname", value: "bankDetails.name" },
        { text: "IBAN", value: "bankDetails.iban" },
        { text: "BIC", value: "bankDetails.bic" },
      ],

      editedIndexOrganization: -1,

      defaultItemOrganization: {
        addressBC: "",
        permissionLevel: 3, //1 is highest Permission Level (== Root/ContractAdmin)
        name: "",
        orgType: "", //i.e. Machine Owner, Site Owner, Root/ContractAdmin, Insurance, ...
        street: "",
        codeTown: "",
        country: "",
        eMail: "",
        phone: "",
        VAT: { VATRate: [], VATregistered: false, VATNr: "" },
        bankDetails: {
          name: "",
          bic: "",
          iban: ""
        }
      },
      editedItemOrganization: {},
      vat: false,
      defaultBankDetails: {
          name: "",
          bic: "",
          iban: ""
        },

      snackbar: false,
      snackbar_text: "",

      organizations: [],
      loading_data: true,

      changeInvoice: false,
      orgTypesProvider: ["MachineOwner", "Insurance"],
      orgTypesAdmin: ["Provider", "MachineOwner", "Insurance"],
      orgTypes: [],

      vatGroup: [],
      selectedVat: {},
      headers_vatGroup: [
        { text: "Index", value: "vatIndex" },
        { text: "VAT Rate in Promille", value: "vatRate" },
        { text: "Delete", value: "actions_delete", sortable: false },
      ],
    };
  },
  created () {
    this.editedItemOrganization = this.defaultItemOrganization;
  },
  async mounted() {
    const orgs = await this.Web3.SitesRegistryContract.getAllOrganisations();
    let _organizations = array2ArrayObject(orgs);
    for (let org of _organizations) {
      try {
        org.bankDetails = JSON.parse(org.bankDetails);
        }
      catch (err) {
        //console.log(err);
      }
    }
    //console.log(_organizations)
    if (!this.mySelf.roles.includes("admin")) {
      this.orgTypes = this.orgTypesProvider;
      this.userPermissionLevel = 2;
      // console.log("Load Org Aff ");
      let affs = [];
      for (let org of _organizations) {
        const _affs = await this.Web3.SitesRegistryContract.getAllOrganisationAffiliations(
          org.addressBC
        );
        _affs.forEach((_aff) => {
          if (_aff.toLowerCase() == this.mySelf.crypto_address.toLowerCase()) {
            affs.push(org.addressBC);
          }
        });
      }
      affs = _.uniq(affs);
      affs.forEach((aff) => {
        this.organizations.push(
          _.find(_organizations, (org) => {
            return org.addressBC.toLowerCase() == aff.toLowerCase();
          })
        );
      });
    } else {
      this.orgTypes = this.orgTypesAdmin;
      this.userPermissionLevel = 0;
      this.organizations = _.filter(_organizations, {});
    }
    for (let org of this.organizations) {
      if (org.orgType == "Provider") {
        org.InvoiceNr = await this.Web3.InvoiceContract.ProviderInvoiceNr();
        org.InvoicePrefix = await this.Web3.InvoiceContract.ProviderInvoicePrefix();
        org.ProviderRate = await this.Web3.InvoiceContract.ProviderRate();
      } else if (org.orgType == "MachineOwner") {
        const MachineOwner = await this.Web3.InvoiceContract.readMachineOwner(
          org.addressBC
        );
        org.InvoiceNr = MachineOwner._ActInvoiceNr;
        org.InvoicePrefix = MachineOwner._InvoicePrefix;
      }
      org.VAT = array2Object(org.VAT);
    }

    this.minPermissionLevelRule = [
      (v) => {
        return !!v || "Bitte einen Level angeben";
      },
      (v) =>
        (v && v > this.userPermissionLevel) ||
        "Nur PermissionLevel größer " + this.userPermissionLevel + " erlaubt",
    ];

    this.editedItemOrganization = Object.assign(
      {},
      this.defaultItemOrganization
    );
    this.loading_data = false;
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Web3: (web3) => web3.web3,
    }),

    formTitleOrganization() {
      return this.editedIndexOrganization === -1
        ? "New Organization"
        : "Edit Organization";
    },
  },
  methods: {
    async createItemOrganization() {
      this.editedItemOrganization = Object.assign(
        {},
        this.defaultItemOrganization
      );
      this.editedItemOrganization.VAT = {
        VATRate: [0],
        VATregistered: false,
        VATNr: "",
      };
      this.editedItemOrganization.bankDetails = JSON.parse(JSON.stringify(this.defaultBankDetails));
      this.editedIndexOrganization = -1;
      this.dialog_organization = true;
    },

    async editItemOrganization(item) {
      // console.log("editItem ", item);
      this.editedIndexOrganization = this.organizations.indexOf(item);
      this.editedItemOrganization = JSON.parse(JSON.stringify(item)); //Object.assign({}, item);
      this.vat = this.editedItemOrganization.VAT.VATregistered;
      if (typeof(this.editedItemOrganization.bankDetails) !== 'object') {
        this.editedItemOrganization.bankDetails = JSON.parse(JSON.stringify(this.defaultBankDetails));
      }

      this.vatGroup = [];
      for (let rateIndex in this.editedItemOrganization.VAT.VATRate) {
        let obj = {};
        obj.vatIndex = parseInt(rateIndex);
        obj.vatRate = this.editedItemOrganization.VAT.VATRate[rateIndex];
        this.vatGroup.push(obj);
      }
      this.selectedVat = this.vatGroup[0];
      this.dialog_organization = true;
    },

    async editItemOrganizationInvoice(item) {
      //console.log("editItem ", item);
      this.editedIndexOrganization = this.organizations.indexOf(item);
      //this.editedItemOrganization = Object.assign({}, item);
      this.editedItemOrganization = JSON.parse(JSON.stringify(item));
      this.dialog_organization_invoice = true;
    },

    async deleteItemOrganization(item) {
      const index = this.organizations.indexOf(item);
      try {
        await this.Web3.SitesRegistryContract.delOrganisation(item.addressBC);
        this.organizations.splice(index, 1);
      } catch (err) {
        console.log(err);
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
    },

    closeOrganization() {
      this.dialog_organization = false;
      this.$nextTick(() => {
        this.editedItemOrganization = Object.assign(
          {},
          this.defaultItemOrganization
        );
        this.editedIndexOrganization = -1;
      });
      this.resetValidation();
    },

    closeOrganizationInvoice() {
      this.dialog_organization_invoice = false;
      this.$nextTick(() => {
        this.editedItemOrganization = Object.assign(
          {},
          this.defaultItemOrganization
        );
        this.editedIndexOrganization = -1;
      });
      this.resetValidation();
      this.changeInvoice = false;
    },

    async saveOrganizationInvoice() {
      // console.log("saveOrganizationInvoice")
      if (this.editedItemOrganization.orgType == "Provider") {
        try {
          await this.Web3.InvoiceContract.setProvider(
            this.editedItemOrganization.InvoicePrefix,
            this.editedItemOrganization.ProviderRate
          );
          Object.assign(
            this.organizations[this.editedIndexOrganization],
            this.editedItemOrganization
          );
          this.dialog_organization_invoice = false;
        } catch (err) {
          this.snackbar_text = extractRevertReason(err);
          this.snackbar = true;
        }
      } else if (this.editedItemOrganization.orgType == "MachineOwner") {
        try {
          await this.Web3.InvoiceContract.setMachineOwner(
            this.editedItemOrganization.addressBC,
            this.editedItemOrganization.InvoicePrefix
          );

          Object.assign(
            this.organizations[this.editedIndexOrganization],
            this.editedItemOrganization
          );
          this.dialog_organization_invoice = false;
        } catch (err) {
          this.snackbar_text = extractRevertReason(err);
          this.snackbar = true;
        }
      }
      this.changeInvoice = false;
    },
    async saveOrganization() {
      // console.log("saveOrganization", this.editedItemOrganization)
      let arr = [];

      for (let rate of this.vatGroup) {
        //console.log(rate);
        arr.push(parseInt(rate.vatRate));
      }
      //this.editedItemOrganization.VAT.VATRate = _.map(this.vatGroup, "value");
      this.editedItemOrganization.VAT.VATRate = arr;
      console.log(this.editedItemOrganization.VAT.VATRate);
      try {
        this.editedItemOrganization.addressBC = ethers.utils.getAddress(
          this.editedItemOrganization.addressBC
        );
        
        this.editedItemOrganization.bankDetails = JSON.stringify(this.editedItemOrganization.bankDetails);
        if (this.editedIndexOrganization > -1) {
          try {
            // console.log(this.editedItemOrganization)
            await this.Web3.SitesRegistryContract.changeOrganisation(
              this.editedItemOrganization
            );
            this.editedItemOrganization.bankDetails = JSON.parse(this.editedItemOrganization.bankDetails);
            Object.assign(
              this.organizations[this.editedIndexOrganization],
              this.editedItemOrganization
            );
          } catch (err) {
            this.snackbar_text = extractRevertReason(err);
            this.snackbar = true;
          }
        } else {
          // console.log(this.editedItemOrganization);
          // console.log(this.Web3.SitesRegistryContract);
          try {
            await this.Web3.SitesRegistryContract.addOrganisation(
              this.editedItemOrganization
            );
            this.editedItemOrganization.bankDetails = JSON.parse(this.editedItemOrganization.bankDetails);
            this.organizations.push(this.editedItemOrganization);
          } catch (err) {
            console.log(err);
            this.snackbar_text = extractRevertReason(err);
            this.snackbar = true;
          }
        }
        
        this.closeOrganization();
      } catch (err) {
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addVatGroup() {
      let obj = { vatIndex: this.vatGroup.length, vatRate: 0 };
      this.vatGroup.push(obj);
      this.selectedVat = obj;
    },
    // setVat(item) {
    //   console.log(item);
    //   this.selectedVat = item;
    //   this.dialog_vat = true;
    // },
    deleteVATGroup(item) {
      console.log(item.vatIndex);
      this.vatGroup.splice(item.vatIndex, 1);
      let newGroup = [];
      for (let rateIndex in this.vatGroup) {
        let obj = {};
        obj.vatIndex = parseInt(rateIndex);
        obj.vatRate = this.vatGroup[rateIndex].vatRate;
        newGroup.push(obj);
      }
      this.vatGroup = newGroup;
    },
  },
  watch: {
    vat(value) {
      // if (!value) {
      //   this.editedItemOrganization.VAT.VATRate = 0;
      // }
      this.editedItemOrganization.VAT.VATregistered = value;
    },
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>