import api from "../../../api";

export default {
  async getCertificates({ commit }, {rentBC}) {
    commit("REQUEST_CERTIFICATE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CERTIFICATE",
        await api.getCertificates({rentBC})
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CERTIFICATE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async sendCertificate({ commit }, id ) {
    commit("REQUEST_SEND_CERTIFICATE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_SEND_CERTIFICATE",
        await api.sendCertificate( id )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_SEND_CERTIFICATE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async delCertificates({ commit }, query ) {
    commit("REQUEST_DELETE_CERTIFICATES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_CERTIFICATES",
        await api.delCertificates( query )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_CERTIFICATES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
