import api from "../../../api";

export default {
  async getMySelf({ commit }) {
    commit("REQUEST_MYSELF");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_MYSELF", await api.mySelf());
    } catch (err) {
      commit("FAILURE_REQUEST_MYSELF");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async authenticate({ commit, dispatch }, obj) {
    commit("REQUEST_AUTH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_AUTH",
        await api.authenticate(obj)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_AUTH");
    } finally {
      commit("SET_LOADING", false, { root: true });

      dispatch("getMySelf");
    }
  },
  async cryptoAuth({ commit, dispatch }, { digest, signature }) {
    commit("REQUEST_CRYPTOAUTH");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CRYPTOAUTH",
        await api.cryptoAuth({ digest, signature })
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CRYPTOAUTH", err);
    } finally {
      commit("SET_LOADING", false, { root: true });

      dispatch("getMySelf");
    }
  },
  async logout() {
    window.localStorage.removeItem("Authorization");
    window.location.reload();
  }
};
