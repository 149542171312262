import api from "../../../api";

export default {
  async getContracts({ commit }) {
    commit("REQUEST_CONTRACTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CONTRACTS",
        await api.getContracts()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CONTRACTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deleteContract({ commit }, id) {
    commit("REQUEST_DELETE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_CONTRACT",
        await api.deleteContract(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updateContract({ commit }, item ) {
    commit("REQUEST_UPDATE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_CONTRACT",
        await api.updateContract(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createContract({ commit }, item ) {
    commit("REQUEST_CREATE_CONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_CONTRACT",
        await api.createContract( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_CONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
