import { ethers } from "ethers";
import store from "../store/index";

export default {
  install: function (Vue, opts = {}) {
    /*
    const _this = this

    const self = new Vue(_this)

    Object.defineProperty(Vue.prototype, '$auth', {
      get () {
        return self
      }
    })*/

    const { router } = opts;

    if (!router) {
      console.info(
        "To use with vue-router, pass it to me Vue.use(VueAuth, {router})"
      );
    } else {
      router.beforeEach(async (to, from, next) => {
        //console.log("beforeEach", { to, from, next });

        if (to.name == "site") {
          //console.log("Params: ", to.params);
          if (to.params.site) {
            try {
              localStorage.removeItem("Authorization");
              //this.site_address = ethers.utils.getAddress(to.params.site);
              store.state.auth.site = ethers.utils.getAddress(to.params.site);
            } catch (err) {
              console.log("No Link on crypto_login");
            }
          }
          if (to.params.site_name) {
            try {
              //this.site_address = ethers.utils.getAddress(to.params.site);
              store.state.auth.site_name = to.params.site_name;
            } catch (err) {
              //
            }
          }
        }
        if (to.name == "login") {
          localStorage.removeItem("Authorization");
        }

        if (to.name == "machine" || to.name == "door") {
          console.log("Machine Params: ", to.params);

          if (to.params.machine) {
            store.state.auth.machine = parseInt(to.params.machine);
          }
          if (to.params.door) {
            store.state.auth.door = parseInt(to.params.door);
          }
        }

        try {
          const token = localStorage.getItem("Authorization");
          const is_wallet = localStorage.getItem("wallet");

          //console.log("token: ", token);
          //console.log("wallet: ", is_wallet);

          if (!is_wallet) {
            //console.log("not wallet ", to.name)
            if (
              to.name == "cryptologin" ||
              to.name == "login" ||
              //to.name == "loginFirebase" ||
              to.name == "homeCheckContract" ||
              to.name == "dsgvo" ||
              to.name == "about" ||
              to.name == "resetPassword" ||
              to.name == "resetPasswordConfirm" ||
              to.name == "accountActivation"
            ) {
              //console.log("route to ", to.name)
              next();
            } else if (to.name == "homeReadOnly" && !token) {
              next({ name: "login" });
            } else if (to.name == "homeReadOnly" && token) {
              next();
            } else {
              //console.log("ELSE")
              next({ name: "cryptologin" });
            }
          } else if (!token) {
            if (
              to.name == "login" ||
              //to.name == "loginFirebase" ||
              to.name == "homeCheckContract" ||
              //||*/
              to.name == "dsgvo" ||
              to.name == "about" ||
              to.name == "resetPassword" ||
              to.name == "resetPasswordConfirm" ||
              to.name == "accountActivation" ||
              to.name == "signUp" ||
              to.name == "cryptologin"
            ) {
              next();
            } else if (to.name == "homeReadOnly") {
              next({ name: "login" });
            } else {
              next({ name: "cryptologin" });
            }
          } else {
            const store = opts.store;
            await store.dispatch("auth/getMySelf");
            if (!store.state.auth.mySelf) {
              localStorage.removeItem("Authorization");
              next({ name: "cryptologin" });
              //throw new Error("Incorrect token");
            } else {
              next();
            }
          }
        } catch (e) {
          console.log("Auth Router Error", e);

          if (
            to.name == "login" ||
            //to.name == "loginFirebase" ||
            to.name == "cryptoLogin" ||
            to.name == "resetPassword" ||
            to.name == "resetPasswordConfirm" ||
            //to.name == "signUp" ||
            to.name == "accountActivation" ||
            to.name == "homeReadOnly" ||
            to.name == "homeCheckContract"
          ) {
            next();
          } else {
            next({ name: "cryptologin" });
          }
        }
      });
    }
  },
};
