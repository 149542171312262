const REQUEST_PERSONS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_PERSONS = (state, { body }) => {
  state.Persons = body;
  state.status = "success";
};

const FAILURE_REQUEST_PERSONS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_PERSON = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_PERSON = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_PERSON = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_PERSON = state => {
  state.statusUpdate = "failure";
};

const REQUEST_PERSON_HISTORY = state => {
  state.statusHistory = "fetching";
};

const SUCCESS_REQUEST_PERSON_HISTORY = (state, { body }) => {
  //console.log("SUCCESS_HISTORY")
  //console.log(body.history)
  state.History = body;
  state.statusHistory = "success";
};

const FAILURE_REQUEST_PERSON_HISTORY = state => {
  state.statusHistory = "failure";
};

const REQUEST_CREATE_PERSON = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_PERSON = state => {
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_PERSON = state => {
  state.statusCreate = "failure";
};

const REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_PERSON = state => {
  state.statusDeactivate = "failure";
};
export default {
  REQUEST_PERSONS,
  SUCCESS_REQUEST_PERSONS,
  FAILURE_REQUEST_PERSONS,
  REQUEST_CREATE_PERSON,
  SUCCESS_REQUEST_CREATE_PERSON,
  FAILURE_REQUEST_CREATE_PERSON,
  REQUEST_PERSON_HISTORY,
  SUCCESS_REQUEST_PERSON_HISTORY,
  FAILURE_REQUEST_PERSON_HISTORY,
  REQUEST_DELETE_PERSON,
  SUCCESS_REQUEST_DELETE_PERSON,
  FAILURE_REQUEST_DELETE_PERSON,
  REQUEST_UPDATE_PERSON,
  SUCCESS_REQUEST_UPDATE_PERSON,
  FAILURE_REQUEST_UPDATE_PERSON,
  REQUEST_DEACTIVATE_PERSON,
  SUCCESS_REQUEST_DEACTIVATE_PERSON,
  FAILURE_REQUEST_DEACTIVATE_PERSON
};
