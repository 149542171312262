import Vue from "vue";
import Router from "vue-router";
import LoginComponent from "./views/Login.vue";
import SignUpComponent from "./views/SignUp.vue";
import AccountActivation from "./views/AccountActivation.vue";
import ResetPasswordComponent from "./views/ResetPassword.vue";
import Home from "./views/Home.vue";
import Dashboard from "./views/Dashboard.vue";
import Start from "./views/Start.vue";
import CryptoLogin from "./views/CryptoLogin.vue";
import Dsgvo from "./views/Dsgvo.vue";
import About from "./views/About.vue";
import HomeReadOnly from "./views/HomeReadOnly.vue";
import HomeCheckContract from "./views/HomeCheckContract.vue";
//import LoginFirebaseComponent from "./views/LoginFirebase.vue";

Vue.use(Router);

export default new Router({
  mode: "hash", //process.env.CORDOVA_PLATFORM ? "hash" : "history", //"history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginComponent,
    },
    // {
    //   path: "/loginFirebase",
    //   name: "loginFirebase",
    //   component: LoginFirebaseComponent
    // },
    {
      path: "/sign-up",
      name: "signUp",
      component: SignUpComponent,
      props: true,
    },
    {
      path: "/activate-account/:token?",
      component: AccountActivation,
      name: "accountActivation",
    },
    {
      path: "/reset-password",
      name: "resetPassword",
      component: ResetPasswordComponent,
    },
    {
      path: "/confirm-password-reset/:token?",
      name: "resetPasswordConfirm",
      component: ResetPasswordComponent,
    },

    {
      path: "/start",
      name: "start",
      component: Start,
    },
    {
      path: "/home",
      name: "home",
      icon: "mdi-calendar",
      color: "orange",
      component: Home,
    },
    {
      path: "/home-read-only",
      name: "homeReadOnly",
      component: HomeReadOnly,
    },
    {
      path: "/home-check-contract",
      name: "homeCheckContract",
      component: HomeCheckContract,
    },
    {
      path: "/cryptologin",
      name: "cryptologin",
      component: CryptoLogin,
    },
    {
      path: "/dashboard",
      name: "dashboard",
      icon: "mdi-monitor-dashboard",
      color: "blue",
      component: Dashboard,
    },
    {
      path: "/dsgvo",
      name: "dsgvo",
      color: "deep-orange",
      component: Dsgvo,
    },
    {
      path: "/about",
      name: "about",
      color: "deep-orange",
      component: About,
    },
    // {
    //   path: "/site/:site",
    //   name: "site",
    //   component: CryptoLogin,
    // },
    {
      path: "/site/:site/name/:site_name",
      name: "site",
      component: CryptoLogin,
    },
    {
      path: "/site/:site/machine/:machine",
      name: "machine",
      component: CryptoLogin,
    },
    {
      path: "/site/:site/door/:door",
      name: "door",
      component: CryptoLogin,
    },
    {
      path: "/",
      component: CryptoLogin,
    },
  ],
});
