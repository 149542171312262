<template>
  <div class="lock" v-if="mySelf">
    <v-card flat lazy>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers_lock"
            :items="Locks"
            sort-by="name"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{ $t("Locks") }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemLock"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog
                  v-model="dialog_lock"
                  max-width="500px"
                  v-if="editedItemLock"
                >
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleLock }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemLock.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          v-model="editedItemLock.rentBC"
                          label="rentBC"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemLock.api_token"
                          label="api_token"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemLock.lock_id"
                          label="lock_id"
                        ></v-text-field>
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemLock.brand"
                          label="brand"
                        ></v-text-field>
                        <!-- <v-textarea
                          v-model="editedItemLock.machines"
                          label="machines"
                        ></v-textarea> -->
                        <v-select
                          multiple
                          :items="machines"
                          item-text="Name"
                          item-value="MachineId"
                          label="Maschinenzuordnung"
                          v-model="editedItemLock.machines"
                        >
                        </v-select>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeLock"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="saveLock"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="dialog_qr"
                  max-width="250px"
                  v-if="editedItemLock"
                >
                  <v-card align="center" class="pa-5">
                    <div class="h5">{{ editedItemLock.name }}</div>
                    <vue-qr
                      :text="lockQr"
                      :logoImage="logoQr"
                      :correctLevel="1"
                    ></vue-qr>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.machines="{ item }">
              {{ compMachines(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemLock(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemLock(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.actions_qr="{ item }">
              <v-icon small class="mr-2" @click="printQr(item)"
                >mdi-qrcode</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, addressBCRule } from "../utils/rules";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";

export default {
  name: "lock",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      addressBCRule,

      dialog_delete: false,

      dialog_lock: false,

      headers_lock: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Qr Code", value: "actions_qr", sortable: false },
        { text: "Name", value: "name" },
        { text: "machines", value: "machines" },
        { text: "rentBC", value: "rentBC" },
        { text: "api_token", value: "api_token" },
        { text: "lock_id", value: "lock_id" },
        { text: "brand", value: "brand" },
      ],

      editedIndexLock: -1,
      defaultItemLock: {
        name: "",
        rentBC: "",
        api_token: "",
        lock_id: "",
        brand: "",
        machines: [],
      },
      editedItemLock: {},

      snackbar: false,
      snackbar_text: "",
      logoQr: undefined,
      lockQr: 1,
      dialog_qr: false,
      machines: [],
    };
  },
  async mounted() {
    this.editedItemLock = Object.assign({}, this.defaultItemLock);
    await this.loadMachines();
    await this.getLocks({});
  },
  computed: {
    ...mapState({
      status: ({ lock }) => lock.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Locks: ({ lock }) => lock.Locks,
      LockState: ({ lock }) => lock,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Web3: (web3) => web3.web3,
    }),

    formTitleLock() {
      return this.editedIndexLock === -1 ? "New Lock" : "Edit Lock";
    },
  },
  methods: {
    ...mapActions("lock", [
      "getLocks",
      "createLock",
      "updateLock",
      "deleteLock",
    ]),
    async loadMachines() {
      //console.log("Load Machines ", this.Web3.RentContract);
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          _machines[idx].MachineId = parseInt(idx) + 1;
          if (_machines[idx].Active) {
            this.machines.push(_machines[idx]);
          }
        }
      }
    },
    printQr(item) {
      this.editedIndexLock = this.Locks.indexOf(item);
      this.editedItemLock = Object.assign({}, item);
      this.lockQr =
        "https://leas-e.makerspace.at/#/site/" +
        this.Sites[this.selectedSite].rentBC +
        "/machine/0/door/" +
        "1";
      this.dialog_qr = true;
    },
    createItemLock() {
      this.editedItemLock = Object.assign({}, this.defaultItemLock);
      this.editedItemLock.rentBC = this.Sites[this.selectedSite].rentBC;
      this.editedIndexLock = -1;
      this.dialog_lock = true;
    },

    async editItemLock(item) {
      //console.log("editItem ", item);
      this.editedIndexLock = this.Locks.indexOf(item);
      this.editedItemLock = Object.assign({}, item);
      this.dialog_lock = true;
    },

    async deleteItemLock(item) {
      const index = this.Locks.indexOf(item);
      await this.deleteLock(item._id);
      if (this.LockState.statusDelete == "success") {
        this.Locks.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeLock() {
      this.dialog_lock = false;
      this.$nextTick(() => {
        this.editedItemLock = Object.assign({}, this.defaultItemLock);
        this.editedIndexLock = -1;
      });
    },

    async saveLock() {
      //console.log("saveLock");
      //console.log(this.editedItemLock);
      //this.editedItemLock.machines = this.editedItemLock.machines.split(",");
      if (this.editedIndexLock > -1) {
        await this.updateLock(this.editedItemLock);

        if (this.LockState.statusUpdate == "success") {
          Object.assign(this.Locks[this.editedIndexLock], this.editedItemLock);
        } else {
          this.snackbar_text = "Update failed"; //this.LockState.Lock.message;
          this.snackbar = true;
        }
      } else {
        await this.createLock(this.editedItemLock);
        if (this.LockState.statusCreate == "success") {
          this.Locks.push(this.editedItemLock);
        } else {
          this.snackbar_text = "Create failed"; //this.LockState.Lock.message;
          this.snackbar = true;
        }
      }

      this.closeLock();
    },
    compMachines(item) {
      let arr = [];
      for (let machine of this.machines) {
        if (item.machines.includes(machine.MachineId)) {
          arr.push(machine.Name);
        }
      }
      return arr;
    },
  },
  watch: {},
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>