import api from "../../../api";

export default {
  async getPaypal({ commit }, query) {
    commit("REQUEST_PAYPAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_PAYPAL",
        await api.getPaypal(query)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_PAYPAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deletePaypal({ commit }, id) {
    commit("REQUEST_DELETE_PAYPAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_PAYPAL",
        await api.deletePaypal(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_PAYPAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updatePaypal({ commit }, item ) {
    commit("REQUEST_UPDATE_PAYPAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_PAYPAL",
        await api.updatePaypal(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_PAYPAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createPaypal({ commit }, item ) {
    commit("REQUEST_CREATE_PAYPAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_PAYPAL",
        await api.createPaypal( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_PAYPAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async openPaypal({ commit }, item ) {
    commit("REQUEST_UPDATE_PAYPAL");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_PAYPAL",
        await api.openPaypal(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_PAYPAL");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
