<template>
  <div class="certificate" v-if="mySelf">
    <v-card flat>
      <v-row>
         <v-col
              cols="8"
              sm="4"
              md="3"
              lg="3"
              xl="2"
              class="text-center pa-0 pt-4"
              v-if="mySelf.roles.includes('admin')"
            >
              <v-text-field
                dense
                type="date"
                v-model="deleteDate"
                label="Delete all Certs before"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" lg="3" xl="2" class="pa-0" v-if="mySelf.roles.includes('admin')">
              <div v-if="!deleteWanted">
                <v-btn
                  
                  class="ma-4"
                  small
                  elevation="1"
                  @click="
                    deleteWanted = true;
                  "
                  >Delete to Date</v-btn
                >
              </div>
              <div v-else>
                <v-btn
                  class="ma-4"
                  small
                  elevation="1"
                  @click="
                    clickDeleteCertificates(deleteDate);
                    deleteWanted = false;
                  "
                  >Are you sure?</v-btn
                >
                <v-btn
                  class="ma-4"
                  small
                  elevation="1"
                  color = "red"
                  @click="
                    deleteWanted = false;
                  "
                  >Cancel</v-btn
                >
              </div>
            </v-col>
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <div v-if="loading_data">
            {{processMsg}}
          </div>
          <v-data-table
            v-else
            :headers="headers_certificate"
            :items="Certificates"
            sort-by="createdAt"
            sort-desc
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{$t("Certificates")}}</v-toolbar-title>
                <!--<v-divider class="mx-4" inset vertical></v-divider>-->
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:header.actions_send="{ item }">
              <div v-if="(mySelf.roles.includes('admin') || mySelf.roles.includes('provider'))">Sende Email</div>
            </template>
            <template v-slot:item.number="{ item }">
              <div align="right"> {{ item.prefix + item.number}}</div>
            </template>
            <template v-slot:item.issuer_address="{ item }">
               {{getIssuer(item)}}
            </template>
              <template v-slot:item.recipient_address="{ item }">
                {{getRecipient(item)}}
            </template>
             <template v-slot:item.issue_date="{ item }">
              {{ item.issue_date | moment("DD.MM.YYYY") }}
            </template>
            <template v-slot:item.gross="{ item }">
              {{ parseFloat(item.gross).toFixed(2)}}
            </template>
            <template v-slot:item.send="{ item }">
              {{ item.send | moment("DD.MM.YYYY HH:mm") }}
            </template>
            <template v-slot:item.actions_pdf="{ item }">
              <v-icon  small @click="showCertificatePdf(item)">picture_as_pdf</v-icon>
            </template>
            <template v-slot:item.actions_send="{ item }">
              <v-icon v-if="(mySelf.roles.includes('admin') || mySelf.roles.includes('provider'))" small @click="clickSendCertificate(item)">email</v-icon>
            </template>
            <!--<template v-slot:item.birthday="{ item }"><div  class="caption">{{item.birthday}} </div></template>-->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, emailRule } from "../utils/rules";
import { array2ArrayObject} from "../functions/utils";
import { ethers, errors } from "ethers";

export default {
  name: "certificates",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      emailRule,

      dialog_delete: false,
    
      dialog_certificate: false,
      dialog_certificate_site: false,
      headers_certificate: [
        //{ text: "Prefix", value: "prefix" },
        { text: "Öffnen", value: "actions_pdf", sortable: false , align: "center"},
        { text: "Sende Email", value: "actions_send", sortable: false , align: "center"},
        { text: "Nummer", value: "contract_number" },
        { text: "Datum", value: "createdAt"},
        { text: "Empfänger", value: "recipient_address" , align: "center" },

      ],
      editedIndexCertificate: -1,
      
      defaultItemCertificate: {
        certificate_nr: "",
        provider_address: "",
        customer_address: "",
        pdf: "",
        send_to_customer: null
      },
      editedItemCertificate: this.defaultItemCertificate,
      snackbar: false,
      snackbar_text: "",

      //allSites: [],
      loading_data: true,
 
      organizations: [],
      deleteDate: null,
      deleteWanted: false,
      processMsg: ""
    };
  },
  async mounted() {
    //const sites = await this.Web3.SitesRegistryContract.getAllSites();
    //this.allSites = array2ArrayObject(sites);
    //console.log(this.allSites);
    //console.log(this.Sites[this.selectedSite]);
    
    //await this.getCertificates();
    // console.log("Certificate loaded ", this.Certificates);
    /*for (let certificate of this.Certificates) {
      if (!_.isUndefined(certificate.contract_number)) {
      console.log("Loading Contract ", certificate.contract_number);
        let _contract = await this.Web3.RentContract.getContract(certificate.contract_number);
        certificate.contract = Array2Object(_contract);
      }
    }*/
   // this.editedItemCertificate = Object.assign({}, this.defaultItemCertificate);

    this.Init();
   

  },
  computed: {
    ...mapState({
      status: ({ certificate }) => certificate.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Certificates: ({ certificate }) => certificate.Certificates,
      CertificateState: ({ certificate }) => certificate,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
      MachineContracts: ({ machineContract }) =>
        machineContract.MachineContracts,
      MachineContractState: ({ machineContract }) => machineContract,
    }),
    
    formTitleCertificate() {
      return this.editedIndexCertificate === -1 ? "New Certificate" : "Edit Certificate";
    },
  },
  methods: {
    ...mapActions("certificate", [
      "getCertificates",
      "sendCertificate",
      "delCertificates"
    ]),
     ...mapActions("user", [
      "getUsers",
    ]),
    ...mapActions("machineContract", ["getMachineContracts"]),
    async Init() {
      //this.loading_data = true;
      this.processMsg = "Init";
      await this.getCertificates(this.Sites[this.selectedSite]);
      this.processMsg = "Get Cert Finished";
      this.organizations = array2ArrayObject(await this.Web3.SitesRegistryContract.getAllOrganisations());
      this.processMsg = "Get Orgas Finished";
      if (this.mySelf.roles.includes('admin') || this.mySelf.roles.includes('provider')) {
        await this.getUsers({});
        this.processMsg = "Get Users Finished";
        await this.getMachineContracts();
        this.processMsg = "Get MC Finished";
      } 
      this.processMsg = "";
      this.loading_data = false;
    },
    async clickDeleteCertificates(deleteDate) {
      this.loading_data = true;
      this.processMsg = "Start Delete";
      await this.delCertificates({date: deleteDate, rentBC: this.Sites[this.selectedSite].rentBC});    
      this.processMsg = "Get Cert";
      await this.getCertificates(this.Sites[this.selectedSite]);
      
      this.processMsg = "";
      this.loading_data = false;
      //console.log("SEND 1")
      //item.send = this.$moment().toDate();
    },
    clickSendCertificate(item) {
      this.sendCertificate(item._id);
      console.log("SEND 1")
      //item.send = this.$moment().toDate();
    },
    getIssuer(item) {
      for (let _org of this.organizations) {
        if (item.issuer_address.toLowerCase() == _org.addressBC.toLowerCase()) {
          return _org.name;
        }
      }
    },
    getRecipient(item) {
      
      for (let _org of this.organizations) {
        if (item.recipient_address.toLowerCase() == _org.addressBC.toLowerCase()) {
          return _org.name;
        }
      }
     
      if (this.mySelf.roles.includes('admin') || this.mySelf.roles.includes('provider')) {   
        let _mc = _.find(this.MachineContracts, {contractNr: item.contract_number, rentBC: this.Sites[this.selectedSite].rentBC.toLowerCase()});
        let _user = _.find(this.Users, {_id: _mc.owner_id});
        if (_user.firstname) {
          return _user.firstname + ' ' + _user.lastname;
        } else {
          return item.recipient_address.toLowerCase();
        }
      
      } else {
        return item.recipient_address.toLowerCase();
      }
      
    },
    /*comp_site (site) {
      try {
        let site_array = ""
        site.forEach(obj => {
          let _site = _.find(this.allSites, s => {return s.rentBC.toLowerCase() == obj.address.toLowerCase()});
          if (_site && obj.active) {
            site_array += _site.name + ", ";
          }
        })      
        return site_array
      }
      catch {
        return ""
      }
    },*/
    
    async editItemCertificate(item) {
      //console.log("editItem");
      this.editedIndexCertificate = this.Certificates.indexOf(item);
      this.editedItemCertificate = Object.assign({}, item);
      this.dialog_certificate = true;
    },

    async editItemCertificateSite(item) {
      //console.log("editItem");
      this.editedIndexCertificate = this.Certificates.indexOf(item);
      this.editedItemCertificate = Object.assign({}, item);
      this.dialog_certificate_site = true;
    },

    async deleteItemCertificate(item) {
      const index = this.Certificates.indexOf(item);
      await this.deleteCertificate(item._id);
      if (this.CertificateState.statusDelete == "success") {
        this.Certificates.splice(index, 1);
      } else {
        this.snackbar_text = "Already deleted";
        this.snackbar = true;
      }
    },

    closeCertificate() {
      this.dialog_certificate = false;
      this.$nextTick(() => {
        this.editedItemCertificate = Object.assign({}, this.defaultItem);
        this.editedIndexCertificate = -1;
      });
    },

    closeCertificateSite() {
      this.dialog_certificate_site = false;
      this.$nextTick(() => {
        this.editedItemCertificate = Object.assign({}, this.defaultItem);
        this.editedIndexCertificate = -1;
      });
    },
    async saveCertificate() {
      //console.log("saveCertificate");

      if (this.editedIndexCertificate > -1) {
        await this.updateCertificate(this.editedItemCertificate);

        if (this.CertificateState.statusUpdate == "success") {
          this.editedItemCertificate._id = this.CertificateState.Certificate._id;
          this.editedItemCertificate.updatedAt = this.$moment(
            this.CertificateState.Certificate.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          Object.assign(this.Certificates[this.editedIndexCertificate], this.editedItemCertificate);
        } else {
          this.snackbar_text = this.CertificateState.Certificate.message;
          this.snackbar = true;
        }
      } else {
        await this.createCertificate(this.editedItemCertificate);
        if (this.CertificateState.statusCreate == "success") {
          this.editedItemCertificate._id = this.CertificateState.Certificate._id;
          this.editedItemCertificate.roles = ["certificate"];
          this.editedItemCertificate.activated = true;
          this.editedItemCertificate.updatedAt = this.$moment(
            this.CertificateState.Certificate.updatedAt
          ).format("DD-MM-YYYY HH:mm");
          this.Certificates.push(this.editedItemCertificate);
        } else {
          this.snackbar_text = this.CertificateState.Certificate.message;
          this.snackbar = true;
        }
      }
      this.closeCertificate();
    },
    showCertificatePdf (item) {
        var download_url = "/api/invoice/" + item.pdf + ".pdf";
        var x=new XMLHttpRequest();
        x.open("GET", download_url, true);
        x.setRequestHeader("Authorization", localStorage.getItem("Authorization") );
        x.responseType = 'blob';
        x.onload=function(e){
          let file = new File([x.response], item.prefix+item.number+".pdf",{type: "application/pdf"})
          var objectURL = window.URL.createObjectURL(file);
          window.open(objectURL);
          }
        x.send();
    }    
  },
  watch: {
  
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
   font-size: 10px !important;
}
</style>