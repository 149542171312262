<template>
  <div class="site" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-data-table
            v-if="!loading_data"
            :headers="headers_site"
            :items="sites"
            sort-by="lastname"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            item-class="caption"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Sites</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="green"
                  left
                  fab
                  small
                  dark
                  class="mb-2"
                  @click="createItemSite"
                >
                  <v-icon> add </v-icon>
                </v-btn>
                <v-dialog v-model="dialog_site" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{ formTitleSite }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        ref="form"
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        <v-text-field
                          :rules="requiredRule"
                          required
                          v-model="editedItemSite.name"
                          label="Name"
                        ></v-text-field>
                        <v-text-field
                          
                          :rules="addressBCRule"
                          required
                          v-model="editedItemSite.owner"
                          label="Provider"
                        ></v-text-field>
                        <v-text-field
                          :disabled="editedIndexSite > -1"
                          :rules="addressBCRule"
                          required
                          v-model="editedItemSite.invoiceBC"
                          label="invoiceBC"
                        ></v-text-field>
                        <v-text-field
                          :disabled="editedIndexSite > -1"
                          :rules="addressBCRule"
                          required
                          v-model="editedItemSite.rentBC"
                          label="rentBC"
                        ></v-text-field>
                        <v-text-field
                          :rules="addressBCRule"
                          required
                          v-model="editedItemSite.tokenBC"
                          label="tokenBC"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeSite"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!valid"
                        color="blue darken-1"
                        text
                        @click="validate"
                        >Save</v-btn
                      >
                      <v-btn v-else color="blue darken-1" text @click="saveSite"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItemSite(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItemSite(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule, addressBCRule } from "../utils/rules";
import { array2ArrayObject, extractRevertReason } from "../functions/utils";
import { ethers, errors } from "ethers";
import {rentErrors} from "../utils/errors";

export default {
  name: "site",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      addressBCRule,

      dialog_delete: false,
      dialog_site: false,

      headers_site: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "Name", value: "name" },
        { text: "owner", value: "owner" },
        { text: "invoiceBC", value: "invoiceBC" },
        { text: "rentBC", value: "rentBC" },
        { text: "tokenBC", value: "tokenBC" },
        
      ],

      editedIndexSite: -1,

      defaultItemSite: {
        name: "",
        owner: "",
        invoiceBC: "",
        rentBC: "",
        tokenBC: "",
      },
      editedItemSite: this.defaultItemSite,
      snackbar: false,
      snackbar_text: "",

      sites: [],
      loading_data: true
    };
  },
  async mounted() {
    const sites = await this.Web3.SitesRegistryContract.getAllSites();
    this.sites = array2ArrayObject(sites);
    this.editedItemSite = Object.assign({}, this.defaultItemSite);
    this.loading_data = false;
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      Web3: (web3) => web3.web3,
    }),

    formTitleSite() {
      return this.editedIndexSite === -1 ? "New Site" : "Edit Site";
    },
  },
  methods: {
    async createItemSite() {
      this.editedItemSite = Object.assign({}, this.defaultItemSite);
      this.editedIndexSite = -1;
      this.dialog_site = true;
    },

    async editItemSite(item) {
      // console.log("editItem ", item);
      this.editedIndexSite = this.sites.indexOf(item);
      this.editedItemSite = Object.assign({}, item);
      this.dialog_site = true;
    },

    async deleteItemSite(item) {
      const index = this.sites.indexOf(item);
      try {
        await this.Web3.SitesRegistryContract.delSite(item.rentBC);
        this.sites.splice(index, 1);
      } catch (err) {
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
    },

    closeSite() {
      this.dialog_site = false;
      this.$nextTick(() => {
        this.editedItemSite = Object.assign({}, this.defaultItemSite);
        this.editedIndexSite = -1;
      });
      this.resetValidation();
    },

    async saveSite() {
      try {
        this.editedItemSite.owner = ethers.utils.getAddress(this.editedItemSite.owner);
        this.editedItemSite.invoiceBC = ethers.utils.getAddress(
          this.editedItemSite.invoiceBC
        );
        this.editedItemSite.rentBC = ethers.utils.getAddress(
          this.editedItemSite.rentBC
        );
        this.editedItemSite.tokenBC = ethers.utils.getAddress(
          this.editedItemSite.tokenBC
        );

        if (this.editedIndexSite > -1) {
          try {
            await this.Web3.SitesRegistryContract.changeSite(
              this.editedItemSite
            );
            Object.assign(
              this.sites[this.editedIndexSite],
              this.editedItemSite
            );
          } catch (err) {
            console.log(err);
            this.snackbar_text = extractRevertReason(err);
            this.snackbar = true;
          }
        } else {
          try {
            await this.Web3.SitesRegistryContract.addSite(this.editedItemSite);
            this.sites.push(this.editedItemSite);
          } catch (err) {
            this.snackbar_text = extractRevertReason(err);
            this.snackbar = true;
          }
        }

        this.closeSite();
      } catch (err) {
        this.snackbar_text =extractRevertReason(err);
        this.snackbar = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    
  },
  watch: {},
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>