<template>
  <div class="dashboard">
    <v-row>
      <v-col>
        <SystemChecks></SystemChecks>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="checkUser('user')">
        <v-row>
          <v-col cols="12" md="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="10">
            <Invoice class="mr-2" v-if="selectedItem == 0"></Invoice>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="checkUser('superUser')">
        <v-row>
          <v-col cols="12" md="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <!-- <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <!-- <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
                <!-- <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="10">
            <!-- <Report class="mr-2" v-if="selectedItem == 0"></Report> -->
            <!-- <UserSuperUser class="mr-2" v-if="selectedItem == 1"></UserSuperUser> -->
            <!-- <Invoice class="mr-2" v-if="selectedItem == 2"></Invoice>       -->
          </v-col>
        </v-row>
      </v-col>
      <!-- A: Sigi -->
      <v-col
        cols="12"
        v-else-if="
          checkUser('provider') &&
          mySelf.crypto_address == '0xef9300c0c1e7f311c93b59d5f4779531ccaadfe8'
        "
      >
        <v-row>
          <v-col cols="12" md="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="10">
            <Report class="mr-2" v-if="selectedItem == 0"></Report>
            <User class="mr-2" v-if="selectedItem == 1"></User>
            <!-- <Invoice class="mr-2" v-if="selectedItem == 2"></Invoice>       -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="checkUser('machineOwner')">
        <v-row>
          <v-col cols="12" md="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="10">
            <Report class="mr-2" v-if="selectedItem == 0"></Report>
            <Invoice class="mr-2" v-if="selectedItem == 1"></Invoice>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="checkUser('provider')">
        <v-row>
          <v-col cols="12" md="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Overview") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Contracts") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Space Admin</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("Organizations")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Resources") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Locks") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Buyable") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("PayPal") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="10">
            <Report class="mr-2" v-if="selectedItem == 0"></Report>
            <VueCalendarResources
              :machine="false"
              class="mr-2"
              v-if="selectedItem == 1"
            ></VueCalendarResources>
            <MachineContract
              class="mr-2"
              v-if="selectedItem == 2"
            ></MachineContract>
            <Space class="mr-2" v-if="selectedItem == 3"></Space>
            <User class="mr-2" v-if="selectedItem == 4"></User>
            <Organization class="mr-2" v-if="selectedItem == 5"></Organization>
            <Invoice class="mr-2" v-if="selectedItem == 6"></Invoice>
            <Machine
              :buyable="false"
              class="mr-2"
              v-if="selectedItem == 7"
            ></Machine>
            <Lock class="mr-2" v-if="selectedItem == 8"></Lock>

            <Machine
              :buyable="true"
              class="mr-2"
              v-if="selectedItem == 9"
            ></Machine>
            <PayPal class="mr-2" v-if="selectedItem == 10"></PayPal>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else-if="checkUser('admin')">
        <v-row>
          <v-col cols="2">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Overview") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Users") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("Organizations")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Smart Contracts</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Sites Registry</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Invoices") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Resources") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Space Admin</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Locks") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Contracts") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("Buyable") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("PayPal") }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("Certificate")
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="10">
            <Report class="mr-2" v-if="selectedItem == 0"></Report>
            <VueCalendarResources
              :machine="false"
              class="mr-2"
              v-if="selectedItem == 1"
            ></VueCalendarResources>
            <User class="mr-2" v-if="selectedItem == 2"></User>
            <Organization class="mr-2" v-if="selectedItem == 3"></Organization>
            <Contract class="mr-2" v-if="selectedItem == 4"></Contract>
            <Site class="mr-2" v-if="selectedItem == 5"></Site>
            <Invoice class="mr-2" v-if="selectedItem == 6"></Invoice>
            <Machine
              :buyable="false"
              class="mr-2"
              v-if="selectedItem == 7"
            ></Machine>
            <Space class="mr-2" v-if="selectedItem == 8"></Space>
            <Lock class="mr-2" v-if="selectedItem == 9"></Lock>
            <MachineContract
              class="mr-2"
              v-if="selectedItem == 10"
            ></MachineContract>
            <Machine
              :buyable="true"
              class="mr-2"
              v-if="selectedItem == 11"
            ></Machine>
            <PayPal class="mr-2" v-if="selectedItem == 12"></PayPal>
            <Certificate class="mr-2" v-if="selectedItem == 13"></Certificate>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import User from "./User.vue";
import Site from "./Site.vue";
import Contract from "./Contract.vue";
import Organization from "./Organization.vue";
import Invoice from "./Invoice.vue";
import Machine from "./Machine.vue";
import Space from "./Space.vue";
import Lock from "./Lock.vue";
import MachineContract from "./MachineContract.vue";
import Report from "./Report.vue";
import PayPal from "./PayPal.vue";
import VueCalendarResources from "./VueCalendarResources.vue";
import UserSuperUser from "./UserSuperUser.vue";
import SystemChecks from "./SystemChecks.vue";
import Certificate from "./Certificate.vue";

export default {
  name: "Dashboard",
  components: {
    User,
    Site,
    Contract,
    Organization,
    Invoice,
    Machine,
    Space,
    Lock,
    MachineContract,
    Report,
    PayPal,
    VueCalendarResources,
    UserSuperUser,
    SystemChecks,
    Certificate,
  },
  data() {
    return {
      selectedItem: 0,
    };
  },
  mounted() {
    if (!this.Wallet) {
      //console.log("No Wallet");
      this.logout();
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    checkUser(role) {
      return this.mySelf.roles.includes(role);
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      mySelf: ({ auth }) => auth.mySelf,
      Wallet: ({ web3 }) => web3.Wallet,
      WalletBalance: ({ web3 }) => web3.Balance,
      WalletAddress: ({ web3 }) => web3.Address,
      selectedSite: ({ user }) => user.selectedSite,
    }),
  },
  watch: {
    selectedSite(value) {
      this.selectedItem = -1;
    },
    selectedItem(_new, _old) {
      //console.log(_new, " ", _old);
      if (_.isUndefined(_new)) {
        this.selectedItem = _old;
      }
    },
  },
};
</script>
