import types from "./types";

const REQUEST_SIGNUP = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_SIGNUP = state => {
  state.status = "success";
};

const FAILURE_REQUEST_SIGNUP = state => {
  state.status = "failure";
};

const REQUEST_UPDATE_USER = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_USER = (state, { body }) => {
  state.statusUpdate = "success";
  state.User = body;
};

const FAILURE_REQUEST_UPDATE_USER = (state, {body}) => {
  state.statusUpdate = "failure";
  state.User = body;
};

const REQUEST_USERS = state => {
  state.statusUsers = "fetching";
};

const SUCCESS_REQUEST_USERS = (state, {body}) => {
  state.Users = body;
  state.statusUsers = "success";
};

const FAILURE_REQUEST_USERS = state => {
  state.statusUsers = "failure";
};


const REQUEST_USER = state => {
  state.statusUser = "fetching";
};

const SUCCESS_REQUEST_USER = (state, {body}) => {
  state.User = body;
  state.statusUser = "success";
};

const FAILURE_REQUEST_USER = state => {
  state.statusUser = "failure";
};

const REQUEST_CREATE_USER = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_USER = (state, { body }) => {
  state.statusCreate = "success";
  state.User = body;
};

const FAILURE_REQUEST_CREATE_USER = (state, { body }) => {
  state.statusCreate = "failure";
  state.User = body;
};

const REQUEST_CREATE_USER_AUTH = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_USER_AUTH = (state, { body }) => {
  state.statusCreate = "success";
  state.User = body;
};

const FAILURE_REQUEST_CREATE_USER_AUTH = (state, { body }) => {
  state.statusCreate = "failure";
  state.User = body;
};

const REQUEST_CREATE_USER_APP = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_USER_APP = (state, { body }) => {
  state.statusCreate = "success";
  state.User = body;
};

const FAILURE_REQUEST_CREATE_USER_APP = (state, { body }) => {
  state.statusCreate = "failure";
  state.User = body;
};

export default {
  REQUEST_SIGNUP,
  SUCCESS_REQUEST_SIGNUP,
  FAILURE_REQUEST_SIGNUP,

  REQUEST_CREATE_USER,
  SUCCESS_REQUEST_CREATE_USER,
  FAILURE_REQUEST_CREATE_USER,

  REQUEST_CREATE_USER_AUTH,
  SUCCESS_REQUEST_CREATE_USER_AUTH,
  FAILURE_REQUEST_CREATE_USER_AUTH,
  
  REQUEST_CREATE_USER_APP,
  SUCCESS_REQUEST_CREATE_USER_APP,
  FAILURE_REQUEST_CREATE_USER_APP,

  REQUEST_UPDATE_USER,
  SUCCESS_REQUEST_UPDATE_USER,
  FAILURE_REQUEST_UPDATE_USER,

 

  [types.REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "fetching";
  },
  [types.SUCCESS_REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "success";
  },
  [types.FAILURE_REQUEST_ACTIVATE_ACCOUNT]: state => {
    state.statusAccountActivation = "failure";
  },
  [types.REQUEST_FORGOT_PASSWORD]: state => {
    state.statusForgotPassword = "fetching";
  },
  [types.SUCCESS_REQUEST_FORGOT_PASSWORD]: (state, resp) => {
    state.statusForgotPassword = "success";
  },
  [types.FAILURE_REQUEST_FORGOT_PASSWORD]: state => {
    state.statusForgotPassword = "failure";
  },
  [types.REQUEST_RESET_PASSWORD]: state => {
    state.statusResetPassword = "fetching";
  },
  [types.SUCCESS_REQUEST_RESET_PASSWORD]: (state, resp) => {
    state.statusResetPassword = "success";
  },
  [types.FAILURE_REQUEST_RESET_PASSWORD]: state => {
    state.statusResetPassword = "failure";
  },

  REQUEST_USERS,
  SUCCESS_REQUEST_USERS,
  FAILURE_REQUEST_USERS,

  REQUEST_USER,
  SUCCESS_REQUEST_USER,
  FAILURE_REQUEST_USER
};
