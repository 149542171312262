import types from "./types";

const REQUEST_REGISTER_WEB3 = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_REGISTER_WEB3 = async (state) => {
  state.status = "success";
};

const FAILURE_REQUEST_REGISTER_WEB3 = state => {
  state.status = "failure";
};


export default {
  REQUEST_REGISTER_WEB3,
  SUCCESS_REQUEST_REGISTER_WEB3,
  FAILURE_REQUEST_REGISTER_WEB3,
};
