<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="7">
        <v-card v-if="statusAccountActivation === 'success'" flat>
          <v-alert type="info" value="1">Email bestätigt</v-alert>
          <v-card-text
            >Deine Email wurde bestätigt. Du erhältst nun eine E-mail, sobald
            Dein Account seitens des Administrators freigeschalten worden
            ist.</v-card-text
          >
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              rounded
              outlined
              color="primary"
              @click="$router.push('/cryptologin')"
              >zum Login</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-card v-else-if="statusAccountActivation === 'failure'" flat>
          <v-alert type="warning" value="1" class="black--text"
            >Account activation message</v-alert
          >
          <v-card-text
            >User does not exists or is already activated.</v-card-text
          >
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              rounded
              outlined
              color="primary"
              @click="$router.push('/cryptologin')"
              >zum Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      statusAccountActivation: ({ user }) => user.statusAccountActivation,
      statusAuth: ({ auth }) => auth.status,
      loading: function () {
        return this.statusAccountActivation === "fetching";
      },
    }),
  },

  mounted() {
    if (this.statusAuth === "success") {
      this.logout();
    } else {
      this.activateAccount({ token: this.$route.params.token });
    }
  },

  watch: {
    statusAccountActivation(val) {
      if (val === "success") {
        // setTimeout(() => {
        //   this.$router.push("/login");
        // }, 3000);
      }
    },
  },

  methods: {
    ...mapActions("user", ["activateAccount"]),
    ...mapActions("auth", ["logout"]),
  },
};
</script>
