import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  Wallet: void 0,
  EtherBalance: 0,
  Address: void 0,
  RentContract: void 0,
  RentContractAbi: void 0,
  RentContractAddress: void 0,
  TokenContract: void 0,
  TokenContractAbi: void 0,
  TokenContractAddress: void 0,
  SitesRegistryContract: void 0,
  SitesRegistryContractAbi: void 0,
  SitesRegistryContractAddress: void 0,
  InvoiceContract: void 0,
  InvoiceContractAbi: void 0,
  InvoiceContractAddress: void 0,
  TokenBalance: void 0,
  TokenName: void 0,
  TokenSymbol: void 0,
  actualBlock: void 0
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
