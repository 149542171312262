<template>
  <div class="space" v-if="mySelf">
    <v-card flat>
      <v-row wrap>
        <v-col cols="12" md="6" lg="4" class="caption" align="center">
          <v-card class="pa-5 d-flex align-center" min-height="200px">
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  class="caption"
                  v-model="insurance"
                  label="insurance address"
                ></v-text-field>
                <v-text-field
                  dense
                  class="caption"
                  v-model="insuranceRate"
                  label="insuranceRate in Promille"
                ></v-text-field>
                <v-btn
                  small
                  :loading="loadingInsurance"
                  v-on:click="setInsurance()"
                  >Set Insurance</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="caption" align="center">
          <v-card class="pa-5 d-flex align-center" min-height="200px">
            <v-row>
              <v-col>
                <v-text-field
                  disabled
                  dense
                  class="caption"
                  v-model="royalty"
                  label="royalty address"
                ></v-text-field>
                <v-text-field
                  disabled
                  dense
                  class="caption"
                  v-model="royaltyRate"
                  label="royaltyRate in Promille"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="caption" align="center">
          <v-card class="pa-5 d-flex align-center" min-height="200px">
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  class="caption"
                  v-model="voucherPrefix"
                  label="Voucher Prefix"
                ></v-text-field>

                <v-btn
                  small
                  :loading="loadingVoucherPrefix"
                  v-on:click="setVoucherPrefix()"
                  >Set Voucher Prefix</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4" align="center">
          <v-card class="pa-5 d-flex align-center" min-height="200px">
            <v-row>
              <v-col>
                <!-- class="text-center">-->
                <v-select
                  dense
                  solo
                  background-color="secondary"
                  v-model="spaceOpenDay"
                  :items="days"
                  item-text="name"
                  item-value="idx"
                  color="white"
                  v-on:change="setTimeSlots"
                >
                </v-select>
                <v-text-field
                  :disabled="
                    spaceWholeDay[spaceOpenDay] || spaceDayClosed[spaceOpenDay]
                  "
                  dense
                  type="time"
                  v-model="spaceOpenStart"
                  label="Start"
                ></v-text-field>
                <v-text-field
                  :disabled="
                    spaceWholeDay[spaceOpenDay] || spaceDayClosed[spaceOpenDay]
                  "
                  dense
                  type="time"
                  v-model="spaceOpenEnd"
                  label="End"
                >
                </v-text-field>
                <v-checkbox
                  :disabled="spaceDayClosed[spaceOpenDay]"
                  class="pt-0"
                  dense
                  color="blue"
                  v-model="spaceWholeDay[spaceOpenDay]"
                  label="Durchgehend geöffnet"
                >
                </v-checkbox>
                <v-checkbox
                  :disabled="spaceWholeDay[spaceOpenDay]"
                  class="pt-0"
                  dense
                  color="blue"
                  v-model="spaceDayClosed[spaceOpenDay]"
                  label="Geschlossen"
                >
                </v-checkbox>
                <v-btn
                  small
                  v-on:click="setSpaceOpenTime()"
                  :loading="loadingSpaceOpenTime"
                  >Tag speichern</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" lg="8" align="center">
          <v-card class="pa-5 d-flex align-center">
            <v-row>
              <v-col>
                SignUp Link: <br /><br />
                <div class="caption">
                  {{ regLink }}
                </div>
                <br />
                <div @click="dialog_qr = true">
                  <vue-qr
                    width="150px"
                    :text="regLink"
                    :correctLevel="3"
                  ></vue-qr>
                </div>
                <br /><br />
                MQTT Key: {{ addressLink.slice(0, 8).toLowerCase() }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_qr" max-width="250px">
      <v-card align="center" class="pa-5">
        <div class="h5">LEAS-E Link</div>
        <div class="h5">{{ Sites[selectedSite].name }}</div>
        <vue-qr :text="regLink" :correctLevel="3"></vue-qr>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { ethers, errors } from "ethers";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
} from "../functions/utils";
import { rentErrors } from "../utils/errors";

export default {
  name: "space",
  components: {},
  data() {
    return {
      snackbar: false,
      snackbar_text: "",
      dialog_qr: false,
      insurance: "",
      insuranceRate: 0,
      royalty: "",
      royaltyRate: 0,
      voucherPrefix: "",
      spaceOpenStart: 0,
      spaceOpenEnd: 0,
      spaceOpenTime: [],
      loadingSpaceOpenTime: false,
      loadingInsurance: false,
      loadingVoucherPrefix: false,
      spaceWholeDay: [false, false, false, false, false, false, false],
      spaceOpenDay: 0,
      spaceDayClosed: [false, false, false, false, false, false, false],
      days: [
        { idx: 0, name: "Montag" },
        { idx: 1, name: "Dienstag" },
        { idx: 2, name: "Mittwoch" },
        { idx: 3, name: "Donnerstag" },
        { idx: 4, name: "Freitag" },
        { idx: 5, name: "Samstag" },
        { idx: 6, name: "Sonntag" },
      ],
      regLink: "",
    };
  },
  async mounted() {
    this.insurance = await this.Web3.InvoiceContract.InsuranceBC();
    this.insuranceRate = await this.Web3.InvoiceContract.InsuranceRate();
    this.voucherPrefix = await this.Web3.InvoiceContract.VoucherPrefix();
    this.spaceOpenTime = await this.Web3.RentContract.getAllTimeSlots(0);
    //console.log(this.spaceOpenTime)
    this.royalty = await this.Web3.InvoiceContract.RoyaltyBC();
    this.royaltyRate = await this.Web3.InvoiceContract.RoyaltyRate();
    this.setTimeSlots();
    let site_name = this.Sites[this.selectedSite].name;
    //site_name = site_name.replace(" ", "_");
    site_name = site_name.replace(" ", "");
    this.regLink =
      "https://leas-e.makerspace.at/#/site/" +
      this.addressLink +
      "/name/" +
      site_name;
  },
  computed: {
    ...mapState({
      mySelf: ({ auth }) => auth.mySelf,
      Web3: (web3) => web3.web3,
      selectedSite: ({ user }) => user.selectedSite,
      Sites: ({ user }) => user.Sites,
      actualBlock: (web3) => web3.web3.actualBlock,
    }),
    addressLink() {
      let siteAddress = this.Sites[this.selectedSite].rentBC;
      return siteAddress.slice(2, siteAddress.length);
    },
  },
  methods: {
    setTimeSlots() {
      let time = this.spaceOpenTime[this.spaceOpenDay][0];
      //console.log(time)
      if (!time) {
        this.spaceDayClosed[this.spaceOpenDay] = true;
      } else if (time.stop == 1440) {
        this.spaceWholeDay[this.spaceOpenDay] = true;
      } else {
        let start = time.start;
        let end = time.stop;
        //console.log(start, time.stop)
        let hour = parseInt(start / 60);

        this.spaceOpenStart =
          hour.toString().padStart(2, "0") +
          ":" +
          (start - hour * 60).toString().padStart(2, "0");
        hour = parseInt(end / 60);
        this.spaceOpenEnd =
          hour.toString().padStart(2, "0") +
          ":" +
          (end - hour * 60).toString().padStart(2, "0");
        this.spaceDayClosed[this.spaceOpenDay] = false;
        this.spaceWholeDay[this.spaceOpenDay] = false;
      }
    },
    // getOpenDay() {

    // },
    async setSpaceOpenTime() {
      //console.log("setSpaceOpenTime")
      this.loadingSpaceOpenTime = true;
      let start = 0;
      let end = 0;
      if (this.spaceWholeDay[this.spaceOpenDay] == true) {
        end = 1440;
      } else {
        let _time = this.spaceOpenStart.split(":");
        start = parseInt(_time[0]) * 60 + parseInt(_time[1]);
        _time = this.spaceOpenEnd.split(":");
        end = parseInt(_time[0]) * 60 + parseInt(_time[1]);
      }
      //for (let i = 0; i < 7; i++) {
      if (this.spaceDayClosed[this.spaceOpenDay] == true) {
        //console.log("Del Time Slot")
        try {
          await this.Web3.RentContract.delTimeSlot(0, this.spaceOpenDay, 0);
        } catch (err) {
          this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
          this.snackbar = true;
          this.loadingSpaceOpenTime = false;
        }
      } else {
        let time = this.spaceOpenTime[this.spaceOpenDay][0];
        if (!time) {
          try {
            //console.log("Add Time Slot")
            await this.Web3.RentContract.addTimeSlot(
              0,
              this.spaceOpenDay,
              start,
              end
            );
          } catch (err) {
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
            this.loadingSpaceOpenTime = false;
          }
        } else {
          try {
            //console.log("Change Time Slot")
            await this.Web3.RentContract.changeTimeSlot(
              0,
              this.spaceOpenDay,
              0,
              start,
              end
            );
          } catch (err) {
            this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
            this.snackbar = true;
            this.loadingSpaceOpenTime = false;
          }
        }
      }

      //console.log(await this.Web3.RentContract.getAllTimeSlots(0));
    },
    async setInsurance() {
      this.loadingInsurance = true;
      try {
        await this.Web3.InvoiceContract.setInsurance(
          this.insurance,
          this.insuranceRate
        );
      } catch (err) {
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
      this.loadingInsurance = false;
    },
    async setVoucherPrefix() {
      this.loadingVoucherPrefix = true;
      try {
        await this.Web3.InvoiceContract.setVoucher(this.voucherPrefix);
      } catch (err) {
        this.snackbar_text = extractRevertReason(err);
        this.snackbar = true;
      }
      this.loadingVoucherPrefix = false;
    },
  },
  watch: {
    async actualBlock() {
      if (this.loadingSpaceOpenTime) {
        this.spaceOpenTime = await this.Web3.RentContract.getAllTimeSlots(0);
        this.loadingSpaceOpenTime = false;
        //console.log(this.spaceOpenTime)
      }
    },
  },
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
.box {
  border: solid 1px red;
}
</style>