const REQUEST_MACHINECONTRACTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_MACHINECONTRACTS = (state, { body }) => {
  state.MachineContracts = body;
  state.status = "success";
};

const FAILURE_REQUEST_MACHINECONTRACTS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_MACHINECONTRACT = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_MACHINECONTRACT = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_MACHINECONTRACT = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_MACHINECONTRACT = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_MACHINECONTRACT = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_MACHINECONTRACT = state => {
  state.statusUpdate = "failure";
};


const REQUEST_CREATE_MACHINECONTRACT = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_MACHINECONTRACT = (state, { body }) => {
  state.CreatedMachineContract = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_MACHINECONTRACT = state => {
  
  state.statusCreate = "failure";
};

const REQUEST_CHECK_MACHINECONTRACT = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_CHECK_MACHINECONTRACT = (state, { body }) => {
  state.MachineCheck = body;
  state.status = "success";
};

const FAILURE_REQUEST_CHECK_MACHINECONTRACT = state => {
  state.status = "failure";
};


export default {
  REQUEST_MACHINECONTRACTS,
  SUCCESS_REQUEST_MACHINECONTRACTS,
  FAILURE_REQUEST_MACHINECONTRACTS,
  REQUEST_CREATE_MACHINECONTRACT,
  SUCCESS_REQUEST_CREATE_MACHINECONTRACT,
  FAILURE_REQUEST_CREATE_MACHINECONTRACT,
  REQUEST_DELETE_MACHINECONTRACT,
  SUCCESS_REQUEST_DELETE_MACHINECONTRACT,
  FAILURE_REQUEST_DELETE_MACHINECONTRACT,
  REQUEST_UPDATE_MACHINECONTRACT,
  SUCCESS_REQUEST_UPDATE_MACHINECONTRACT,
  FAILURE_REQUEST_UPDATE_MACHINECONTRACT,
  REQUEST_CHECK_MACHINECONTRACT,
  SUCCESS_REQUEST_CHECK_MACHINECONTRACT,
  FAILURE_REQUEST_CHECK_MACHINECONTRACT
};
