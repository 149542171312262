<template>
  <div class="homeReadOnly" v-if="mySelf" height="100%">
    <v-row v-if="firebase_id">
      <v-col>
        <v-btn
          color="green"
          small
          dark
          class="mt-2 ml-4"
          @click="
            $router.push({
              path: '/login',
              query: { firebase_id: firebase_id, back: true },
            })
          "
        >
          <v-icon class="mr-5">mdi-arrow-left-box</v-icon>Zurück zum Menu
        </v-btn>
      </v-col>
    </v-row>

    <v-card flat>
      <v-card-title class="h5">Kunden QR</v-card-title>
      <v-row>
        <v-col cols="auto" class="ml-3 pr-0">
          <vue-qr
            width="150px"
            :text="mySelf.user_id"
            :logoImage="logoQr"
            :correctLevel="3"
          ></vue-qr>
          <!-- A :text="mySelf.access_token" -->
        </v-col>
        <v-col cols="100%" class="pl-0 mt-3 mr-6">
          <table v-if="mySelf.email" class="caption" width="100%">
            <tr>
              <!-- <td align="left" width="70%">
                Name:<br/>
                Geburtsdatum:<br/>
                Email:<br/>
              </td> -->
              <td align="right">
                {{ mySelf.firstname }} {{ mySelf.lastname }}<br />
                {{ mySelf.birthday | moment("DD.MM.YYYY") }}<br />
                {{ mySelf.user_id }}<br />
                {{ mySelf.email }}<br />
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card>
    <br />
    <v-progress-circular
      indeterminate
      :size="50"
      :width="7"
      color="secondary"
      v-if="loading_data"
    >
    </v-progress-circular>
    <v-data-table
      v-if="!loading_data"
      :headers="headers_machineContract"
      :items="MachineContracts"
      class="elevation-1 caption"
      dense
      :mobile-breakpoint="600"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :items-per-page="50"
      hide-default-footer
      calculate-widths
      :item-class="row_classes"
      sort-by="endTime"
      sort-desc
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="h5">Aktuelle Tests</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            x-small
            elevation="1"
            color="blue lighten-3"
            @click="loadMachineContracts"
            >Erstelle neue Zertifikate</v-btn
          >
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.contractNr="{ item }"> -->
      <!-- <v-btn x-small elevation="1" @click="showContract(item)">{{
          item.contractNr
        }}</v-btn> -->
      <!-- {{ item.contractNr }}
      </template> -->
      <template v-slot:item.startTime="{ item }">
        <div class="" v-if="calcTime(item).minutesPerTimeSlot > 10">
          {{ calcTime(item).start | moment("utc", "DD.MMM.YY") }}
        </div>
        <div class="" v-else>
          {{ calcTime(item).start | moment("DD.MMM.YY HH:mm") }}
        </div>
      </template>
      <template v-slot:item.endTime="{ item }">
        <div
          class="font-weight-bold"
          v-if="calcTime(item).minutesPerTimeSlot > 10"
        >
          {{ calcTime(item).end | moment("utc", "DD.MMM.YY") }}
        </div>
        <div class="font-weight-bold" v-else>
          {{ calcTime(item).end | moment("DD.MMM.YY HH:mm") }}
        </div>
      </template>
      <template v-slot:item.test="{ item }">
        <div class="font-weight-bold red--text" v-if="getFlag(item)">nachgewiesen</div>
        <div class="font-weight-bold green--text" v-else>nicht nachgewiesen</div>
      </template>
      <template v-slot:item.machine.Name="{ item }">
        <div class="font-weight-bold">{{ item.machine.Name }}</div></template
      >
      <template v-slot:item.organisation="{ item }" >
        <div v-if="item.organisation.name">{{ item.organisation.name }} <br/> {{ item.organisation.codeTown }} <br/> {{ item.organisation.country }}</div>
      </template>
      <template v-slot:item.actions_qr="{ item }">
        <div @click="printQr(item)">
          <vue-qr
            width="150px"
            :text="getMachineQr(item)"
            :logoImage="logoQr"
            :correctLevel="1"
          ></vue-qr>
        </div>
        <!-- {{getMachineQr(item)}} -->
      </template>

    
      <template v-slot:item.pdf="{ item }" >
        <div class="justify-center layout px-0" v-if="item.pdf">
          <v-icon
            small
            class="mr-2"
            @click="showInvoicePdf(item)"
          >
            picture_as_pdf
          </v-icon>
        </div>    
      </template>

      <template v-slot:header.signature="{ header }">
        {{ header.text }}
      </template>
      <template v-slot:item.signature="{ item }">
        {{ JSON.parse(item.qr).signature.substr(0, 20) }}
        {{ JSON.parse(item.qr).signature.substr(20, 20) }}...
        <!-- {{ JSON.parse(item.qr).signature.substr(40, 20) }}... -->
        <!-- {{ JSON.parse(item.qr).signature.substr(60, 20) }}
        {{ JSON.parse(item.qr).signature.substr(80, 20) }}
        {{ JSON.parse(item.qr).signature.substr(100, 17) }}... -->
      </template>
      <template v-slot:item.time="{ item }">
        {{ getTime(JSON.parse(item.qr).secret.time) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { requiredRule } from "../utils/rules";

export default {
  name: "homeReadOnly",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,
      dialog_error: false,
      dialog_cancel_contract: false,
      dialog_contract: false,

      headers_machineContract: [
        { text: "QR Code", value: "actions_qr", sortable: false },
        { text: "Testbericht", value: "pdf", sortable: false },
        // { text: "link", value: "link"  },
        { text: "Produkt", value: "machine.Name", sortable: false },
        { text: "Ergebnis", value: "test", sortable: false },
        { text: "gültig von", value: "startTime", sortable: false },
        { text: "bis", value: "endTime", sortable: false },
        // { text: "Aussteller", value: "organisation", sortable: false },
       
        {
          text: "Signatur",
          value: "signature",
          sortable: false,
          width: "10%",
        },
         { text: "Signatur ausgestellt am", value: "time", sortable: false },
      ],

      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      loading_data: true,

      dialog_qr: false,

      machineQr: "",
      logoQr: undefined,

      // Sites: [],
      // selectedSite: "",
      users: [],
      selectedUser: {},
      firebase_id: null,
    };
  },
  created: function () {
    this.timeOffset = new Date().getTimezoneOffset();
  },
  async mounted() {
    // await this.getContracts();
    // console.log(this.Contracts)
    // const SitesRegistryContract = _.find(this.Contracts, {
    //   name: "SiteRegistry",
    // });
    // this.$store.state.web3.SitesRegistryContractAddress =
    //   SitesRegistryContract.address;
    // this.$store.state.web3.SitesRegistryContractAbi =
    //   SitesRegistryContract.abi;
    // const sitesRegistryContract = new ethers.Contract(
    //   SitesRegistryContract.address,
    //   SitesRegistryContract.abi,
    //   window.ethersprovider
    // );
    // this.$store.state.web3.SitesRegistryContract = sitesRegistryContract;

    // const _tokenContract = _.find(this.Contracts, { name: "Token" });
    // this.$store.state.web3.TokenContractAbi = _tokenContract.abi;
    // const _rentContract = _.find(this.Contracts, { name: "Rent" });
    // this.$store.state.web3.RentContractAbi = _rentContract.abi;
    // const _invoiceContract = _.find(this.Contracts, { name: "Invoice" });
    // this.$store.state.web3.InvoiceContractAbi = _invoiceContract.abi;

    // const _sites = await sitesRegistryContract.getAllSites();
    // //window.sitesReg = sitesRegistryContract;

    // const userSites = _.filter(this.mySelf.sites, { active: true });

    //     let sitesArray = [];

    //     for (let userSite of userSites) {
    //       for (let site of _sites) {
    //         if (userSite.address.toLowerCase() == site.rentBC.toLowerCase()) {
    //           sitesArray.push({
    //             name: site.name,
    //             siteOwner: site.owner,
    //             rentBC: site.rentBC,
    //             tokenBC: site.tokenBC,
    //             invoiceBC: site.invoiceBC,
    //           });
    //         }
    //       }
    //     }
    //   console.log("sitesArray: ",sitesArray);
    //   this.Sites = sitesArray;
    //   this.selectedSite = 0;
    //   let idx = 0;
    //   try {
    //     this.$store.state.web3.TokenContractAddress = this.Sites[this.selectedSite].tokenBC;
    //     const _tokenContract = await new ethers.Contract(
    //       this.Sites[this.selectedSite].tokenBC,
    //       this.$store.state.web3.TokenContractAbi,
    //       window.ethersprovider
    //     );

    //     this.$store.state.web3.TokenContract = _tokenContract;
    //     this.$store.state.web3.TokenName = await _tokenContract.name();
    //     this.$store.state.web3.TokenSymbol = await _tokenContract.symbol();

    //     this.$store.state.web3.RentContractAddress = this.Sites[this.selectedSite].rentBC;
    //     const _rentContract = await new ethers.Contract(
    //       this.Sites[this.selectedSite].rentBC,
    //       this.$store.state.web3.RentContractAbi,
    //       window.ethersprovider
    //     );
    //     this.$store.state.web3.RentContract = _rentContract;

    //     this.$store.state.web3.InvoiceContractAddress = this.Sites[this.selectedSite].invoiceBC;
    //     const _invoiceContract = await new ethers.Contract(
    //       this.Sites[this.selectedSite].invoiceBC,
    //       this.$store.state.web3.InvoiceContractAbi,
    //       window.ethersprovider
    //     );

    //     this.$store.state.web3.InvoiceContract = _invoiceContract;
    //   } catch (err) {
    //     console.log(err);
    //   }

    // await this.loadMachines();
    if (!this.mySelf) {
      await this.getMySelf();
    }
    this.firebase_id = localStorage.getItem("firebase_id");

    await this.loadMachineContracts();
    //console.log(this.MachineContracts)
    //await this.matchMachine();
  },
  computed: {
    ...mapState({
      status: ({ machineContract }) => machineContract.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      MachineContracts: ({ machineContract }) =>
        machineContract.MachineContracts,
      MachineContractState: ({ machineContract }) => machineContract,

      Contracts: ({ contract }) => contract.Contracts,
    })
  },
  methods: {
    ...mapActions("machineContract", ["getMachineContracts"]),
    ...mapActions("auth", ["getMySelf"]),

    getTime(time) {
      return this.$moment(time, "X").format("DD.MMM.YYYY HH:mm:ss");
    },
    calcTime(item) {
      let obj = {};
      try {
        //if (duration )
        obj.start = item.startTime;
        obj.end = item.endTime - 60;
        obj.maxSlots = this.machine.MaxSlots;
        obj.minutesPerTimeSlot = this.machine.MinutesPerTimeSlot;
      } catch (err) {}
      //console.log(obj)
      return obj;
    },

    getMachineQr(item) {
      return item.link;
    },

    async showContract(item) {
      this.selectedMachineContract = item;
      this.dialog_contract = true;
    },

    async loadMachineContracts() {
      this.loading_data = true;
      try {
        await this.getMachineContracts({
          //rentBC: this.mySelf.sites[0].address.toLowerCase(),
          cancelled: false,
          invoiced: true,
          //machineId: machine.MachineId,
          startTime: {$gte: this.$moment().utc().subtract(7,"days").format('X')},
          endTime: {$lte: this.$moment().utc().add(5,"days").format('X')},
          //endTime: { $gte: this.$moment().add(5,'days').utc().format("X") },
        });
      } catch (err) {
        console.log(err);
      }

      this.loading_data = false;
    },

    printQr(item) {
      this.selectedMachineContract = item;
      this.machineQr = this.getMachineQr(item);
      this.dialog_qr = true;
    },
    

    getFlag(item) {
      
      try {
        
        let subCat = JSON.parse(item.machine.SubCategory);
        //console.log(subCat)
        // if (subCat.flag) {
        //   return "positiv";
        // } else {
        //   return "negativ";
        // }
        return subCat.flag
      } catch (err) {
        return "";
        //console.log(err)
      }
    },
    row_classes(item) {
      if (item.machine.SubCategory.flag) {
        return "caption background-color: orange";
      } else {    
        return "caption";
      }
    },
    showInvoicePdf (item) {
      //ToDo: Chage to XMLRequest -> siehe Leas-e
      window.open("/api/image/" + this.mySelf._id + "/" + item.pdf, '_blank')
    }    
  },
  watch: {},
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>