import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import user from "./modules/user";
import person from "./modules/person";
import event from "./modules/event";
import web3 from "./modules/web3";
import contract from "./modules/contract";
import invoice from "./modules/invoice";
import lock from "./modules/lock";
import machine from "./modules/machine";
import machineContract from "./modules/machineContract";
import paypal from "./modules/paypal";
import certificate from "./modules/certificate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: true,
    myInterval: undefined,
    blockListener: false,
    showDrawer: true,
    showPayPal: false
  },

  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_DRAWER(state, _drawer) {
      state.drawer = _drawer;
    }
  },
  modules: {
    auth,
    user,
    person,
    event,
    web3,
    contract,
    invoice,
    lock,
    machine,
    machineContract,
    paypal,
    certificate
  }
});
