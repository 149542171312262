const REQUEST_EVENTS = state => {
  state.status = "fetching";
};

const SUCCESS_REQUEST_EVENTS = (state, { body }) => {
  state.Events = body;
  state.status = "success";
};

const FAILURE_REQUEST_EVENTS = state => {
  state.status = "failure";
};

const REQUEST_DELETE_EVENT = state => {
  state.statusDelete = "fetching";
};

const SUCCESS_REQUEST_DELETE_EVENT = state => {
  state.statusDelete = "success";
};

const FAILURE_REQUEST_DELETE_EVENT = state => {
  state.statusDelete = "failure";
};

const REQUEST_UPDATE_EVENT = state => {
  state.statusUpdate = "fetching";
};

const SUCCESS_REQUEST_UPDATE_EVENT = state => {
  state.statusUpdate = "success";
};

const FAILURE_REQUEST_UPDATE_EVENT = state => {
  state.statusUpdate = "failure";
};

const REQUEST_EVENT_HISTORY = state => {
  state.statusHistory = "fetching";
};

const SUCCESS_REQUEST_EVENT_HISTORY = (state, { body }) => {
  //console.log("SUCCESS_HISTORY")
  //console.log(body.history)
  state.History = body;
  state.statusHistory = "success";
};

const FAILURE_REQUEST_EVENT_HISTORY = state => {
  state.statusHistory = "failure";
};

const REQUEST_CREATE_EVENT = state => {
  state.statusCreate = "fetching";
};

const SUCCESS_REQUEST_CREATE_EVENT = (state, { body }) => {
  state.CreatedEvent = body
  state.statusCreate = "success";
};

const FAILURE_REQUEST_CREATE_EVENT = state => {
  
  state.statusCreate = "failure";
};

const REQUEST_DEACTIVATE_EVENT = state => {
  state.statusDeactivate = "fetching";
};

const SUCCESS_REQUEST_DEACTIVATE_EVENT = state => {
  state.statusDeactivate = "success";
};

const FAILURE_REQUEST_DEACTIVATE_EVENT = state => {
  state.statusDeactivate = "failure";
};
export default {
  REQUEST_EVENTS,
  SUCCESS_REQUEST_EVENTS,
  FAILURE_REQUEST_EVENTS,
  REQUEST_CREATE_EVENT,
  SUCCESS_REQUEST_CREATE_EVENT,
  FAILURE_REQUEST_CREATE_EVENT,
  REQUEST_EVENT_HISTORY,
  SUCCESS_REQUEST_EVENT_HISTORY,
  FAILURE_REQUEST_EVENT_HISTORY,
  REQUEST_DELETE_EVENT,
  SUCCESS_REQUEST_DELETE_EVENT,
  FAILURE_REQUEST_DELETE_EVENT,
  REQUEST_UPDATE_EVENT,
  SUCCESS_REQUEST_UPDATE_EVENT,
  FAILURE_REQUEST_UPDATE_EVENT,
  REQUEST_DEACTIVATE_EVENT,
  SUCCESS_REQUEST_DEACTIVATE_EVENT,
  FAILURE_REQUEST_DEACTIVATE_EVENT
};
