import api from "../../../api";

export default {
  async getMachineContracts({ commit }, query) {
    commit("REQUEST_MACHINECONTRACTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_MACHINECONTRACTS",
        await api.getMachineContracts(query)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_MACHINECONTRACTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  // async deleteMachineContract({ commit }, id) {
  //   commit("REQUEST_DELETE_MACHINECONTRACT");
  //   commit("SET_LOADING", true, { root: true });
  //   try {
  //     commit(
  //       "SUCCESS_REQUEST_DELETE_MACHINECONTRACT",
  //       await api.deleteMachineContract(id)
  //     );
  //   } catch (err) {
  //     commit("FAILURE_REQUEST_DELETE_MACHINECONTRACT");
  //   } finally {
  //     commit("SET_LOADING", false, { root: true });
  //   }
  // },
  async updateMachineContract({ commit }, item ) {
    commit("REQUEST_UPDATE_MACHINECONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_MACHINECONTRACT",
        await api.updateMachineContract(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_MACHINECONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  // async createMachineContract({ commit }, item ) {
  //   commit("REQUEST_CREATE_MACHINECONTRACT");
  //   commit("SET_LOADING", true, { root: true });
  //   try {
  //     commit(
  //       "SUCCESS_REQUEST_CREATE_MACHINECONTRACT",
  //       await api.createMachineContract( item )
  //     );
  //   } catch (err) {
  //     commit("FAILURE_REQUEST_CREATE_MACHINECONTRACT");
  //   } finally {
  //     commit("SET_LOADING", false, { root: true });
  //   }
  // }
  async checkMachineContract({ commit }, query) {
    commit("REQUEST_CHECK_MACHINECONTRACT");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CHECK_MACHINECONTRACT",
        await api.checkMachineContract(query)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CHECK_MACHINECONTRACT");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
