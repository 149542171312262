import types from "./types";
import { getWeb3 } from '../../../utils/web3'
//import pollWeb3 from '../utils/pollWeb3'
//import getContractOperator from '../utils/getContractOperator'

export default {

  async registerWeb3 ({commit}) {
    commit(types.REQUEST_REGISTER_WEB3);
    commit("SET_LOADING", true, { root: true });
    //console.log('registerWeb3 Action being executed')
    try {
      commit(
        types.SUCCESS_REQUEST_REGISTER_WEB3,
        await getWeb3()
      );
    } catch (err) {
      commit(types.FAILURE_REQUEST_REGISTER_WEB3);
      //console.log('error in action registerWeb3', err)
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
