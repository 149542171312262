import api from "../../../api";

export default {
  async getPersons({ commit }) {
    commit("REQUEST_PERSONS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_PERSONS",
        await api.getPersons()
      );
    } catch (err) {
      commit("FAILURE_REQUEST_PERSONS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getPersonHistory({ commit }, id) {
    commit("REQUEST_PERSON_HISTORY");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_PERSON_HISTORY",
        await api.getPersonHistory(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_PERSON_HISTORY");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deletePerson({ commit }, id) {
    commit("REQUEST_DELETE_PERSON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DELETE_PERSON",
        await api.deletePerson(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DELETE_PERSON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async updatePerson({ commit }, item ) {
    commit("REQUEST_UPDATE_PERSON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_UPDATE_PERSON",
        await api.updatePerson(item)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_UPDATE_PERSON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async createPerson({ commit }, item ) {
    commit("REQUEST_CREATE_PERSON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_CREATE_PERSON",
        await api.createPerson( item )
      );
    } catch (err) {
      commit("FAILURE_REQUEST_CREATE_PERSON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async deactivatePerson({ commit }, id) {
    commit("REQUEST_DEACTIVATE_PERSON");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEACTIVATE_PERSON",
        await api.deactivatePerson(id)
      );
    } catch (err) {
      commit("FAILURE_REQUEST_DEACTIVATE_PERSON");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  }
};
