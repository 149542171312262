<template>
  <div class="paypal">
    <v-card flat lazy class="pa-5">
      <v-card-title>
        <span class="subtitle-1"
          ><v-icon small left> mdi-cash-multiple </v-icon
          ><b>Gutschein Token kaufen</b></span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-if="step == 0"
                v-model="paypal_amount"
                label="Betrag"
                :rules="minAmountRule"
              ></v-text-field>

              <div v-else>
                <b>GutscheinTokens im Wert von {{ paypal_amount }} € kaufen.</b
                ><br /><br />
                Bitte beachte, dass nach dem Kaufabschluss über PayPal es einige
                Minuten dauert, bis die Tokens übertragen werden.
                <br /><br />
              </div>
              <div ref="new_paypal" id="divpaypal"></div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- <div v-if="step == 0" > -->
          <v-btn class="pa-0" text small color="error" @click="closePayPal()">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
            class="pa-0"
            text
            small
            color="blue"
            v-if="step == 0 && paypal_amount >= Paypals.min_amount"
            @click="
              createPayPalOrder();
              step = 1;
            "
          >
            Weiter zu Zahlung
          </v-btn>
          <v-btn v-if="step == 1" small text color="blue" @click="resetPayPal">
            <v-icon small> mdi-chevron-left </v-icon> Zurück
          </v-btn>
          <!-- </div> -->
        </v-card-actions>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar" color="error">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
//import { requiredRule, addressBCRule } from "../utils/rules";

import PayPal from "vue-paypal-checkout";
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "paypaldialog",
  components: {
    PayPal,
  },
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      paypal_amount: this.Paypals.min_amount,
      snackbar: false,
      snackbar_text: "",
      minAmountRule: [
        (v) =>
          v >= this.Paypals.min_amount ||
          "Mindestbetrag: " + this.Paypals.min_amount + " €",
      ],
      step: 0,
      paypalObj: undefined,
    };
  },
  async mounted() {
    await this.getPaypal({ rentBC: this.User.Sites[this.selectedSite].rentBC });
  },
  computed: {
    ...mapState({
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Wallet: ({ web3 }) => web3.Wallet,
      Paypals: ({ paypal }) => paypal.Paypals,
      User: ({ user }) => user,
    }),
    getPayPalInvoiceNr() {
      return JSON.stringify({
        rentBC: this.User.Sites[this.selectedSite].rentBC,
        address: this.Wallet.address,
      });
    },
  },
  methods: {
    ...mapActions("paypal", ["getPaypal"]),
    async createPayPalOrder() {
      console.log(this.Paypals.rentBC, this.Sites[this.selectedSite].rentBC);
      if (
        this.Paypals.rentBC.toLowerCase() ==
        this.Sites[this.selectedSite].rentBC.toLowerCase()
      ) {
        let credentials = this.Paypals.sandbox.credentials;
        if (process.env.NODE_ENV === "production") {
          credentials = this.Paypals.production.credentials;
        }
        this.paypalObj = await loadScript({
          "client-id": credentials,
          currency: "EUR",
        });
        //console.log("PayPal: ", this.paypalObj);
        let divPayPal = document.getElementById("divpaypal");
        //console.log("divPayPal: ",divPayPal)
        this.paypalObj
          .Buttons({
            onInit: (data, actions) => {
              // Disable the buttons
              //console.log("ON INIT PAYPAL");
              //console.log(actions, data);
            },
            createOrder: (data, actions) => {
              let am1 = String(this.paypal_amount);
              //console.log(am1);
              let obj = {};
              obj.purchase_units = [];
              let obj2 = {};
              obj2.amount = {};
              obj2.amount.value = am1;
              obj2.custom_id = this.getPayPalInvoiceNr;
              //obj2.invoice_id = "0xEbB8b3A8B91C61c98B68529FE440df98d8f9eF1A";  //must be unique
              //console.log(JSON.stringify(obj2));
              obj.purchase_units.push(obj2);
              //console.log(JSON.stringify(obj));

              return actions.order.create(obj);
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                // alert(
                //   "Transaction completed by " + details.payer.name.given_name
                // );
                this.$store.state.showPayPal = false;
                //this.dialog = false;
              });
            },
            onCancel: (data, actions) => {
              //alert("Transaction canceled ");
              this.$store.state.showPayPal = false;
              //console.log(this.$store.state.showPayPal)
              //this.dialog = false;
            },
          })
          //.render(this.$refs["new_paypal"]);
          .render(divPayPal);
      } else {
        console.log("Error Paypal credentials");
      }
    },
    // payPalApprove(data, actions) {
    //   console.log("Payment approved ", data, actions);
    // },
    // payPalCancel(data, actions) {
    //   console.log("Payment cancelled ", data, actions);
    // },
    resetPayPal() {
      this.step = 0;
      try {
        //console.log("Remove DOM Obj")
        let divPayPal = document.getElementById("divpaypal");
        if (divPayPal.childNodes[0]) {
          divPayPal.removeChild(divPayPal.childNodes[0]);
        }
      } catch (err) {
        //console.log(err);
      }
    },
    closePayPal() {
      this.$store.state.showPayPal = false;
    },
  },
  watch: {
    async dialog(now, before) {
      if (now) {
        this.resetPayPal();
      }
    },
    async selectedSite(idx) {
      //console.log("BEFORE load ", idx, this.Paypals)
      await this.getPaypal({ rentBC: this.User.Sites[idx].rentBC });
    },
  },
};
</script>

