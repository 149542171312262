function array2ArrayObject(_array) {
  let result = [];
  for (let element of _array) {
    let obj = {};
    for (let key in element) {
      if (isNaN(Number(key))) {
        obj[key] = element[key];
      }
    }
    result.push(obj);
  }
  result.splice(0, 1);
  return result;
}

function array2Object(_array) {
  let obj = {};
  for (let key in _array) {
    if (isNaN(Number(key))) {
      obj[key] = _array[key];
    }
  }
  return obj;
}

function extractRevertReason(err) {
  try {
    let revertMsg = err.message.substring(err.message.indexOf('"error":'));
    
    revertMsg = revertMsg.substring(
      revertMsg.indexOf("data") + 7,
      revertMsg.indexOf('"}')
    );
    
    var hex = revertMsg
      .substr(revertMsg.indexOf("0x") + 138, revertMsg.length)
      .toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    
    if (str.length != 0) {
      return str;
    } else {
      throw err;
    }
  } catch (error) {
    
    const _end = err.message.indexOf(")");
    if (_end > 0) {
      //return err.message.substring(0, 200);
      return err.message.substring(0, err.message.indexOf(")"));
    } else {
      return err.message.substring(0, 200);
    }
  }

}

function extractReason(revertMsg) {
  var hex = revertMsg
    .substr(revertMsg.indexOf("0x") + 138, revertMsg.length)
    .toString();
  var str = "";
  for (var n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export { array2Object, array2ArrayObject, extractRevertReason, extractReason };