<template>
  <div class="machineContract" v-if="mySelf">
    <v-card flat>
      <v-row>
        <v-col>
          <v-progress-circular
            indeterminate
            :size="50"
            :width="7"
            color="secondary"
            v-if="loading_data"
          >
          </v-progress-circular>
          <v-data-table
            v-if="!loading_data"
            :headers="headers_machineContract"
            :items="machineContracts"
            sort-by="startTime"
            class="elevation-1 caption"
            dense
            must-sort
            :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
            :items-per-page="50"
            :item-class="row_classes"
            group-by="machine.Name"
            show-group-by
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title
                  >Offene Verträge / Fehlerbehandlung</v-toolbar-title
                >
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog_error" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="subtitle-1">{{
                        formTitleMachineContract
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        v-model="valid"
                        style="width: 100%; height: 100%"
                        class="compact-form"
                      >
                        {{ editedItemError.MachineID }}
                        {{ editedItemError.Description }}
                        {{ editedItemError.ErrorTime }}
                        {{ editedItemError.ErrorSender }}
                        {{ editedItemError.active }}
                        {{ editedItemError.ErrorCosts / 1000 }}
                        {{ selectedMachineContract.contractNr }}
                        <div v-if="selectedMachineContract.contract">
                          {{ selectedMachineContract.contract.Deposit / 1000 }}
                        </div>

                        <v-textarea
                          v-model="unsetDescription"
                          label="Fehlerbeschreibung"
                          rows="5"
                        ></v-textarea>
                        <v-text-field
                          v-if="selectedMachineContract.contract"
                          :rules="maxDeposit"
                          v-model="editedItemError.ErrorCosts"
                          label="Error Costs in €"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog_error = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        :disabled="!valid"
                        color="blue darken-1"
                        text
                        @click="unsetError(editedItemError)"
                        >Unset Error</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.contractNr="{ item }">
              <v-btn x-small elevation="1" @click="showContract(item)">{{
                item.contractNr
              }}</v-btn>
            </template>
            <template v-slot:item.startTime="{ item }">
              {{ item.startTime | moment("DD.MMM HH:mm") }}
            </template>
            <template v-slot:item.endTime="{ item }">
              {{ item.endTime | moment("DD.MMM HH:mm") }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div
                v-if="
                  item.error.active &&
                  item.error.ErrorTime * 60 >= item.startTime
                "
              >
                <v-icon small class="mr-2" @click="editError(item)"
                  >mdi-sync-alert</v-icon
                >
              </div>
            </template>
            <template v-slot:item.error="{ item }">
              <div
                v-if="
                  item.error.active &&
                  item.error.ErrorTime * 60 >= item.startTime
                "
              >
                {{ (item.error.ErrorTime * 60) | moment("DD.MM.YYYY HH:mm") }}
              </div>
            </template>
            <template v-slot:item.cancel="{ item }">
              <v-icon small class="mr-2" @click="startCancelingContract(item)"
                >mdi-cancel</v-icon
              >
            </template>
            <template v-slot:group.header="group">
              {{ group.group }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" :color="snackbar_color">
      {{ snackbar_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_cancel_contract" persistent max-width="350">
      <v-card>
        <v-card-title class="subtitle-1">
          Vertrag Nr. {{ selectedMachineContract.contractNr }} stornieren
        </v-card-title>
        <v-card-text class="caption"
          >Wollen Sie diesen Vertrag wirklich stornieren?</v-card-text
        >
        <v-card-actions>
          <v-btn
            class="mx-3"
            color="error"
            rounded
            outlined
            small
            @click="dialog_cancel_contract = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-3"
            color="success"
            rounded
            outlined
            small
            @click="cancelContract()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_contract" max-width="500px">
      <v-card
        v-if="
          selectedMachineContract.contract &&
          selectedMachineContract.contract.error
        "
      >
        <v-card-title>
          Details zu Vertrag Nr {{ selectedMachineContract.contractNr }}
        </v-card-title>
        <v-card-text>
          Maschinen Nr: {{ selectedMachineContract.contract.MachineID }}
          <br />Abgeschlossen am:
          {{
            (selectedMachineContract.contract.ContractTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Mieter:
          {{ getRenter(selectedMachineContract.contract.Renter) }}
          <br />StartSlot: {{ selectedMachineContract.contract.StartSlot }}
          <br />SlotCount: {{ selectedMachineContract.contract.SlotCount }}
          <br />Verbrauchte kW:
          {{ (selectedMachineContract.contract.Watth / 1000).toFixed(3) }}
          <br />Betriebszeit in Minuten:
          {{ selectedMachineContract.contract.UsedMin }}

          <br />Kaution: {{ selectedMachineContract.contract.Deposit / 1000 }}
          <br />Reservierungsgebühr:
          {{ selectedMachineContract.contract.SlotPrice / 1000 }}
          <br />Betriebskosten:
          {{ selectedMachineContract.contract.RunPrice / 1000 }} <br />Gebühren:
          {{ selectedMachineContract.contract.Fees / 1000 }} <br />Summe:
          {{ selectedMachineContract.contract.TotalBudget / 1000 }}

          <br />Verrechnet: {{ selectedMachineContract.contract.Invoiced }}
          <br />Token transferiert:
          {{ selectedMachineContract.contract.MoneyTransferred }}
          <br />Rücküberweisung:
          {{ selectedMachineContract.contract.RenterReturn }}
          <br />Rücküberweisung Zeit:
          {{
            (selectedMachineContract.contract.DepositPayOutTime * 60)
              | moment("DD.MM.YYYY HH:MM")
          }}
          <br />Rechnungsnummer Provider:
          {{ selectedMachineContract.contract.InvoiceProviderID }}
          <br />Rechnungsnummer Maschinenbesitzer:
          {{ selectedMachineContract.contract.InvoiceMachineOwnerID }}

          <br />Fehlernummer: {{ selectedMachineContract.contract.ErrorID }}
          <br />Fehler aktiv:
          {{ selectedMachineContract.contract.error.active }}
          <br />Fehlermeldung:
          {{ selectedMachineContract.contract.error.Description }} <br />Unset
          Description:
          {{ selectedMachineContract.contract.error.UnsetDescription }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog_contract = false"
            >Schliessen</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import api from "../api";
import { requiredRule } from "../utils/rules";
import {
  array2ArrayObject,
  array2Object,
  extractRevertReason,
  extractReason,
} from "../functions/utils";
import {rentErrors} from "../utils/errors";

export default {
  name: "machineContract",
  components: {},
  data() {
    return {
      valid: false,
      requiredRule,

      // dialog_delete: false,

      //dialog_machineContract: false,
      dialog_error: false,
      dialog_cancel_contract: false,
      dialog_contract: false,

      headers_machineContract: [
        { text: "contractNr", value: "contractNr", groupable: false },
        { text: "startTime", value: "startTime", groupable: false },
        { text: "endTime", value: "endTime", groupable: false },
        //{ text: "rentBC", value: "rentBC" },
        //{ text: "machineId", value: "machineId" },
        { text: "Maschinenname", value: "machine.Name" },
        { text: "finalized", value: "finalized", groupable: false },
        { text: "usedSec", value: "usedSec", groupable: false },
        { text: "wattH", value: "wattH", groupable: false },
        {
          text: "maxWattPerHour",
          value: "machine.MaxWattPerHour",
          groupable: false,
        },
        //{ text: "blockNumber", value: "blockNumber" },
        { text: "error", value: "error", groupable: false },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          groupable: false,
        },
        { text: "Cancel", value: "cancel", sortable: false, groupable: false },
      ],

      editedIndexError: -1,
      editedItemError: {},
      defaultItemError: {
        MachineID: "",
        Description: "",
        ErrorTime: "",
        ErrorSender: "",
        ErrorCosts: 0,
        ErrorContract: "",
      },
      selectedMachineContract: {},
      selectedMachineContractIndex: 0,
      snackbar: false,
      snackbar_text: "",
      snackbar_color: "error",
      machineContracts: [],
      machines: [],
      unsetDescription: "",
      loading_data: true,
    };
  },
  async mounted() {
    await this.getMachineContracts({
      rentBC: this.Sites[this.selectedSite].rentBC.toLowerCase(),
      cancelled: false,
      invoiced: false,
    });
    this.editedItemMachineContract = Object.assign(
      {},
      this.defaultItemMachineContract
    );
    await this.matchError();
    this.loading_data = false;
  },
  computed: {
    ...mapState({
      status: ({ machineContract }) => machineContract.status,
      loading: (state) => state.loading,
      statusMySelf: ({ auth }) => auth.statusMySelf,
      mySelf: ({ auth }) => auth.mySelf,
      Drawer: (state) => state.drawer,
      MachineContracts: ({ machineContract }) =>
        machineContract.MachineContracts,
      MachineContractState: ({ machineContract }) => machineContract,
      Web3: (web3) => web3.web3,
      Sites: ({ user }) => user.Sites,
      selectedSite: ({ user }) => user.selectedSite,
      Users: ({ user }) => user.Users,
    }),

    formTitleMachineContract() {
      return this.editedIndexMachineContract === -1
        ? "New MachineContract"
        : "Edit MachineContract";
    },
    maxDeposit() {
      return [
        (v) => {
          return !!v || "Bitte einen Betrag angeben";
        },
        (v) =>
          (v && v <= this.selectedMachineContract.contract.Deposit / 1000) ||
          "Maximal " +
            this.selectedMachineContract.contract.Deposit / 1000 +
            " € erlaubt. (Kautionsbetrag)",
        (v) => v >= 0 || "Negative Werte nicht erlaubt",
      ];
    },
  },
  methods: {
    ...mapActions("machineContract", ["getMachineContracts"]),

    async matchError() {
      await this.loadMachines();
      //let machineContracts = _.filter(this.MachineContracts, {rentBC: this.Sites[this.selectedSite].rentBC.toLowerCase()})
      let machineContracts = this.MachineContracts;
      for (let machineContract of machineContracts) {
        machineContract.contract = array2Object(
          await this.Web3.RentContract.getContract(machineContract.contractNr)
        );

        machineContract.machine = _.find(this.machines, {
          MachineId: machineContract.machineId,
        });
        //Object.assign(machineContract,_machine.Error);
        try {
          machineContract.error = machineContract.machine.Error;
        } catch (err) {
          //console.log("No error attrib found ", machineContract.machine);
        }
      }
      this.machineContracts = machineContracts;
    },
    async unsetError(item) {
      // console.log(item)
      //if (item.LastErrorID > 0) {
      try {
        await this.Web3.RentContract.unsetError(
          item.MachineID,
          item.ErrorCosts * 1000,
          this.selectedMachineContract.contractNr,
          this.unsetDescription
        );
        this.snackbar_text = "Machine Error Unset";
        this.snackbar_color = "success";
        this.snackbar = true;
        await this.matchError();
        this.dialog_error = false;
      } catch (err) {
        this.snackbar_color = "error";
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
      // } else {
      //   this.snackbar_text = "Kein Fehler aktiv";
      //   //this.snackbar_color = "success";
      //   this.snackbar = true;
      // }
    },

    editError(item) {
      this.editedIndexError = this.machineContracts.indexOf(item);
      this.editedItemError = Object.assign({}, item.error);
      this.selectedMachineContract = item;
      this.dialog_error = true;
    },

    async loadMachines() {
      // console.log("Load Machines ");
      const _Machines = await this.Web3.RentContract.getAllMachines();
      let _machines = array2ArrayObject(_Machines);
      this.machines = [];

      if (_machines.length > 0) {
        for (let idx in _machines) {
          if (_machines[idx].Active && !_machines[idx].Buyable) {
            const _error = array2Object(
              await this.Web3.RentContract.readError(_machines[idx].LastErrorID)
            );
            _machines[idx].Error = _error;
            _machines[idx].MachineId = parseInt(idx) + 1;
            this.machines.push(_machines[idx]);
          }
        }
      }
    },

    async showContract(item) {
      this.selectedMachineContract = item;
      //console.log("ERRORID ", this.selectedMachineContract.contract.ErrorID);
      this.selectedMachineContract.contract.error = array2Object(
        await this.Web3.RentContract.readError(
          this.selectedMachineContract.contract.ErrorID
        )
      );
      this.dialog_contract = true;
    },

    getRenter(_address) {
      if (_address) {
        const _user = _.find(this.Users, {
          crypto_address: _address.toLowerCase(),
        });
        if (_user) {
          return _user.firstname + " " + _user.lastname;
        } else {
          return _address;
        }
      } else {
        return 0;
      }
    },

    startCancelingContract(item) {
      this.dialog_cancel_contract = true;
      this.selectedMachineContractIndex = this.machineContracts.indexOf(item);
      this.selectedMachineContract = item;
    },

    async cancelContract() {
      try {
        await this.Web3.RentContract.cancelContract(
          parseInt(this.selectedMachineContract.contractNr)
        );
        this.machineContracts.splice(this.selectedMachineContractIndex, 1);
        this.dialog_cancel_contract = false;
      } catch (err) {
        this.snackbar_text = rentErrors[parseInt(extractRevertReason(err))];
        this.snackbar = true;
      }
    },

    row_classes(item) {
      if (item.error.active) {
        if (item.error.ErrorTime * 60 >= item.startTime) {
          return "caption background-color: orange";
        } else if (
          item.error.ErrorTime * 60 < item.startTime &&
          this.$moment().format("X") > item.startTime
        ) {
          return "caption background-color: blue";
        }
      } else {
        if (this.$moment().format("X") >= item.startTime) {
          return "caption background-color: grey lighten-4";
        } else {
          return "caption";
        }
      }
    },

    // createItemMachineContract () {
    //   this.editedItemMachineContract = Object.assign({}, this.defaultItem);
    //   this.editedIndexMachineContract = -1;
    //   this.dialog_machineContract = true;
    // },

    // async editItemMachineContract(item) {
    //   console.log("editItem ", item);
    //   this.editedIndexMachineContract = this.MachineContracts.indexOf(item);
    //   this.editedItemMachineContract = Object.assign({}, item);
    //   this.dialog_machineContract = true;
    // },

    // async deleteItemMachineContract(item) {
    //   const index = this.MachineContracts.indexOf(item);
    //   await this.deleteMachineContract(item._id);
    //   if (this.MachineContractState.statusDelete == "success") {
    //     this.MachineContracts.splice(index, 1);
    //   } else {
    //     this.snackbar_text = "Already deleted";
    //     this.snackbar = true;
    //   }
    // },

    // closeMachineContract() {
    //   this.dialog_machineContract = false;
    //   this.$nextTick(() => {
    //     this.editedItemMachineContract = Object.assign({}, this.defaultItem);
    //     this.editedIndexMachineContract = -1;
    //   });
    // },

    // async saveMachineContract() {
    //   console.log("saveMachineContract");
    //   console.log(this.editedItemMachineContract)

    //   if (this.editedIndexMachineContract > -1) {
    //     await this.updateMachineContract(this.editedItemMachineContract);

    //     if (this.MachineContractState.statusUpdate == "success") {
    //       Object.assign(this.MachineContracts[this.editedIndexMachineContract], this.editedItemMachineContract);
    //     } else {
    //       this.snackbar_text = "Update failed"; //this.MachineContractState.MachineContract.message;
    //       this.snackbar = true;
    //     }
    //   } else {
    //     await this.createMachineContract(this.editedItemMachineContract);
    //     if (this.MachineContractState.statusCreate == "success") {
    //       this.MachineContracts.push(this.editedItemMachineContract);
    //     } else {
    //       this.snackbar_text = "Create failed"; //this.MachineContractState.MachineContract.message;
    //       this.snackbar = true;
    //     }
    //   }

    //   this.closeMachineContract();
    // },
  },
  watch: {},
};
</script>

<style>
.compact-form {
  transform: scale(0.9);
  transform-origin: left;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 10px !important;
}
</style>