import Vue from "vue";
import VueResource from "vue-resource";
import { API_ROOT } from "../config";

Vue.use(VueResource);

// Vue.http.options.crossOrigin = true
// Vue.http.options.credentials = true

/*if (process.env.NODE_ENV === "production") {
  throw new Error("Missing API root");
  // Vue.http.options.root = ...
}*/

Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  request.headers.set("Authorization", localStorage.getItem("Authorization"));
  next(response => {
    if (response.status === 401) {
      if (!window.location.pathname === "/login")
        window.location.pathname = "/login";
    }
  });
});

export const AuthResource = Vue.resource(API_ROOT + "auth");
export const AuthCryptoResource = Vue.resource(API_ROOT + "cryptoAuth");
export const SignUpResource = Vue.resource(API_ROOT + "signup");
export const ActivateAccountResource = Vue.resource(
  API_ROOT + "activate/{token}"
);
export const MySelfResource = Vue.resource(API_ROOT + "myself");
export const ForgotPasswordResource = Vue.resource(
  API_ROOT + "forgot_password"
);
export const ResetPasswordResource = Vue.resource(
  API_ROOT + "reset_password{/token}"
);

export const CreatePersonResource = Vue.resource(API_ROOT + "person");
export const PersonResource = Vue.resource(API_ROOT + "person");
export const PersonDeleteResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonDeactivateResource = Vue.resource(API_ROOT + "person/deactivate/{id}");
export const PersonUpdateResource = Vue.resource(API_ROOT + "person/{id}");
export const PersonHistoryResource = Vue.resource(API_ROOT + "person/{id}");

export const UserResource = Vue.resource(API_ROOT + "user");
export const UserCreateResource = Vue.resource(API_ROOT + "user");
export const UserCreateAuthResource = Vue.resource(API_ROOT + "userAuth");
export const UserCreateAppResource = Vue.resource(API_ROOT + "userApp");
export const UserReadResource = Vue.resource(API_ROOT + "user/{id}");
export const UserUpdateResource = Vue.resource(API_ROOT + "user/{id}");
export const UserDeleteResource = Vue.resource(API_ROOT + "user/{id}");

export const ContractResource = Vue.resource(API_ROOT + "contract");
export const ContractDeleteResource = Vue.resource(API_ROOT + "contract/{id}");
export const ContractUpdateResource = Vue.resource(API_ROOT + "contract/{id}");
export const ContractCreateResource = Vue.resource(API_ROOT + "contract");

export const LockResource = Vue.resource(API_ROOT + "lock");
export const LockDeleteResource = Vue.resource(API_ROOT + "lock/{id}");
export const LockUpdateResource = Vue.resource(API_ROOT + "lock/{id}");
export const LockCreateResource = Vue.resource(API_ROOT + "lock");
export const LockOpenResource = Vue.resource(API_ROOT + "lock/open");

export const MachineGetStatusResource = Vue.resource(API_ROOT + "machine/getStatus");
export const MachineStartStopResource = Vue.resource(API_ROOT + "machine/startStop");


export const InvoiceResource = Vue.resource(API_ROOT + "invoice");
/*export const InvoiceDeleteResource = Vue.resource(API_ROOT + "invoice/rollback/{id}");
export const InvoiceProviderResource = Vue.resource(API_ROOT + "invoice/provider/{id}");
export const InvoiceCreateProviderResource = Vue.resource(API_ROOT + "invoice/create/provider/{id}");*/
export const InvoiceSendResource = Vue.resource(API_ROOT + "invoice/send/{id}");
/*export const InvoiceSendProviderAllResource = Vue.resource(API_ROOT + "invoice/send/provider/{id}");*/
export const InvoiceCreateVoucherResource = Vue.resource(API_ROOT + "invoice/voucher/{obj}");

export const CertificateResource = Vue.resource(API_ROOT + "certificate");
export const CertificateSendResource = Vue.resource(API_ROOT + "certificate/send/{id}")
export const CertificateDeleteResource = Vue.resource(API_ROOT + "certificate/deleteUntil");
/*
export const EventResource = Vue.resource(API_ROOT + "event");
export const EventDeleteResource = Vue.resource(API_ROOT + "event/{id}");
export const EventUpdateResource = Vue.resource(API_ROOT + "event/{id}");
export const EventCreateResource = Vue.resource(API_ROOT + "event");
*/

export const MachineContractResource = Vue.resource(API_ROOT + "machinecontract");
export const MachineContractUpdateResource = Vue.resource(API_ROOT + "machinecontract/{id}");
export const MachineContractCheckResource = Vue.resource(API_ROOT + "check_cert");

export const PaypalResource = Vue.resource(API_ROOT + "paypal");
export const PaypalDeleteResource = Vue.resource(API_ROOT + "paypal/{id}");
export const PaypalUpdateResource = Vue.resource(API_ROOT + "paypal/{id}");
export const PaypalCreateResource = Vue.resource(API_ROOT + "paypal");